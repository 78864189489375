import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './index.module.less';

const Toast = ({ VideoCallStore }) => {
  useEffect(() => {
    if (VideoCallStore.isToastVisible) {
      setTimeout(() => {
        VideoCallStore.hideToast();
      }, 3000);
    }
  }, [VideoCallStore.isToastVisible]);

  return (
    <>
      {VideoCallStore.isToastVisible ? (
        <div className={styles.tips}>
          <div className={styles.cont}>{VideoCallStore.toast?.content}</div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default inject('VideoCallStore')(observer(Toast));
