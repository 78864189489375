import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
// import { Toast, Modal } from 'antd-mobile';
import YunliRTC from '../../../rtc';
// import SineWave from '../../../plugins/utils/sinewave';
import styles from './index.module.less';

const Call = ({ VideoCallStore }) => {
  const [num, setNum] = useState(''); // 点击的按钮
  const [mute, setMute] = useState(false); // 是否静音
  const dialBtns = [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', '0', '#'];

  // 延迟
  let delay;

  let config = YunliRTC.getConfig();

  const dialBtnClick = (i) => {
    // 拨号-数字
    VideoCallStore.setCallPhoneNum(VideoCallStore.callPhoneNum + '' + i);
    setNum(i + '');
    clearTimeout(delay);
    delay = setTimeout(() => {
      setNum('');
    }, 200);
  };

  const dialBtnDelete = () => {
    // 拨号-删除
    setNum('clear');
    clearTimeout(delay);
    delay = setTimeout(() => {
      setNum('');
    }, 200);
    let num = VideoCallStore.callPhoneNum;
    if (!num) {
      return;
    }
    VideoCallStore.setCallPhoneNum(num.slice(0, -1));
  };

  useEffect(() => {
    document.addEventListener('keyup', ({ keyCode, shiftKey }) => {
      if (VideoCallStore.isCallVisible && VideoCallStore.status === 'default') {
        if (shiftKey) {
          if (keyCode === 51) {
            // #
            dialBtnClick('#');
          } else if (keyCode === 56) {
            // *
            dialBtnClick('*');
          }
        } else {
          if (keyCode === 8) {
            // 删除
            dialBtnDelete();
          } else if (keyCode >= 48 && keyCode <= 57) {
            // 0-9
            dialBtnClick(keyCode - 48);
          }
        }
      }
    });

    return () => {
      VideoCallStore.status = 'default';
      setMute(false);
    };
  }, []);

  useEffect(() => {
    if (VideoCallStore.status === 'accepted') {
      VideoCallStore.showCallTime();
      setMute(false);
    }
    // if (VideoCallStore.isCallVisible) {
    //   new SineWave({
    //     el: document.getElementById('yunli-rtc-call-status'),
    //     speed: 8,
    //     width: 278,
    //     height: 44,
    //     waves: [
    //       {
    //         timeModifier: 1,
    //         lineWidth: 2,
    //         amplitude: 10 / 3,
    //         wavelength: 100 / 3,
    //         segmentLength: 20 / 3,
    //         opacity: 0.4
    //       },
    //       {
    //         timeModifier: 1,
    //         lineWidth: 2,
    //         amplitude: 50 / 3,
    //         wavelength: 100 / 3,
    //         opacity: 0.7
    //       },
    //       {
    //         timeModifier: 2,
    //         lineWidth: 2,
    //         amplitude: -50 / 3,
    //         wavelength: 50 / 3,
    //         segmentLength: 10 / 3,
    //         opacity: 0.2
    //       }
    //     ],
    //     initialize: function () {},
    //     resizeEvent: function () {
    //       var b = -1;
    //       var c = this.waves.length;
    //       while (++b < c) {
    //         var a = this.ctx.createLinearGradient(0, 0, this.width, 0);
    //         a.addColorStop(0, 'rgba(0, 250, 250, 0)');
    //         a.addColorStop(0.5, 'rgba(0, 250, 250, ' + this.waves[b].opacity + ')');
    //         a.addColorStop(1, 'rgba(0, 250, 250, 0)');
    //         this.waves[b].strokeStyle = a;
    //       }
    //       b = void 0;
    //       c = void 0;
    //       a = void 0;
    //     }
    //   });
    // }
  }, [VideoCallStore.status]);

  const makeCall = () => {
    // 拨打电话
    let num = VideoCallStore.callPhoneNum;
    if (!num) {
      VideoCallStore.showToast({
        icon: 'fail',
        content: '请输入号码'
      });
      return;
    }
    VideoCallStore.call({
      id: num
    });
  };

  const makeCallVideo = () => {
    // 拨打视频
    let num = VideoCallStore.callPhoneNum;
    if (!num) {
      VideoCallStore.showToast({
        icon: 'fail',
        content: '请输入号码'
      });
      return;
    }

    if (VideoCallStore.dialVideo) {
      // 自定义拨号盘拨打视频
      VideoCallStore.dialVideo(num);
    } else {
      VideoCallStore.call({
        id: num,
        video: true
      });
    }
  };

  // 挂断
  const hangup = () => {
    const eventBus = config.eventBus;
    const { status, callTimes } = VideoCallStore;

    VideoCallStore.hangup();

    // 统一挂断不进拨号
    VideoCallStore.isCallVisible = false;
    // if (status === 'accepted') {
    // }

    // 未拨通重试的情况
    if (status === 'progress') {
      if (callTimes) {
        console.log('未接通...');
        eventBus.emit('yunli-rtc-turnplate-fail', VideoCallStore.callInfo);
      }
    }
  };

  // 静音切换
  const callMuteToggle = () => {
    let id = VideoCallStore.id;
    if (mute) {
      YunliRTC.unmute({ id: id });
    } else {
      YunliRTC.mute({ id: id });
    }
    setMute(!mute);
  };

  const timeFormat = (data) => {
    const _data = +data;
    const h = (Math.floor(_data / 3600) + '').padStart(2, 0);
    const m = (Math.floor((_data % 3600) / 60) + '').padStart(2, 0);
    const s = (((_data % 3600) % 60) + '').padStart(2, 0);
    return h === '00' ? `${m}:${s}` : `${h}:${m}:${s}`;
  };

  return (
    <>
      <div
        className={
          styles.callModal +
          ' isMinHide' +
          ' isCall' +
          VideoCallStore.type.charAt(0).toUpperCase() +
          VideoCallStore.type.slice(1)
        }
        style={{ display: VideoCallStore.isCallVisible ? '' : 'none' }}
        onClick={() => {
          // 点击背景关闭拨号
          if (VideoCallStore.status === 'default') {
            VideoCallStore.isCallVisible = false;
          }
        }}>
        <div className={styles.callModalMask}>
          <div
            className={styles.callDefaultContainer}
            style={{
              display: VideoCallStore.status === 'default' ? 'flex' : 'none'
            }}>
            <div className={styles.defaultCallPhone}>
              <span>{VideoCallStore.callPhoneNum}</span>
              <em>
                {VideoCallStore.callPhoneNum && VideoCallStore.callPhoneNum.length > 3
                  ? VideoCallStore.callNickname
                  : ''}
              </em>
            </div>
            <div
              className={styles.callBox}
              onClick={(event) => {
                event.stopPropagation();
              }}>
              {dialBtns.map((i) => (
                <span
                  key={i}
                  className={'ico ico-call-btn' + (num === i + '' ? '-on' : '') + ' ' + styles.callBtn}
                  onClick={() => dialBtnClick(i)}>
                  {i}
                </span>
              ))}
            </div>
            <div
              className={styles.callFunBox}
              onClick={(event) => {
                event.stopPropagation();
              }}>
              <span className={'ico ico-call-video ' + styles.callBtnCallVideo} onClick={makeCallVideo}></span>
              <span className={'ico ico-call-audio ' + styles.callBtnCall} onClick={makeCall}></span>
              <span
                className={'ico ico-call-btn-del' + (num === 'clear' ? '-on' : '') + ' ' + styles.callBtnDelete}
                onClick={dialBtnDelete}></span>
            </div>
          </div>
          <div
            className={
              styles.callContainer +
              (VideoCallStore.status === 'accepted' &&
              VideoCallStore.type === 'video' &&
              VideoCallStore.isVideoBtnBottom
                ? ' ' + styles.isVideoBtnBottom
                : '')
            }
            style={{
              display: VideoCallStore.status !== 'default' ? 'flex' : 'none'
            }}>
            <div className={styles.defaultCallPhone}>
              <span>{VideoCallStore.callPhoneNum}</span>
              <em>
                {VideoCallStore.callPhoneNum && VideoCallStore.callPhoneNum.length > 3
                  ? VideoCallStore.callNickname
                  : ''}
              </em>
            </div>
            <div className={styles.callStatus}>
              <div
                className={styles.callingContent}
                style={{
                  display: VideoCallStore.status === 'accepted' && VideoCallStore.type === 'video' ? 'none' : 'flex'
                }}>
                {/* <canvas id="yunli-rtc-call-status" /> */}
                <span>{VideoCallStore.status === 'progress' ? '正在呼叫…' : ''}</span>
              </div>
              <div
                className={styles.onlineContent}
                style={{
                  display: VideoCallStore.status === 'accepted' && VideoCallStore.type === 'video' ? 'flex' : 'none'
                }}>
                <div className={styles.callVideoPlayer}>
                  <video
                    id={VideoCallStore.localVideoId}
                    className={VideoCallStore.isLocalViedoFront ? '' : styles.isSmall}
                    autoPlay
                  />
                  <video
                    id={VideoCallStore.remoteVideoId}
                    className={VideoCallStore.isLocalViedoFront ? styles.isSmall : ''}
                    style={
                      VideoCallStore.contactsHash[VideoCallStore.callPhoneNum]?.hsUser
                        ? VideoCallStore.isLocalViedoFront
                          ? {
                              transform: 'scaleY(3) translateY(13px)',
                              height: '8.5%'
                            }
                          : {
                              transform: 'scaleY(3) translateY(51px)',
                              height: '33.5%'
                            }
                        : {}
                    }
                    autoPlay
                  />
                </div>
              </div>
            </div>
            {VideoCallStore.status === 'accepted' && VideoCallStore.type === 'video' ? (
              <div
                className={styles.change}
                onClick={() => {
                  VideoCallStore.isLocalViedoFront = !VideoCallStore.isLocalViedoFront;
                }}></div>
            ) : (
              ''
            )}
            <div className={styles.callTime}>
              {VideoCallStore.status === 'progress' ? '' : timeFormat(VideoCallStore.callTime)}
            </div>
            <div
              className={styles.callFunBox}
              style={{
                visibility: !VideoCallStore.isMuteHidden && VideoCallStore.status === 'accepted' ? '' : 'hidden'
              }}>
              <span className={mute ? styles.callFunMuteOn : styles.callFunMuteOff} onClick={callMuteToggle}>
                <i />
                <label>{mute ? '取消' : ''}静音</label>
              </span>
            </div>
            <div className={styles.callHangup}>
              <em
                className="ico ico-call-hangup"
                onClick={() => {
                  if (VideoCallStore.status === 'accepted') {
                    VideoCallStore.showModal({
                      content: '确定结束通话？',
                      onConfirm: () => {
                        hangup();
                      }
                    });
                  } else {
                    hangup();
                  }
                }}></em>
              {VideoCallStore.isAudioToVideo &&
              VideoCallStore.status === 'accepted' &&
              VideoCallStore.type === 'audio' ? (
                <div
                  className={styles.callToVideo}
                  title="切换到视频"
                  onClick={() => {
                    // 切换到视频
                    if (VideoCallStore.type === 'audio') {
                      YunliRTC.callVideo({
                        id: VideoCallStore.id
                      });

                      VideoCallStore.type = 'video';
                      VideoCallStore.showLocalVideo();
                    } else {
                      VideoCallStore.type = 'audio';
                      VideoCallStore.hideLocalVideo();
                    }
                  }}>
                  <em className="ico ico-call-to-video"></em>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className={styles.callLeftBtn}>
              {VideoCallStore.status === 'accepted' ? VideoCallStore.callLeftBtn : ''}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default inject('VideoCallStore')(observer(Call));
