import React, { useState, useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Radio, Modal, message, Button, Tree, Table, Form, Input, Tooltip, DatePicker, Empty } from 'antd';
import styles from './invite.module.less';
import moment from 'moment';

const { TextArea } = Input;

const Control = ({ VideoCallStore }) => {
  // 通讯录
  const [concatsList, setConcatsList] = useState([]);
  const [concatsListChecked, setConcatsListChecked] = useState([]);
  const [sendType, setSendType] = useState('');
  const controlFormRef = useRef();

  function onClose() {
    // 对话框关闭
    VideoCallStore.isControlVisible = false;
    setConcatsListChecked([]);
  }

  function onCheck(keys, e) {
    console.log(keys, e);
    let checked = [];
    e.checkedNodes.forEach((o) => {
      if (o.contactsId) {
        checked.push(o);
      }
    });

    setConcatsListChecked(checked);
  }

  function onSelect(keys, e) {
    console.log(keys, e);
    let el = e.nativeEvent.target;
    if (el.className === 'ant-tree-title') {
      el.parentNode.previousSibling.click();
    } else {
      el.previousSibling.click();
    }
  }

  const init = async () => {
    if (VideoCallStore.isControlVisible) {
      const list = await VideoCallStore.getContactsList();
      setConcatsList(list);
      let checked = [];
      const target = toJS(VideoCallStore.contactsListChecked);
      const getChecked = (arr) => {
        arr.forEach((o) => {
          if (o.children) {
            getChecked(o.children);
          }
          target.forEach((e) => {
            if (e.id === o.mobile && e.name === o.name) {
              checked.push(o);
            }
          });
        });
      };
      getChecked(toJS(list));
      setConcatsListChecked(checked);
      controlFormRef.current.resetFields();
    }
    // setConcatsList([
    //   {
    //     title: '万柳西路（8880006）',
    //     addr: '万柳西路',
    //     value: '8880006',
    //     key: '0-0'
    //   },
    //   {
    //     title: '知春路181（8880004）',
    //     addr: '知春路181',
    //     value: '8880004',
    //     key: '0-1'
    //   },
    //   {
    //     title: '琉璃井胡同4号（8880011）',
    //     addr: '琉璃井胡同4号',
    //     value: '8880011',
    //     key: '0-2'
    //   }
    // ]);
  };
  useEffect(() => {
    init();
  }, [VideoCallStore.isControlVisible]);

  return (
    <Modal
      title="调度人员"
      className={VideoCallStore.css + 'Dialog isSmall'}
      width={692}
      centered
      open={VideoCallStore.isControlVisible}
      getContainer={VideoCallStore.modalContainer}
      zIndex="1001"
      cancelButtonProps={{ style: { display: 'none' } }}
      okText="发送任务"
      okButtonProps={{ className: VideoCallStore.css + 'Btn' }}
      onOk={() => {
        let list = concatsListChecked,
          num = list.length;
        if (!num) {
          message.error('请选择调度人员');
        } else if (list.length > 500) {
          message.error('调度人员数量不能超过500人');
        } else {
          // onClose();
          // message.success('任务已发送');
          controlFormRef.current.submit();
        }
      }}
      onCancel={() => {
        VideoCallStore.isControlVisible = false;
      }}>
      <div className={styles.invite}>
        <div className={styles.cont}>
          <div className={styles.title}>通讯录</div>
          <Tree
            className={VideoCallStore.css + 'Tree isRight'}
            checkable
            showLine={{ showLeafIcon: false }}
            switcherIcon={<em className="ico ico-arrow-down" />}
            checkedKeys={concatsListChecked.map((o) => o.key)}
            defaultCheckedKeys={concatsListChecked.map((o) => o.key)}
            // defaultExpandAll={true}
            onSelect={onSelect}
            onCheck={onCheck}
            treeData={concatsList}
          />
        </div>
        <div className={styles.selected} style={{ width: '380px' }}>
          <div className={styles.title}>
            已选调度人员<em>{concatsListChecked.length}人</em>
          </div>
          <div className={styles.cont}>
            {concatsListChecked.length ? (
              <>
                <Table
                  dataSource={concatsListChecked}
                  className={VideoCallStore.css + 'Table'}
                  size="small"
                  scroll={{ y: 120 }}
                  pagination={false}
                  columns={[
                    {
                      title: '姓名',
                      width: 100,
                      ellipsis: {
                        showTitle: false
                      },
                      dataIndex: 'title',
                      key: 'title',
                      render: (text) => (
                        <Tooltip placement="left" title={text}>
                          {text.split(' ')[0]}
                        </Tooltip>
                      )
                    },
                    {
                      title: '电话',
                      dataIndex: 'mobile',
                      key: 'mobile'
                    },
                    {
                      title: '职务',
                      dataIndex: 'parentName',
                      ellipsis: {
                        showTitle: false
                      },
                      key: 'parentName',
                      render: (text, record) => (
                        <Tooltip
                          placement="left"
                          title={text + (record.jobDescription ? '-' + record.jobDescription : '')}>
                          {text}
                          {record.jobDescription ? '-' + record.jobDescription : ''}
                        </Tooltip>
                      )
                    },
                    {
                      title: '',
                      width: 30,
                      dataIndex: '',
                      key: 'x',
                      render: (text) => (
                        <em
                          className="ico ico-remove"
                          onClick={() => {
                            setConcatsListChecked(concatsListChecked.filter((o) => o.key !== text.key));
                          }}></em>
                      )
                    }
                  ]}
                />
                <div className={styles.btn}>
                  <Button
                    type="primary"
                    className={VideoCallStore.css + 'Btn ant-btn-primary2'}
                    onClick={() => {
                      let list = concatsListChecked;
                      if (list.length) {
                        onClose();
                        VideoCallStore.conf(
                          list.map((o) => {
                            return { id: o.mobile, name: o.contactsName };
                          })
                        );
                        message.success('邀请已发送');
                      } else {
                        message.error('请选择调度人员');
                      }
                    }}>
                    一键会议
                  </Button>
                </div>
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginTop: '60px' }} />
            )}
          </div>
          <Form
            ref={controlFormRef}
            className={VideoCallStore.css + 'Form ' + (sendType === '2' ? 'isSendTime' : '')}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            layout="horizontal"
            onFinish={async (values) => {
              // console.log(values);
              if (values.sendType === '2') {
                values.sendTime = values.sendTime.format('YYYY-MM-DD HH:mm:ss');
              }
              values.contactsId = concatsListChecked.map((o) => o.contactsId).join(',');
              values.contactName = concatsListChecked.map((o) => o.contactsName).join(',');
              if (VideoCallStore.inviteOk) {
                VideoCallStore.controlOk(values);
              } else {
                const res = await VideoCallStore.sendTask(values);
                if (res.code === '200') {
                  onClose();
                  message.success('任务已发送');
                }
              }
            }}
            onValuesChange={(values) => {
              if (values.sendType) {
                setSendType(values.sendType);
              }
            }}>
            <Form.Item label="任务名称" name="taskName" rules={[{ required: true, message: '请输入任务名称' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="任务内容" name="taskContent" rules={[{ required: true, message: '请输入任务内容' }]}>
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item label="发送时间" style={{ marginBottom: '-10px' }}>
              <Form.Item
                className="checkboxWrap"
                name="sendType"
                rules={[
                  {
                    validator: (rule, value) => {
                      const values = controlFormRef.current.getFieldsValue();
                      if (!value) {
                        return Promise.reject(new Error('请选择发送时间'));
                      } else if (value === '2' && !values.sendTime) {
                        return Promise.reject(new Error('请选择日期时间'));
                      }
                      return Promise.resolve();
                    }
                  },
                  { required: true, message: '' }
                ]}>
                <Radio.Group>
                  <Radio value="1"> 立即发送 </Radio>
                  <Radio value="2"> 定时发送 </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="sendTime" style={{ display: sendType === '2' ? '' : 'none' }}>
                <DatePicker
                  style={{ position: 'absolute', top: '-14px' }}
                  // defaultValue={moment().add(1, 'days')}
                  disabledDate={(current) => {
                    return current && current < moment();
                  }}
                  placeholder="请选择日期时间"
                  format="YYYY-MM-DD HH:mm"
                  showTime
                  onBlur={() => {
                    controlFormRef.current.validateFields(['sendType']);
                  }}
                />
              </Form.Item>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default inject('VideoCallStore')(observer(Control));
