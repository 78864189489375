let config = {
  eventBus: null, // EventBus

  ip: '',
  port: '',
  businesPort: '', // 凌壹无此参数
  userNick: '', // 昵称，未设置时取userName
  userName: '', // 用户名
  userPsw: '', // 密码
  userType: '', // 凌壹无此参数
  // resourceUrl: '', // 凌壹无此参数

  callOriginator: '', // 通话响应的发起者（local-当前用户 remote-对方用户），凌壹用不到，航美中使用，用来区分响应是否为对方操作
  callState: '', // 通话状态，用来解决接听航美来电，会重复触发通话监听事件两次的问题

  ua: null, // 注册凌壹的ua实例
  uaWS: null, // 注册凌壹的ws客户端
  msgWS: null, // 接收凌壹消息的ws客户端
  confWS: null, // 接收凌壹视频会议的ws客户端
  confTarget: 'yunliRTCConf', // 接收凌壹视频会议的Iframe名称

  confId: '', // 接收凌壹视频会议的ws客户端-confId 会议ID
  confToken: '', // 接收凌壹视频会议的ws客户端-token
  confClientId: '', // 接收凌壹视频会议的ws客户端-clientId
  appId: '', // 接收凌壹视频会议appId
  secretKey: '', // 接收凌壹视频会议secretKey
  token: '', // 凌壹视频会议token
  user: '', // 当前登录用户的信息
  info: '', // 后台返回的系统信息和用户信息，包含token等

  // businessResourceUrl: '', // 凌壹无此参数
  path: '', // 信令服务器url path 航美无此参数
  imei: 'yunlirtcapi', // 设备识别号imei 3a8ade2f7dd9ed75008029273d51e5b1
  apiSDK: '', // 凌壹所有api的前缀，默认为空
  api: '/api/command' // 业务所有api的前缀，默认为 /api/command
};

export function setConfig(options) {
  config = Object.assign(config, options);
}

export function getConfig() {
  return config;
}
