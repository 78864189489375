import React, { useState, useEffect, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Modal, message, Button, Tree, Col, Row, Spin } from 'antd';
import styles from './live.module.less';
import YunliRTC from '../../rtc';
import _ from 'lodash';

const getLiveVideoUrlCache = {}; // 获取视频播放地址缓存

const Live = ({ VideoCallStore }) => {
  const [liveCallEmpty, setLiveCallEmpty] = useState([]); // 补齐空位置
  const [isFold, setIsFold] = useState(false); // 收起列表
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  // 通讯录
  const [concatsList, setConcatsList] = useState([]);
  const [concatsListChecked, setConcatsListChecked] = useState([]);

  // 播放视频
  let playIndex = 0; // 保证id唯一，避免id重复出现的问题
  function getId(id) {
    // 获取真实id
    return id?.replace(/id\w-/g, '');
  }
  function play(checked) {
    let ids = [],
      liveCallId = toJS(VideoCallStore.liveCallId),
      // liveId = [],
      liveCallEmpty = [], // 补齐空位置
      num; // 总数
    if (checked.length > 9) {
      message.info('最多只能选择9个视频');
      checked.splice(9);
    }

    num = checked.length;

    setConcatsListChecked(checked);
    ids = checked.map((o) => {
      playIndex++;
      return 'id' + playIndex + '-' + (VideoCallStore.isLiveUrl ? o.contactsId : o.callId || o.mobile);
    });
    VideoCallStore.liveCallId = ids;
    // console.log(ids, checked, 999);

    // 挂断
    liveCallId.forEach((o) => {
      if (o && ids.indexOf(o) === -1) {
        // 不在勾选中的挂断
        // console.log(VideoCallStore.liveId[o], o);
        if (VideoCallStore.liveId[o]) {
          if (VideoCallStore.isLiveUrl) {
            VideoCallStore.liveId[o] = '';
          } else {
            YunliRTC.hangup({ id: VideoCallStore.liveId[o] });
            VideoCallStore.liveId[o] = '';
          }
        }
      }
    });

    // 拨打
    toJS(ids).forEach((o, i) => {
      const mobile = o;
      if (VideoCallStore.isLiveUrl) {
        // 播放URL模式，通过getLiveVideoUrl来获取
        let urlCache = getLiveVideoUrlCache[getId(mobile)];
        if (urlCache) {
          VideoCallStore.liveId[mobile] = urlCache;
          VideoCallStore.liveName[mobile] = checked[i].title;
        } else {
          VideoCallStore.getLiveVideoUrl(getId(mobile)).then((url) => {
            if (url && VideoCallStore.liveId[mobile] !== url) {
              VideoCallStore.liveId[mobile] = url;
              VideoCallStore.liveName[mobile] = checked[i].title;
              getLiveVideoUrlCache[getId(mobile)] = url;
            }
          });
        }
      } else {
        if (!VideoCallStore.liveId[mobile]) {
          // 未播放，拨打
          setTimeout(() => {
            YunliRTC.call(mobile, {
              media: VideoCallStore.liveVideo(i + 1),
              video: true,
              isPlayer: true,
              callback: (res) => {
                VideoCallStore.liveId[mobile] = res.id;
                VideoCallStore.liveName[mobile] = mobile;
              }
            });
          });
        }
      }
    });

    // 补齐空位置
    if (num === 0) {
      liveCallEmpty = [''];
    } else if (num > 2 && num < 4) {
      liveCallEmpty = new Array(4 - checked.length);
      liveCallEmpty.fill('');
    } else if (num > 4 && num < 9) {
      liveCallEmpty = new Array(9 - checked.length);
      liveCallEmpty.fill('');
    }
    setLiveCallEmpty(liveCallEmpty);
  }

  function onCheck(keys, e) {
    let checked = [];
    e.checkedNodes.forEach((o) => {
      if (o.contactsId) {
        checked.push(o);
      }
    });
    play(checked);
  }

  function onSelect(keys, e) {
    let el = e.nativeEvent.target;
    if (el.className === 'ant-tree-title') {
      el.parentNode.previousSibling.click();
    } else {
      el.previousSibling.click();
    }
  }

  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const init = async () => {
    if (VideoCallStore.isLiveVisible) {
      await VideoCallStore.getEquipmentList();
      // const list = await VideoCallStore.getContactsList();
      const list = toJS(VideoCallStore.equipmentList);
      setConcatsList(list);
      let checked = [];
      const target = toJS(VideoCallStore.contactsListChecked);
      const getChecked = (arr) => {
        arr.forEach((o) => {
          if (o.children) {
            getChecked(o.children);
          }
          target.forEach((e) => {
            if (e.id === (o.callId || o.mobile) && e.name === o.name) {
              checked.push(o);
            }
          });
        });
      };
      getChecked(list);
      setConcatsListChecked(checked);
      setSearchValue('');
      play(checked);
      setExpandedKeys(['0-0']); // 默认展开第一个父级
      // controlFormRef.current.resetFields();
    }
  };
  useEffect(() => {
    init();
  }, [VideoCallStore.isLiveVisible]);

  useEffect(() => {
    // 播放器语言设置
    if (window.videojs) {
      window.videojs.addLanguage('en', {
        Play: '播放',
        Pause: '暂停',
        Mute: '静音',
        Unmute: '取消静音',
        'Picture-in-Picture': '画中画',
        'Exit Picture-in-Picture': '取消画中画',
        'Non-fullscreen': '取消全屏',
        Fullscreen: '全屏'
      });
    }
  }, []);

  const treeData = useMemo(() => {
    const loop = (data) =>
      data.map((item) => {
        const { icon, contactsId, callId, mobile } = item;
        const strTitle = item.title;
        const index = strTitle.indexOf(searchValue);
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue?.length);
        const title =
          index > -1 ? (
            <>
              {beforeStr}
              <span className="site-tree-search-value">{searchValue}</span>
              {afterStr}
            </>
          ) : (
            <>{strTitle}</>
          );

        if (item.children) {
          return {
            title,
            icon,
            key: item.key,
            contactsId,
            callId,
            mobile,
            children: loop(item.children)
          };
        }

        return {
          title,
          icon,
          isHidden: searchValue && index === -1,
          key: item.key,
          contactsId,
          callId,
          mobile
        };
      });

    let list = loop(_.cloneDeep(concatsList));

    return searchValue === null ? [] : list;
  }, [searchValue]);

  const getParentKey = (key, tree) => {
    let parentKey;

    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];

      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }

    return parentKey;
  };

  return (
    <Modal
      title={VideoCallStore.liveTitle || '视频监控'}
      className={VideoCallStore.css + 'Dialog ' + (VideoCallStore.isMax ? 'isMax' : '')}
      width={1300}
      centered
      open={VideoCallStore.isLiveVisible}
      getContainer={VideoCallStore.modalContainer}
      zIndex="1001"
      footer={null}
      maskClosable={false}
      onCancel={() => {
        // 挂断所有视频
        if (VideoCallStore.isLiveUrl) {
          VideoCallStore.liveCallId = [];
          VideoCallStore.isLiveUrl = false;
        } else {
          VideoCallStore.liveCallId.forEach((o) => {
            YunliRTC.hangup({ id: VideoCallStore.liveId[o] });
          });
        }

        setSearchValue(null);

        VideoCallStore.isLiveVisible = false;

        if (VideoCallStore.onLiveCancel) {
          VideoCallStore.onLiveCancel();
        }
      }}>
      <div className={styles.max + ' commonDialogMax'}>
        <em
          className="ico ico-max"
          onClick={() => {
            VideoCallStore.isMax = !VideoCallStore.isMax;
            setIsFold(false);
          }}></em>
      </div>
      <div
        className={styles.invite + (VideoCallStore.isMax ? ' ' + styles.isMax : '')}
        style={{ margin: '0', height: VideoCallStore.isMax ? 'calc(100% - 9px)' : '' }}>
        <div className={styles.cont + (isFold ? ' ' + styles.isFold : '')}>
          <div className={styles.title}>
            <span>摄像头列表</span>
            {VideoCallStore.isMax && (
              <div className="floatRight">
                <em
                  className={'ico ico-tree-' + (isFold ? 'un' : '') + 'fold'}
                  onClick={() => {
                    setIsFold(!isFold);
                  }}></em>
              </div>
            )}
          </div>
          <div className={styles.search}>
            <input
              className="commonInput"
              placeholder="请输入关键词"
              onChange={(e) => {
                const { value } = e.target;
                setInputValue(value);
              }}
            />
            <Button
              type="primary"
              className="commonBtn"
              onClick={() => {
                const value = inputValue;
                const dataList = toJS(concatsList);
                const newExpandedKeys = [];
                const getKeys = (data) => {
                  data.map((item) => {
                    item = toJS(item);
                    if (item.title && item.title.indexOf(value) > -1) {
                      newExpandedKeys.push(getParentKey(item.key, dataList));
                    }
                    if (item.children) {
                      getKeys(item.children);
                    }
                  });
                };
                getKeys(dataList);
                setExpandedKeys(newExpandedKeys);
                setAutoExpandParent(true);
                setSearchValue(value);
              }}>
              搜 索
            </Button>
          </div>
          <Tree
            className={VideoCallStore.css + 'Tree isSelect' + (searchValue ? ' isSearch' : '')}
            checkable
            checkStrictly
            showIcon
            switcherIcon={<em className="ico ico-arrow-down" />}
            checkedKeys={concatsListChecked.map((o) => o.key)}
            defaultCheckedKeys={concatsListChecked.map((o) => o.key)}
            onSelect={onSelect}
            onCheck={onCheck}
            treeData={treeData}
            // filterTreeNode={(node) => {
            //   return !node.isHidden;
            // }}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
          />
        </div>
        <div className={styles.selected}>
          <div
            className={styles.cont}
            style={{
              maxHeight: '100%',
              marginRight: concatsListChecked.length <= 1 ? '' : '-10px',
              overflow: 'visible'
            }}>
            <Row
              gutter={concatsListChecked.length <= 1 ? [] : [10, 10]}
              className={styles.playerWrap + ' ' + (concatsListChecked.length <= 1 ? '' : styles.isFour)}>
              {VideoCallStore.liveCallId.map((o, i) => (
                <Col span={concatsListChecked.length <= 1 ? 24 : concatsListChecked.length > 4 ? 8 : 12} key={getId(o)}>
                  <div className={styles.player}>
                    {VideoCallStore.isLiveUrl ? (
                      <>
                        {VideoCallStore.liveId[o] ? (
                          <live-player
                            id="live-player-1"
                            video-url={VideoCallStore.liveId[o]}
                            controls="false"
                            alt=""
                            muted
                            loop
                            aspect="fullscreen"
                            stretch="true"
                            vce-ready
                          />
                        ) : (
                          <Spin size="large" />
                        )}
                      </>
                    ) : (
                      <video id={VideoCallStore['liveVideoId' + (i + 1)]} autoPlay controls />
                    )}
                  </div>
                  <div className={styles.name}>{VideoCallStore.liveName[o]}</div>
                </Col>
              ))}
              {liveCallEmpty.map((o, i) => (
                <Col span={concatsListChecked.length <= 1 ? 24 : concatsListChecked.length > 4 ? 8 : 12} key={i}>
                  <div className={styles.player}>{VideoCallStore.isLiveUrl ? '' : <video />}</div>
                </Col>
              ))}
              {concatsListChecked.length === 0 && <div className={styles.empty}>无摄像头</div>}
            </Row>
          </div>
          {/* <div className={styles.footer}>视频路数</div> */}
        </div>
      </div>
    </Modal>
  );
};

export default inject('VideoCallStore')(observer(Live));
