// import { ua, getSession, onIncomingStream } from './regist-ly';
import { getConfig } from '../config';
import { get } from '../fetch';
import { call } from './call';
import md5 from 'js-md5';

const config = getConfig();

/**
 * 开始广播
 * @param {Number} id 广播组号码
 * @param {HTMLAudioElement} media 广播播放Audio
 * @param {Function} callback 回调函数
 */
export function startBroadcast(id, { media, callback }) {
  call(id, {
    video: false,
    media: media,
    callback: (res) => {
      if (callback) {
        callback(res);
      }
    }
  });
}
/**
 * 结束广播
 * @param {Number} id 广播组号码
 * @param {Function} callback 回调函数
 */
export async function endBroadcast(id, { callback }) {
  const config = getConfig();

  let send = await get(config.apiSDK + '/manager/call/call!conference_interactive.action', {
    cmd: 'kick',
    roomid: id,
    data: 'all'
  });
  if (callback) {
    callback(send);
  }
  return send;
}
/**
 * 广播列表
 * @param {Function} callback 回调函数
 */
export async function getBroadcasts({ callback }) {
  const config = getConfig();
  // console.log(config, 'config...');
  let send = await get(config.apiSDK + '/androidApi/voip/getMeetingListByUId.action', {
    type: 2,
    broadcast: 1,
    userId: config.userName,
    userPwd: md5(config.userPsw)
  });
  if (callback) {
    callback(send);
  }
  return send;
}
/**
 * 创建广播组
 * @param {Array} ids 广播组成员id
 * @param {String} name 广播组名称
 * @param {String} company 广播组单位id
 * @param {Function} callback 回调函数
 */
export async function createBroadcast(ids = [], { name = '', callback }) {
  let create = await get(config.apiSDK + '/androidApi/voip/createBroadcast.action', {
    data: JSON.stringify({
      broasdcast: 1, // 广播默认为1
      compType: 0, // 未知
      size: 0, // 未知
      broadcastType: 2, //  2文件广播 1 语音广播
      BroadcastMode: 0, //  未知
      BroadcastFilename: 'yunli.wav', //  广播文件名称
      BroadcastLoop: 1, //  文件广播循环次数
      BroadcastTimeSet: 0, //  定时 此功能未开放
      BroadcastFilePlayMillSeconds: 0, // 音频播放时长
      createId: config.userName, //  创建人
      conferenceName: name, //  广播名称
      conferenceUserNum: ids.length + 1, // 广播人数
      groupId: null, //  调度台不写
      conferenceType: 2, //  会议；类型 类似广播类型
      meetingMember: ids.join(',') //  广播人员
    }),
    userId: config.userName,
    userPwd: md5(config.userPsw),
    userType: 2,
    meetingMember: ids.join(',')
  });
  // let send = await postForm(config.apiSDK + '/manager/intercom/intercomInfo!saveYonghu.action', {
  //   data: JSON.stringify({
  //     id: create.id,
  //     yhIds: ids.join(',')
  //   })
  // });
  if (callback) {
    callback(create);
  }
  return create;
}
/**
 * 删除广播组
 * @param {Number} id 广播组id
 * @param {Function} callback 回调函数
 */
export async function removeBroadcast(id, { callback }) {
  let send = await get(config.apiSDK + '/androidApi/function/delMeetingByConfId.action', {
    userId: config.userName,
    userPwd: md5(config.userPsw),
    userType: 2,
    id: id
  });
  if (callback) {
    callback(send);
  }
  return send;
}
