/**
 * 波浪动画
 */
function SineWave(a = {}) {
  Object.assign(this, a);
  if (!this.el) {
    throw 'No Canvas Selected';
  }
  this.ctx = this.el.getContext('2d');
  if (!this.waves.length) {
    throw 'No waves specified';
  }
  // this.width *= 3;
  // this.height *= 3;
  this._resizeWidth();
  window.addEventListener('resize', this._resizeWidth.bind(this));
  this.resizeEvent();
  window.addEventListener('resize', this.resizeEvent.bind(this));
  if (typeof this.initialize === 'function') {
    this.initialize.call(this);
  }
  this.loop();
}
SineWave.prototype.width = 278;
SineWave.prototype.height = 44;
SineWave.prototype.speed = 10;
SineWave.prototype.amplitude = 50;
SineWave.prototype.wavelength = 50;
SineWave.prototype.segmentLength = 10;
SineWave.prototype.lineWidth = 2;
SineWave.prototype.strokeStyle = 'rgba(255, 255, 255, 0.2)';
SineWave.prototype.resizeEvent = function () {};
SineWave.prototype._resizeWidth = function () {
  // this.dpr = window.devicePixelRatio || 1;
  this.dpr = 1;
  this.el.width = this.width * this.dpr;
  this.el.height = this.height * this.dpr;
  // this.el.style.width = this.width + 'px';
  // this.el.style.height = this.height + 'px';
  this.waveWidth = this.width * 0.95;
  this.waveLeft = this.width * 0.025;
};
SineWave.prototype.clear = function () {
  this.ctx.clearRect(0, 0, this.width, this.height);
};
SineWave.prototype.time = 0;
SineWave.prototype.update = function (c) {
  this.time = this.time - 0.007;
  if (typeof c === 'undefined') {
    c = this.time;
  }
  var a = -1;
  var b = this.waves.length;
  while (++a < b) {
    var d = this.waves[a].timeModifier || 1;
    this.drawSine(c * d, this.waves[a]);
  }
  a = void 0;
  b = void 0;
};
var PI2 = Math.PI * 2;
var HALFPI = Math.PI / 2;
SineWave.prototype.ease = function (b, a) {
  return a * (Math.sin(b * PI2 - HALFPI) + 1) * 0.5;
};
SineWave.prototype.drawSine = function (d, c) {
  c = c || {};
  var amplitude = c.amplitude || this.amplitude;
  var wavelength = c.wavelength || this.wavelength;
  var lineWidth = c.lineWidth || this.lineWidth;
  var strokeStyle = c.strokeStyle || this.strokeStyle;
  var segmentLength = c.segmentLength || this.segmentLength;
  var e = d;
  var f = 0;
  var a = this.amplitude;
  var g = this.height / 2;
  this.ctx.lineWidth = lineWidth * this.dpr;
  this.ctx.strokeStyle = strokeStyle;
  this.ctx.lineCap = 'round';
  this.ctx.lineJoin = 'round';
  this.ctx.beginPath();
  this.ctx.moveTo(0, g);
  this.ctx.lineTo(this.waveLeft, g);
  for (var b = 0; b < this.waveWidth; b += segmentLength) {
    e = d * this.speed + (-g + b) / wavelength;
    f = Math.sin(e);
    a = this.ease(b / this.waveWidth, amplitude);
    this.ctx.lineTo(b + this.waveLeft, a * f + g);
    a = void 0;
  }
  this.ctx.lineTo(this.width, g);
  this.ctx.stroke();
  c = void 0;
  amplitude = void 0;
  wavelength = void 0;
  lineWidth = void 0;
  strokeStyle = void 0;
  segmentLength = void 0;
  e = void 0;
  f = void 0;
};
SineWave.prototype.loop = function () {
  this.clear();
  this.update();
  window.requestAnimationFrame(this.loop.bind(this));
};
export default SineWave;
