import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, message } from 'antd';
import YunliRTC from '../../rtc';
import checkDeviceSupport from '../../plugins/utils/checkDeviceSupport';
import styles from './index.module.less';
import Call from './Call'; // 拨号、通话
import Player from './Player'; // 播放器
import Incoming from './Incoming'; // 来电
import Invite from './Invite'; // 邀请
import Contacts from './Contacts'; // 通讯录
import Control from './Control'; // 调度人员
import Live from './Live'; // 直播
import Conf from './Conf'; // 会议
import Mobile from './Mobile'; // 移动端
import { toJS } from 'mobx';

function Main({ VideoCallStore, regist, children }) {
  let config = YunliRTC.getConfig();
  const { eventBus } = config;

  const [showTurnplateIndex, setShowTurnplateIndex] = useState(''); // 转盘激活索引

  function showTurnplate(index) {
    setShowTurnplateIndex(index);
  }

  function hideTurnplate() {
    setShowTurnplateIndex('');
  }

  // 注册
  const reg = async (isInit) => {
    let userName,
      urlData = document.location.search.match(/userName=\d+/g);
    if (urlData) {
      // URL中获取userName，后面改成从后台接口中读取
      userName = urlData[0].split('=')[1];
      if (userName) {
        regist.userName = userName;
        regist.confPsw = userName;
      }
    }
    VideoCallStore.sdk = YunliRTC.getSDK(); // sdk版本，用于区分
    // 设置默认主题（Blue）
    VideoCallStore.setTheme(VideoCallStore.theme);

    if (!regist.userName) {
      return;
    }

    // 设备检测
    const deviceSupport = await checkDeviceSupport();
    if (!deviceSupport?.hasMicrophone && !deviceSupport?.hasWebcam) {
      VideoCallStore.deviceSupportTips = '未检测到摄像头和麦克风设备，部分功能无法正常使用';
    }
    if (!deviceSupport?.isMicrophoneAlreadyCaptured || !deviceSupport?.isWebcamAlreadyCaptured) {
      VideoCallStore.deviceSupportTips = '请检查浏览器是否打开摄像头和麦克风权限';
    }

    const result = await YunliRTC.regist({
      ...regist,
      callback: (state) => {
        // 状态变化回调函数
        if (state === 2 || state === 10) {
          // 下线
          VideoCallStore.offline(state);
        }
      }
      // 凌壹
      // ip: 'www.t01md.com.cn', // 111.229.117.19
      // port: '15675',
      // appId: 'beb7da4ced7c42a085c3c99697f9aa42',
      // secretKey: 'ba2468bf083947c5954cf2ccd9f2c9ed',
      // userName: userName,
      // userPsw: '1234',
      // userType: '2', // 2为pc用户，1为app用户
      // confPsw: userName, // 会议密码
      // path: '/tx',
      // api: '/communication-service-ly'
    });
    console.log('yunli-rtc regist result: ', result);
    if (result === 1) {
      VideoCallStore.isRegisted = true;

      if (isInit) {
        // 第一次时的初始化
        YunliRTC.onIncomingCall(({ id, type, status, direction, from, to, cause, originator }) => {
          console.log(
            'onIncomingCall：',
            id,
            type,
            status,
            direction,
            from,
            to,
            cause,
            originator,
            toJS(VideoCallStore)
          );
          let curStatus = VideoCallStore.status,
            callPhoneNum,
            incomingIndex = VideoCallStore.incomingList.findIndex((o) => o.id === id),
            player = VideoCallStore.getPlayer(id, to), // 播放器
            playerIndex = VideoCallStore.getPlayerIndex(id, to), // 播放器index
            isRecall, // 是否需要重复呼叫
            isTalk, // 是否为对讲
            callInfo; // 呼叫信息，eventBus中使用

          // console.log(toJS(VideoCallStore.playerList), playerIndex);
          if (player || VideoCallStore.liveId[to]) {
            // 播放器模式
            isRecall =
              player.times < VideoCallStore.callTimesMax && (cause === '对方不在线' || cause === 'SIP Failure Code');

            // 主动挂断
            if (status === 'ended') {
              if (originator === 'remote') {
                // 只提示对方挂断
                message.error(cause);
              }
              // player.visible = false;
              VideoCallStore.playerRemove(playerIndex);
            }

            // 非主动操作的错误提示
            if (status === 'failed') {
              if (originator === 'remote') {
                // 重拨逻辑
                if (isRecall) {
                  if (player.times === 0) {
                    // 第一次失败时，发送来电提醒的push给app
                    VideoCallStore.sendPush({
                      title: '一体化指挥呼叫',
                      messageBody: '您有新的来电，请注意接听！',
                      userId: player.phoneNum
                    });
                  }
                  player.times++;
                  player.retryDelay = setTimeout(() => {
                    YunliRTC.call(player.phoneNum, {
                      video: true,
                      media: VideoCallStore.playerVideo(playerIndex),
                      isPlayer: true,
                      callback: (res) => {
                        player.id = res.id;
                      }
                    });
                  }, 3000);
                } else {
                  message.error(cause);
                  // player.visible = false;
                  VideoCallStore.playerRemove(playerIndex);
                }
              }
            }

            player.status = status;
          } else {
            // 通话模式
            isRecall =
              VideoCallStore.callTimes < VideoCallStore.callTimesMax &&
              (cause === '对方不在线' || cause === 'SIP Failure Code');
            if (VideoCallStore.isIncomingAllAudio && type === 'video') {
              // 视频来电使用语音接听
              type = 'audio';
            }
            callInfo = {
              id: VideoCallStore.id,
              status: status,
              direction,
              type,
              caller: {
                type: 0,
                id: config.userName,
                name: config.userNick
              },
              call: {
                type: VideoCallStore.getCallType(VideoCallStore.callPhoneNum),
                contactsId: VideoCallStore.getContactsId(VideoCallStore.callPhoneNum),
                id: VideoCallStore.callPhoneNum,
                name: VideoCallStore.callNickname
              }
            };
            VideoCallStore.callInfo = callInfo;

            // 音视频通话
            if (from) {
              // 来电
              isTalk = from.startsWith('8878');

              // 去掉来电的0开头的手机号（航美有的区域，返回联通手机号会多加0，使用的时候手动去掉）
              if (from.startsWith('0') && from.length === 12) {
                from = from.slice(1);
              }
            }

            if (direction === 'outgoing' && to) {
              // 呼出
              callPhoneNum = to;
              isTalk = callPhoneNum.startsWith('8878');
            }

            if (direction === 'incoming') {
              // 来电
              if (status === 'progress') {
                if (from) {
                  if (isTalk) {
                    // 对讲自动接听
                    // message.success(`收到对讲，播放中`);
                    // YunliRTC.answer({
                    //   id: id,
                    //   media: VideoCallStore.talkAudio(),
                    //   video: false,
                    //   callback: () => {
                    //     // VideoCallStore.id = res.id;
                    //   }
                    // });
                  } else {
                    // 普通来电
                    if (incomingIndex === -1 && from !== to && VideoCallStore.isRegisted) {
                      VideoCallStore.incomingList.push({
                        id, // session ID
                        type, // 来电类型
                        visible: true, // 是否可见
                        phoneNum: from, // 来电ID
                        nickName: VideoCallStore.getContactName(from) // 来电昵称
                      });
                      VideoCallStore.playIncoming();
                      // 显示来电位置
                      VideoCallStore.getLocation(from).then((res) => {
                        VideoCallStore.showLocation(id, res);
                      });
                      eventBus.emit('yunli-rtc-turnplate-incoming', {
                        ...callInfo,
                        id,
                        call: { type: 0, id: config.userName, name: config.userNick },
                        caller: {
                          type: VideoCallStore.getCallType(from),
                          id: from,
                          name: VideoCallStore.getContactName(from)
                        }
                      });
                    }
                  }
                }
              } else if (status === 'accepted') {
                if (VideoCallStore.type === 'audio' && type === 'video') {
                  // 接听视频来电被显示成语音的情况，需要手动打开摄像头
                  setTimeout(() => {
                    VideoCallStore.showLocalVideo();
                  }, 500);
                }
                VideoCallStore.type = type; // 来电类型
                VideoCallStore.status = status;
                VideoCallStore.direction = direction;
                console.log('呼入接通...');
                eventBus.emit('yunli-rtc-turnplate-start', callInfo);
              }
            } else {
              // 通话
              if (isTalk) {
                // console.log('开始对讲');
              } else {
                if (!isRecall) {
                  VideoCallStore.type = type; // 来电类型
                  VideoCallStore.status = status;
                  VideoCallStore.direction = direction;
                  if (status === 'accepted') {
                    console.log('呼出接通...');
                    eventBus.emit('yunli-rtc-turnplate-start', callInfo);
                  }
                }
              }
            }

            // 挂断
            if (status === 'ended') {
              // 触发挂断事件
              if (!isTalk) {
                console.log('挂断...');
                eventBus.emit(
                  'yunli-rtc-turnplate-hangup',
                  direction === 'incoming'
                    ? {
                        ...callInfo,
                        id,
                        call: { type: 0, id: config.userName, name: config.userNick },
                        caller: {
                          type: VideoCallStore.getCallType(from),
                          id: from,
                          name: VideoCallStore.getContactName(from)
                        }
                      }
                    : callInfo
                );
              }
            }

            // 挂断或非主动操作的错误提示
            if (status === 'failed' || status === 'ended') {
              if (isTalk) {
                // message.success('对讲已结束');
              } else {
                if (incomingIndex != -1 && VideoCallStore.incomingList.length && direction === 'incoming') {
                  // 来电挂断
                  VideoCallStore.incomingList[incomingIndex].visible = false;
                  setTimeout(() => {
                    VideoCallStore.incomingRemove(incomingIndex);
                    VideoCallStore.hideLocation(id);
                    VideoCallStore.stopIncoming();
                  }, 500);
                } else {
                  // 非来电挂断
                  if (curStatus === 'accepted') {
                    VideoCallStore.isCallVisible = false;
                  }
                  if (!isRecall) {
                    VideoCallStore.status = 'default';
                    VideoCallStore.type = '';
                  }
                  VideoCallStore.id = '';
                  if (VideoCallStore.isMobile) {
                    // 移动端挂断后，直接不显示拨号，体验会好一些
                    VideoCallStore.isCallVisible = false;
                  }
                  VideoCallStore.hideLocalVideo(); // 非来电时，关闭本地摄像头（来电调用，会影响视频会议的本地摄像头）
                  if (originator === 'remote') {
                    // 重拨逻辑
                    if (isRecall) {
                      if (VideoCallStore.callTimes === 0) {
                        // 第一次失败时，发送来电提醒的push给app
                        VideoCallStore.sendPush({
                          title: '一体化指挥呼叫',
                          messageBody: '您有新的来电，请注意接听！',
                          userId: VideoCallStore.callPhoneNum
                        });
                      }
                      VideoCallStore.callTimes++;
                      VideoCallStore.callRetryDelay = setTimeout(() => {
                        VideoCallStore.call({
                          id: VideoCallStore.callPhoneNum,
                          name: VideoCallStore.callNickName,
                          isRecall: true
                        });
                      }, 3000);
                    } else {
                      message.error(cause);
                      if (status === 'failed') {
                        console.log('未接通...');
                        eventBus.emit('yunli-rtc-turnplate-fail', callInfo);
                      }
                    }
                  } else {
                    // 主动挂断，不用判断isRecall
                    VideoCallStore.status = 'default';
                    VideoCallStore.id = '';
                    console.log('未接通...');
                    eventBus.emit('yunli-rtc-turnplate-fail', callInfo);
                  }
                }
              }
            }
          }
        });

        YunliRTC.onConfChange((res, config) => {
          let incomingIndex = VideoCallStore.incomingList.findIndex((o) => o.type === 'conf'),
            members = toJS(VideoCallStore.confMemberList), // 用户列表
            member; // 当前用户
          if (res.op !== 'rspHeartbeat') {
            console.log(res, config, toJS(VideoCallStore), 'conf change...');
          }
          VideoCallStore.confId = res.confid || res.sender?._sessionId || VideoCallStore.confId;

          // 航美
          if (VideoCallStore.sdk !== 'ly') {
            VideoCallStore.isConfLoading = false;

            // 先去掉语音会议的情况，统一使用视频会议（通话转会议之后，无法区分是视频会议还是语音会议），语音的话就是不显示画面
            // VideoCallStore.isConfVideo = res.sender?.isCaller ? true : res.sender?._isVideo; // 识别会议来电是否为视频会议，否则为音频会议

            if (res.sender?._members) {
              // 不用手动插入了，延迟响应onConfChange可以解决这个问题
              // const caller = res.sender.caller;
              // if (caller && !res.sender._members[caller]) {
              //   // 初始的enter事件中的sender._members，不知道为什么没有返回会议发起人，手动插入，模拟数据
              //   res.sender._members[caller] = {
              //     number: caller,
              //     name: caller,
              //     allowListen: true,
              //     allowSpeak: true,
              //     callState: 6,
              //     hasVideo: true,
              //     muteLocalCamera: false,
              //     muteLocalMic: false
              //   };
              // }
              for (let i in res.sender._members) {
                // 将sender._members中成员，追加到列表中，接听会议时，无法从enter事件中得到完整的成员列表
                if (i && !members.filter((o) => '' + o.id === i).length) {
                  members.push(
                    VideoCallStore.initMember({
                      suid: i,
                      nickname: i,
                      isonline: res.sender.isCaller ? false : true, // 主动拨打时需要设置为false避免渲染抖动，被动接听时需要设置true解决已有成员无法显示视频
                      ismuted: !res.sender._members[i].allowSpeak, // 是否静音
                      cameras: [{ iscameraon: true }],
                      id: i,
                      name: i
                    })
                  );
                }
              }
            }
            member = members.filter((o) => '' + o.id === res.member);
            if (res.op === 'connected') {
              // 入会
              // console.log(member, res.member, 9999);
              if (member?.length) {
                member[0].isonline = true;
              } else {
                members.push(
                  VideoCallStore.initMember({
                    suid: res.member,
                    nickname: res.member,
                    isonline: true,
                    // isOpenVideo: true,
                    // isCloseVideo: false,
                    cameras: [{ iscameraon: true }],
                    id: res.member,
                    name: res.member
                  })
                );
              }
            } else if (res.op === 'leave' || res.op === 'ringing') {
              // 离会
              if (member?.length) {
                member[0].isonline = false;
                member[0].isOpenVideo = false;
                member[0].isCloseVideo = false;
                member[0].ismuted = false;
              }
            } else if (res.op === 'UnMuteLocalMic') {
              // 成员取消静音
              if (member?.length) {
                member[0].ismuted = false;
              }
            } else if (res.op === 'MuteLocalMic') {
              // 成员静音
              if (member?.length) {
                member[0].ismuted = true;
              }
            } else if (res.op === 'MuteLocalCamera') {
              // 成员关闭视频
              if (member?.length) {
                member[0].isCloseVideo = true;
              }
            } else if (res.op === 'UnMuteLocalCamera') {
              // 成员打开视频
              if (member?.length) {
                member[0].isCloseVideo = false;
              }
            } else if (res.op === 'Leave' || res.op === 'Kickout' || res.op === 'Terminaled') {
              // 挂断或踢出
              message.error('已挂断');
              if (incomingIndex != -1 && VideoCallStore.incomingList.length) {
                // 挂断会议来电
                VideoCallStore.incomingList[incomingIndex].visible = false;
                setTimeout(() => {
                  VideoCallStore.incomingRemove(incomingIndex);
                  VideoCallStore.stopIncoming();
                }, 500);
              } else if (res.member === config.userName) {
                // 会议调度台被踢出
                VideoCallStore.isConfVisible = false;
                VideoCallStore.hideCallTime();
                VideoCallStore.hideLocalVideo();
                // console.log('会议结束...');
                // eventBus.emit('yunli-rtc-turnplate-conf-end', {
                //   id: toJS(VideoCallStore.confId),
                //   caller: {
                //     type: 0,
                //     id: config.userName,
                //     name: config.userNick
                //   }
                // });
                VideoCallStore.confMemberList = [];
                VideoCallStore.confId = '';
                VideoCallStore.status = 'default';
              }
            }
            VideoCallStore.confMemberList = members;
            // 显示视频控制
            if (res.op === 'connected' || res.op === 'leave') {
              setTimeout(() => {
                members = toJS(VideoCallStore.confMemberList);
                members.forEach((o, i) => {
                  // 打开视频，第一个默认是调度台自己
                  // 第一个也需要调用打开摄像头，否则会有第一次关闭时，无法关闭的问题
                  if (!o.isCloseVideo && !o.isOpenVideo) {
                    let media = VideoCallStore.confVideo(o.id);
                    if (media.nodeName) {
                      o.isOpenVideo = true;
                      setTimeout(() => {
                        if (o.id === config.userName) {
                          // 航美显示自己的本地视频
                          // VideoCallStore.showLocalVideo(media);
                          media = null;
                        }
                        // 延迟来解决视频流串了的问题，连续调用openConfVideo会有串流的现象
                        try {
                          YunliRTC.openConfVideo(o.id, {
                            media: media
                          });
                        } catch (e) {
                          console.log(e);
                        }
                      }, i * 100);
                    }
                  }
                });
                VideoCallStore.confMemberList = members;
              }, 200);
            }

            // 成员变化事件
            if (res.op === 'connected' || res.op === 'leave' || res.op === 'enter') {
              console.log(members, res.sender, 'join members...');
              eventBus.emit('yunli-rtc-turnplate-conf-join', {
                id: VideoCallStore.confId,
                caller: {
                  type: 0,
                  id: config.userName,
                  name: config.userNick
                },
                list: members
              });
            }
          }

          // 凌壹
          if (res.op === 'rspGetConfTerList') {
            // 成员列表
            if (res.speaklist) {
              res.speaklist.forEach((o) => {
                VideoCallStore.initMember(o);
              });
            }
            VideoCallStore.confMemberList = res.speaklist || [];
            console.log('会议成员...', VideoCallStore.confMemberList);
            // 调度台号码无法从列表中获取，强制设置为当前登录的调度台，不支持其他调度台账号的情况
            eventBus.emit('yunli-rtc-turnplate-conf-join', {
              id: res.confid,
              caller: {
                type: 0,
                id: config.userName,
                name: config.userNick
              },
              list: VideoCallStore.confMemberList.map((o) =>
                o.type === 1
                  ? {
                      type: 0,
                      id: config.userName,
                      name: config.userNick
                    }
                  : {
                      type: VideoCallStore.getCallType(o.nickname),
                      contactsId: VideoCallStore.getContactsId(o.nickname),
                      id: o.nickname,
                      name: VideoCallStore.getContactName(o.nickname)
                    }
              )
            });
          } else if (res.op === 'rspTerJoinConf') {
            // 成员加入
            let confMemberList = toJS(VideoCallStore.confMemberList),
              isInList;
            VideoCallStore.initMember(res);
            confMemberList.forEach((o) => {
              // 已在列表，更新已有属性
              if (o.id === res.id) {
                for (let i in res) {
                  o[i] = res[i];
                }
                isInList = true;
              }
            });
            if (!isInList) {
              // 新增
              confMemberList.push(res);
            }
            VideoCallStore.confMemberList = confMemberList;
            console.log('会议成员...', VideoCallStore.confMemberList);
            // 调度台号码无法从列表中获取，强制设置为当前登录的调度台，不支持其他调度台账号的情况
            eventBus.emit('yunli-rtc-turnplate-conf-join', {
              id: res.confid,
              caller: {
                type: 0,
                id: config.userName,
                name: config.userNick
              },
              list: VideoCallStore.confMemberList.map((o) =>
                o.type === 1
                  ? {
                      type: 0,
                      id: config.userName,
                      name: config.userNick
                    }
                  : {
                      type: VideoCallStore.getCallType(o.nickname),
                      contactsId: VideoCallStore.getContactsId(o.nickname),
                      id: o.nickname,
                      name: VideoCallStore.getContactName(o.nickname)
                    }
              )
            });
          } else if (res.op === 'rspTerLeaveConf') {
            // 成员离开
            let confMemberList = toJS(VideoCallStore.confMemberList),
              isOutList;
            confMemberList.forEach((o) => {
              if (o.id === res.id) {
                o.isonline = false;
                isOutList = true;
              }
            });
            if (isOutList) {
              VideoCallStore.confMemberList = confMemberList;
            }
            console.log('会议成员...');
          } else if (res.op === 'rspMuteOne') {
            // 成员禁言
            let confMemberList = toJS(VideoCallStore.confMemberList);
            confMemberList.forEach((o) => {
              if (o.terid === res.jointerid) {
                o.ismuted = true;
              }
            });
            VideoCallStore.confMemberList = confMemberList;
          }
        });
      }

      // 开启新消息
      YunliRTC.getMessage({
        callback: (data) => {
          /*
          {"THIRDUSERID":"101501","UID":"101501","APPID":"beb7da4ced7c42a085c3c99697f9aa42","OPERATION":"ConfStart","THIRDCONFID":"70000025","MSG_KEY":"CALL_BACK","CERTCODE":"FC3CBE54AC5FF7D974F8BA098037E144B05F410D98C28125","SUBJECT":"70000025","CONFID":"783404756088524800"}
          */
          if (data.CONFID) {
            // 会议邀请，比较特殊，放到即时消息的推送中进行通知
            VideoCallStore.status = 'progress';
            VideoCallStore.direction = 'incoming';
            VideoCallStore.confInfo = data;
            VideoCallStore.incomingList.push({
              type: 'conf',
              phoneNum: data.UID + '邀请你加入',
              nickName: data.UID + '发起的视频会议',
              visible: true
            });
          } else if (data.SENDERNAME && data.CONTENT) {
            // 即时消息
            message.info(data.SENDERNAME + ':' + data.CONTENT);
          }
        }
      });

      console.log('yunli-rtc 登录成功！');
      eventBus.emit('yunli-rtc-turnplate-login');
    } else {
      VideoCallStore.isRegisted = false;
    }
  };

  useEffect(() => {
    // 限制多个提示只显示最后一个，存在某些场景弹出多个提示的情况（添加事件）
    message.config({
      maxCount: 1
    });

    // eslint-disable-next-line no-use-before-define
    eventBus.on('yunli-rtc-turnplate', turnplateEmitterEvt);
    reg(true);

    window.addEventListener('offline', () => {
      // 网络掉线，手动下线
      // 问题1：页面非激活状态下，切到浏览器的其他页卡，有时候能收到来电提醒，但是收不到挂断提醒
      // 问题2：电脑睡眠后，无法触发offline事件
      VideoCallStore.offline();
    });

    // 电脑睡眠处理，睡眠后直接当做下线处理（航美的来电提醒有bug，挂断的来电还会推送，但没有触发挂断事件）
    var RATE = 3000;
    var lastTime = Date.now();
    function isSleep() {
      if (Date.now() - lastTime >= RATE * 2) {
        VideoCallStore.offline();
      }
      // console.log(Date.now() - lastTime);
      lastTime = Date.now();
      setTimeout(isSleep, RATE);
    }
    isSleep();

    return () => {
      // eslint-disable-next-line no-use-before-define
      eventBus.off('yunli-rtc-turnplate', turnplateEmitterEvt);
    };
  }, []);

  const turnplateEmitterEvt = async ({ key, value, options, target }) => {
    const { title, url, onCancel, checked } = value || {};
    if ((key === 'call' || key === 'conf') && !VideoCallStore.isRegisted && VideoCallStore.isAutoOnline) {
      // 通话或会议自动登录
      await reg();
    }
    switch (key) {
      case 'regist': // 登录
        YunliRTC.unRegist();
        regist.userName = value.id;
        regist.userPsw = value.password;
        regist.confPsw = value.id;
        reg();
        break;
      case 'dial': // 拨号
        if (VideoCallStore.status !== 'default') {
          message.error((VideoCallStore.status === 'accepted' ? '通话' : '拨号') + '中，无法呼叫');
          return;
        }
        if (!(VideoCallStore.isCallVisible && VideoCallStore.status !== 'default')) {
          VideoCallStore.callPhoneNum = '';
          VideoCallStore.callNickname = '未知人员';
          VideoCallStore.isCallVisible = !VideoCallStore.isCallVisible;
        }
        if (value) {
          // 默认号码
          VideoCallStore.callPhoneNum = value.id;
          VideoCallStore.callNickname = value.name;
        }
        break;
      // case 'call': // 音视频电话
      //   VideoCallStore.call(value);
      //   break;
      case 'control': // 人员调度
        VideoCallStore.isControlVisible = true;
        VideoCallStore.contactsListChecked = value || [];
        break;
      case 'live': // 多个直播
        VideoCallStore.isLiveVisible = true;
        VideoCallStore.isMax = false;
        if (url) {
          VideoCallStore.isLiveUrl = true;
          VideoCallStore.getLiveVideoUrl = url;
        }
        VideoCallStore.onLiveCancel = onCancel; // 关闭回调
        if (checked) {
          VideoCallStore.contactsListChecked = checked;
        }
        VideoCallStore.liveTitle = title || '';
        break;
      case 'contacts': // 通讯录
        VideoCallStore.isContactsVisible = true;
        break;
      case 'invite': // 邀请
        VideoCallStore.isInviteVisible = true;
        if (value) {
          if (value.title) {
            VideoCallStore.inviteTitle = value.title;
          }
          if (value.okText) {
            VideoCallStore.inviteOkText = value.okText;
          }
          if (value.callback) {
            VideoCallStore.inviteOk = value.callback;
          }
        } else {
          VideoCallStore.inviteTitle = '';
          VideoCallStore.inviteOkText = '';
          VideoCallStore.inviteOk = '';
        }
        break;
      // case 'conf': // 会议
      //   VideoCallStore.conf(value);
      //   break;
      case 'get': // 读取属性
        if (value && target) {
          if (typeof value === 'string') {
            target[value] = toJS(VideoCallStore[value]);
          } else {
            value.forEach((o) => {
              target[o] = toJS(VideoCallStore[o]);
            });
          }
          // return toJS(VideoCallStore[value]);
        }
        break;
      case 'set': // 设置属性
        if (value) {
          for (var i in value) {
            VideoCallStore[i] = value[i];

            // 设置主题样式
            if (i === 'theme') {
              VideoCallStore.setTheme(value[i]);
            }
          }
        }
        break;
      default:
        if (VideoCallStore[key]) {
          // 其他方法
          VideoCallStore[key](value, options);
        }
        break;
    }
  };

  return (
    <>
      <div
        className={styles.turnplate + ' ' + (showTurnplateIndex ? styles['active' + showTurnplateIndex] : '')}
        style={{ display: VideoCallStore.isTurnplateVisible ? '' : 'none' }}>
        {children?.map((o, i) => (
          <div
            key={i}
            className={styles['btnCircle' + i]}
            onMouseEnter={() => {
              showTurnplate(i + 1);
            }}
            onMouseLeave={() => {
              hideTurnplate();
            }}>
            {o}
          </div>
        ))}
      </div>
      <div style={{ display: 'none' }}>
        <audio id={VideoCallStore.remoteAudioId} autoPlay />
        <audio id={VideoCallStore.talkAudioId} autoPlay />
        <video id={VideoCallStore.incomingAudioId} loop autoPlay />
      </div>
      {VideoCallStore.isMobile ? (
        <Mobile />
      ) : (
        <>
          <Incoming />
          <Conf />
          <Control />
          <Invite />
          <Modal
            className={VideoCallStore.css + 'Dialog isSmall isLower'}
            title="通讯录"
            width={520}
            open={VideoCallStore.isContactsVisible}
            centered
            footer={null}
            onOk={() => {}}
            onCancel={() => {
              VideoCallStore.isContactsVisible = false;
            }}>
            {VideoCallStore.isContactsVisible ? <Contacts /> : ''}
          </Modal>
          <Call />
          <Player />
          <Live />
        </>
      )}
    </>
  );
}

export default inject('VideoCallStore')(observer(Main));
