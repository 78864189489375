import { runCallbacks } from './regist';
import { getConfig, setConfig } from '../config';
// import eventBus from '@/plugins/eventBus';

const client = window.XYClient?.DispatcherClient?.getInstance();
let currentConference;
let acceptMediaControl;

const config = getConfig();

// 会议各类事件监听
function setConfListener() {
  const { eventBus } = config;
  currentConference.stateChange.on((param) => {
    var op;
    switch (param.state) {
      case 1:
        op = 'connecting';
        break;
      case 2:
        op = 'Connected';
        break;
      case 3:
        op = 'Kickout';
        break;
      case 4:
        op = 'Terminaled';
        break;
      case 5:
        op = 'Leave';
        break;
      default:
        break;
    }
    param.op = op;
    setTimeout(() => {
      // 不延迟的话，读不到sender._members中的完整列表，也读不到sender.callingNumber
      if (param.sender?.callingNumber) {
        param.member = param.sender.callingNumber;
      }
      eventBus.emit('confChange', param);
    }, 100);
  });

  currentConference.memberStateChange.on((param) => {
    var op;
    switch (param.state) {
      case 0:
        op = 'enter';
        break;
      case 1:
        op = 'leave';
        break;
      case 2:
        op = 'speak';
        break;
      case 3:
        op = 'unspeak';
        break;
      case 4:
        op = 'listen';
        break;
      case 5:
        op = 'unlisten';
        break;
      case 6:
        op = 'reject';
        break;
      case 7:
        op = 'ringing';
        break;
      case 8:
        op = 'connected';
        break;
      case 9:
        op = 'MuteLocalMic';
        break;
      case 10:
        op = 'UnMuteLocalMic';
        break;
      case 11:
        op = 'MuteLocalCamera';
        break;
      case 12:
        op = 'UnMuteLocalCamera';
        break;
      default:
        break;
    }
    param.op = op;
    // console.log('conference member：' + param.member + ', statechange: ' + op, param);
    setTimeout(() => {
      // 不延迟的话，读不到sender._members中的完整列表
      eventBus.emit('confChange', param);
    }, 100);
  });

  currentConference.currentVideoChanged.on((param) => {
    console.log('currentVideoChanged: ' + param.member);
  });

  currentConference.pushAudioToRTSPStateChanged.on((param) => {
    console.log('pushAudioToRTSPStateChanged: ' + param.state);
  });
}

client?.receiveConferenceInvite?.on((param) => {
  // console.log(
  //   param,
  //   'receiveConferenceInvite: isMCU ' +
  //     param.conference.isMCU +
  //     ', video: ' +
  //     param.conference.video +
  //     ', caller: ' +
  //     param.conference.caller +
  //     ', isSchedule: ' +
  //     param.conference.isSchedule
  // );

  currentConference = param.conference;

  setConfListener();

  runCallbacks({
    id: currentConference._sessionId,
    status: 'progress',
    type: 'conf', // 无法区分是语音会议还是视频会议
    direction: currentConference.isCaller ? 'outgoing' : 'incoming', // 来电
    from: currentConference.caller,
    to: config.userName,
    originator: config.callOriginator === 'local' ? 'local' : 'remote', // 无法区分动作是否来源于对方，统一按手动设置的callOriginator来处理
    cause: '已挂断' // 处理失败等状态原因，暂时没有cause原因内容，统一按已挂断来处理
  });

  setConfig({
    callOriginator: ''
  });
});

client?.handleAnswerIncomingConference?.on((conf) => {
  console.log(
    'handleAnswerIncomingConference: isMCU ' +
      conf.isMCU +
      ', video: ' +
      conf.video +
      ', caller: ' +
      conf.caller +
      ', isSchedule: ' +
      conf.isSchedule
  );
  currentConference = conf;

  setConfListener();
  currentConference.hangdleRequestVideo(acceptMediaControl);
});

/**
 * 创建会议
 * @param {Array[string]} members 会议成员
 * @param {HTMLElement} media 媒体控制，Video | Audio Dom
 * @param {Boolean} video 视频会议？
 * @returns {Boolean} 是否创建成功
 */
export async function createConf(members, { media, video = true, callback, target }) {
  try {
    if (target) {
      // 已经创建好的会议（单呼转会议）
      currentConference = target;
    } else {
      const conference = await client.startConference(members, media, '', video);
      currentConference = conference;
    }
    setConfListener();
    console.log('startConference success: ', currentConference);
    callback && callback({ id: currentConference._sessionId });
    return true;
  } catch (error) {
    console.log('startConference fail: ', error);
  }
  return false;
}

// 弃用
// export async function openMemberVideo(member, media) {
// try {
//   const result = await currentConference.openMemberVideo(member, media);
//   console.log('openMemberVideo result: ', result);
// } catch (error) {
//   console.log('openMemberVideo fail: ' + error);
// }
// }

// 添加成员
export async function addMember(member) {
  console.log(member, 'add member...');
  currentConference.add(member);
  // try {
  //   const result = await currentConference.openMemberVideo(
  //     member,
  //     media
  //   );
  //   console.log('openMemberVideo result: ', result);
  // } catch (error) {
  //   console.log('openMemberVideo fail: ' + error.message);
  // }
}

export async function getMembers({ video = true }) {
  if (video) {
    // const list = await conf.list();
    // callback && callback(list);
    // return list;
  }
}

// 移出成员
export async function removeMember(member, options = {}) {
  // console.log(member, 'remove member...');
  const { video = true, callback } = options;
  if (video) {
    currentConference.remove(member);
    callback && callback();
  }
}

export function getConf() {}

// 分享屏幕（屏幕共享）
export function shareScreen(options = {}) {
  const { callback } = options;
  currentConference.shareScreen(true);
  callback && callback({});
}

// 结束会议
export function endConf(id, options = {}) {
  const { callback } = options;
  currentConference.end();
  callback && callback({});
}

// 静音
export function muteConf(member = '', options = {}) {
  const { callback } = options;
  currentConference.allowSpeak(member || config.userName, false);
  callback && callback({});
}

// 取消静音
export function unmuteConf(member = '', options = {}) {
  const { callback } = options;
  currentConference.allowSpeak(member || config.userName, true);
  callback && callback({});
}

// 关闭视频
export function closeConfVideo(member = '', options = {}) {
  const { callback, media } = options;
  let userName = member || config.userName;
  if (userName === config.userName) {
    currentConference.muteVideo();
  } else {
    currentConference.closeMemberVideo(member || config.userName, media);
  }
  callback && callback({});
}

// 打开视频
export function openConfVideo(member = '', options = {}) {
  const { callback, media } = options;
  let userName = member || config.userName;
  if (userName === config.userName) {
    currentConference.unmuteVideo();
  } else {
    currentConference.openMemberVideo(member || config.userName, media);
  }
  callback && callback({});
}

// 设置焦点视频
export function focusConfVideo(member = '', options = {}) {
  const { callback } = options;
  currentConference.setConferenceVideo(member || config.userName);
  callback && callback({});
}

// 接听视频会议
export function acceptConf(options = {}) {
  const { media } = options;
  acceptMediaControl = media;
  currentConference.accept(media, true);
}

// 接听语音会议
export function acceptAudioConf(options = {}) {
  const { media } = options;
  acceptMediaControl = media;
  currentConference.accept(media, false);
}

// 退出会议
export function exitConf(options = {}) {
  const { callback } = options;
  currentConference.exit();
  callback && callback({});
}

// 会议状态事件
export function onConfChange(callback) {
  const { eventBus } = config;
  eventBus.on('confChange', (data) => {
    callback && callback(data, config);
  });
}
