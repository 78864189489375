let treeIndex = 0, // 树的索引，防止key冲突
  treeKey = [0],
  treeKeyLevel = 0,
  callbackDo,
  rules = {};
const to = (data, index, parentName) => {
  for (var i in rules) {
    if (Array.isArray(rules[i])) {
      rules[i].forEach((o) => {
        data[i] = data[i] || data[o];
      });
    } else {
      data[i] = data[i] || data[rules[i]];
    }
  }
  treeKey[treeKeyLevel + 1] = index;
  data.key = treeIndex + '-' + treeKey.join('-');
  callbackDo && callbackDo(data);
  if (data.children) {
    data.children.forEach((o, i) => {
      if (parentName) {
        o.parentName = parentName;
      }
      treeKeyLevel++;
      to(o, i, (o.parentName ? o.parentName + '-' : '') + o.contactsLabelName);
      treeKey.splice(treeKey.length - 1, 1);
      treeKeyLevel--;
    });
  }
};

// 转为树数据
const toTreeData = (data, options, callback) => {
  rules = options;
  callbackDo = callback;
  data.forEach((o, i) => {
    treeKeyLevel = 0;
    to(o, i, o.contactsLabelName);
  });
  treeIndex++;
};

export default toTreeData;
