import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Modal, message, Tabs, Tree, Tooltip, Table, Empty } from 'antd';
import YunliRTC from '../../rtc';
import styles from './invite.module.less';

const Invite = ({ VideoCallStore }) => {
  let config = YunliRTC.getConfig();
  const eventBus = config.eventBus;

  const [tabIndex, setTabIndex] = useState('1');
  // 通讯录
  // const [contactsList, setContactsList] = useState([]);
  const [contactsListChecked, setContactsListChecked] = useState([]);
  // 监控
  // const [equipmentList, setEquipmentList] = useState([]);
  const [equipmentListChecked, setEquipmentListChecked] = useState([]);

  eventBus.on('yunli-rtc-turnplate-conf-end', () => {
    // 关闭会商的时候初始化数据
    contactsListChecked.forEach((o) => {
      o.disabled = false;
    });
    equipmentListChecked.forEach((o) => {
      o.disabled = false;
    });
    setContactsListChecked([]);
    setEquipmentListChecked([]);
  });

  function onClose() {
    // 对话框关闭
    VideoCallStore.isInviteVisible = false;
    // setContactsListChecked([]);
    // setEquipmentListChecked([]);
    setTabIndex('1');
  }

  function onChangeTab(e) {
    // 页卡切换
    setTabIndex(e);
  }

  function onCheck(keys, e) {
    console.log(keys, e);
    let checked = [];
    e.checkedNodes.forEach((o) => {
      if (o.contactsId) {
        checked.push(o);
      }
    });
    if (tabIndex === '1') {
      setContactsListChecked(checked);
    } else {
      setEquipmentListChecked(checked);
    }
  }

  function onSelect(keys, e) {
    console.log(keys, e);
    let el = e.nativeEvent.target;
    if (el.className === 'ant-tree-title') {
      el.parentNode.previousSibling?.click();
    } else {
      el.previousSibling?.click();
    }
  }

  useEffect(() => {
    setTimeout(async () => {
      // 组件options生效后再加载，不能等打开邀请的时候再加载，成员名称显示（会商、来电、拨号）需要通讯录里的姓名信息
      await VideoCallStore.getContactsList();
      // setContactsList(list);
      await VideoCallStore.getEquipmentList();
      // setEquipmentList(list);
    });
    /*
    return;

    setContactsList([
      {
        title: '云粒APP1（70001037）',
        mobile: '70001037',
        contactsId: '70001037',
        key: '0-0',
        children: []
      },
      {
        title: '云粒APP2（70001038）',
        mobile: '70001038',
        contactsId: '70001038',
        key: '0-1'
      },
      {
        title: '云粒调度台1（70000025）',
        mobile: '70000025',
        contactsId: '70000025',
        key: '0-2'
      },
      {
        title: '云粒调度台2（70000026）',
        mobile: '70000026',
        contactsId: '70000026',
        key: '0-3'
      }
    ]);
    setEquipmentList([
      {
        title: '万柳西路（8880006）',
        callId: '8880006',
        contactsId: '8880006',
        key: '0-0'
      },
      {
        title: '知春路181（8880004）',
        callId: '8880004',
        contactsId: '8880004',
        key: '0-1'
      },
      {
        title: '琉璃井胡同4号（8880011）',
        callId: '8880011',
        contactsId: '8880011',
        key: '0-2'
      }
    ]);
    */
  }, []);

  return (
    <Modal
      title={VideoCallStore.inviteTitle || '邀请成员'}
      className={VideoCallStore.css + 'Dialog isSmall'}
      width={692}
      centered
      open={VideoCallStore.isInviteVisible}
      zIndex="1001"
      cancelButtonProps={{ style: { display: 'none' } }}
      okText={VideoCallStore.inviteOkText || '邀请'}
      okButtonProps={{ className: VideoCallStore.css + 'Btn' }}
      onOk={() => {
        let list = toJS([
            // ...new Set([
            //   ...contactsListChecked.filter((o) => !o.disabled).map((o) => o.mobile),
            //   ...equipmentListChecked.filter((o) => !o.disabled).map((o) => o.callId)
            // ])
            ...contactsListChecked.filter((o) => !o.disabled),
            ...equipmentListChecked.filter((o) => !o.disabled)
          ]),
          ids = [...new Set([...list.map((o) => o.mobile || o.callId)])];
        if (ids.length) {
          onClose();
          if (VideoCallStore.inviteOk) {
            // 自定义邀请事件
            VideoCallStore.inviteOk(list);
          } else {
            // 会商邀请成员事件
            if (VideoCallStore.isConfVisible) {
              // 已经打开会商
              ids.forEach((o) => {
                const hsUser = VideoCallStore.contactsHash[o]?.hsUser; // 查询通讯录中id对应的账号hsUser值
                YunliRTC.addMember(hsUser || o, {
                  isApp: !!hsUser,
                  callback: (res) => {
                    // 未入会的昵称转换处理，未入会suid跟呼叫id不是一个值，原有hash匹配不上
                    VideoCallStore.confMemberHash[res?.data?.suid] = VideoCallStore.confMemberHash[o];
                    setTimeout(() => {
                      VideoCallStore.getMembers();
                    }, 1000);
                  }
                });
              });
              message.success('邀请已发送');
            } else {
              // 新建会商
              VideoCallStore.conf(ids);
            }
          }
          contactsListChecked.forEach((o) => {
            o.disabled = true;
          });
          equipmentListChecked.forEach((o) => {
            o.disabled = true;
          });
        } else {
          message.error('请选择成员');
          // 本地测试用
          // YunliRTC.addMember('70000026', {
          //   callback: (res) => {}
          // });
        }

        // 设置Hash
        if (!VideoCallStore.inviteOk) {
          contactsListChecked.forEach((o) => {
            o.id = o.mobile;
            o.name = o.contactsName;
            VideoCallStore.confMemberHash[o.id] = o;
          });
          equipmentListChecked.forEach((o) => {
            o.id = o.callId;
            o.name = o.contactsName;
            VideoCallStore.confMemberHash[o.id] = o;
          });
        }
      }}
      onCancel={() => {
        onClose();
      }}>
      <div className={styles.invite}>
        <Tabs
          tabPosition="left"
          type="card"
          activeKey={tabIndex}
          items={[
            {
              key: '1',
              label: `通讯录`,
              children: (
                <div className={styles.cont}>
                  <div className={styles.title}>选择参会人员</div>
                  <Tree
                    className={VideoCallStore.css + 'Tree isRight'}
                    checkable
                    showLine={{ showLeafIcon: false }}
                    switcherIcon={<em className="ico ico-arrow-down" />}
                    checkedKeys={contactsListChecked.map((o) => o.key)}
                    defaultCheckedKeys={contactsListChecked.map((o) => o.key)}
                    // defaultExpandAll={true}
                    onSelect={onSelect}
                    onCheck={onCheck}
                    treeData={VideoCallStore.contactsList}
                  />
                </div>
              )
            },
            {
              key: '2',
              label: `监控`,
              children: (
                <div className={styles.cont}>
                  <div className={styles.title}>选择参会人员</div>
                  <Tree
                    className={VideoCallStore.css + 'Tree isRight'}
                    checkable
                    showLine={{ showLeafIcon: false }}
                    switcherIcon={<em className="ico ico-arrow-down" />}
                    checkedKeys={equipmentListChecked.map((o) => o.key)}
                    defaultCheckedKeys={equipmentListChecked.map((o) => o.key)}
                    // defaultExpandAll={true}
                    onSelect={onSelect}
                    onCheck={onCheck}
                    treeData={VideoCallStore.equipmentList}
                  />
                </div>
              )
            }
          ]}
          className={VideoCallStore.css + 'Tabs'}
          onChange={onChangeTab}
        />
        <div className={styles.selected}>
          <div className={styles.title}>
            已选择
            <em>
              {
                [...contactsListChecked.filter((o) => !o.disabled), ...equipmentListChecked.filter((o) => !o.disabled)]
                  .length
              }
              人
            </em>
          </div>
          <div className={styles.cont + ' commonScroll'}>
            {[...contactsListChecked.filter((o) => !o.disabled), ...equipmentListChecked.filter((o) => !o.disabled)]
              .length ? (
              <Table
                dataSource={[
                  ...contactsListChecked.filter((o) => !o.disabled),
                  ...equipmentListChecked.filter((o) => !o.disabled)
                ]}
                className={VideoCallStore.css + 'Table isNoHead'}
                size="small"
                scroll={{ y: 390 }}
                pagination={false}
                columns={[
                  {
                    title: '姓名',
                    width: 80,
                    ellipsis: {
                      showTitle: false
                    },
                    dataIndex: 'title',
                    key: 'title',
                    render: (text) => (
                      <Tooltip placement="left" title={text.split(' ')[0]}>
                        {text.split(' ')[0]}
                      </Tooltip>
                    )
                  },
                  {
                    title: '电话',
                    dataIndex: 'mobile',
                    key: 'mobile',
                    render: (text, record) => (
                      <Tooltip placement="left" title={record.mobile || record.callId}>
                        {record.mobile || record.callId}
                      </Tooltip>
                    )
                  },
                  {
                    title: '备注',
                    dataIndex: 'parentName',
                    ellipsis: {
                      showTitle: false
                    },
                    key: 'parentName',
                    render: (text, record) => (
                      <Tooltip
                        placement="left"
                        title={text + (record.jobDescription ? '-' + record.jobDescription : '')}>
                        {text}
                        {record.jobDescription ? '-' + record.jobDescription : ''}
                      </Tooltip>
                    )
                  },
                  {
                    title: '',
                    width: 30,
                    dataIndex: '',
                    key: 'x',
                    render: (text) => (
                      <em
                        className="ico ico-remove"
                        onClick={() => {
                          setContactsListChecked(contactsListChecked.filter((o) => o.key !== text.key && o.mobile));
                          setEquipmentListChecked(equipmentListChecked.filter((o) => o.key !== text.key && o.callId));
                        }}></em>
                    )
                  }
                ]}
              />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default inject('VideoCallStore')(observer(Invite));
