import React from 'react';
import { inject, observer } from 'mobx-react';
import styles from './index.module.less';

const Modal = ({ VideoCallStore }) => {
  return (
    <>
      {VideoCallStore.isModalVisible ? (
        <div className={styles.tipsAlert}>
          <div className={styles.bg}></div>
          <div className={styles.cont}>
            <div className={styles.text}>
              <h5>{VideoCallStore.modal.content}</h5>
              {/* {VideoCallStore.modal.content} */}
            </div>
            <div
              className={styles.btn + ' ' + styles.btnCancel}
              onClick={() => {
                VideoCallStore.hideModal();
              }}>
              {VideoCallStore.modal.cancelText || '取消'}
            </div>
            <div
              className={styles.btn + ' ' + styles.btnConfirm}
              onClick={() => {
                VideoCallStore.modal?.onConfirm();
                VideoCallStore.hideModal();
              }}>
              {VideoCallStore.modal.confirmText || '确定'}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default inject('VideoCallStore')(observer(Modal));
