import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { message } from 'antd';
import YunliRTC from '../../../rtc';
import styles from './index.module.less';
import { toJS } from 'mobx';

const Incoming = ({ VideoCallStore }) => {
  useEffect(() => {
    setInterval(() => {
      if (VideoCallStore.incomingList.length) {
        VideoCallStore.isIncominngAnimate = !toJS(VideoCallStore.isIncominngAnimate);
      }
    }, 1000);
  }, []);

  return (
    <>
      {VideoCallStore.incomingList.map((o, i) => (
        <div
          key={o}
          className={styles.callModal + ' isMinHide'}
          style={{ display: o.visible ? '' : 'none' }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}>
          <div className={styles.callModalMask}>
            <div className={styles.callContainer}>
              <div className={styles.defaultCallPhone}>
                <span>{o.phoneNum}</span>
                <em>{o.nickname}</em>
              </div>
              <div className={styles.callStatus}>
                <div
                  className={styles.callingContent}
                  style={{
                    display: VideoCallStore.status === 'accepted' && VideoCallStore.type === 'video' ? 'none' : 'flex'
                  }}>
                  {/* <canvas id="yunli-rtc-call-status" /> */}
                  <span>{VideoCallStore.status === 'progress' ? '正在呼叫…' : ''}</span>
                </div>
                <div
                  className={styles.onlineContent}
                  style={{
                    display: VideoCallStore.status === 'accepted' && VideoCallStore.type === 'video' ? 'flex' : 'none'
                  }}>
                  <div className={styles.callVideoPlayer}></div>
                </div>
              </div>
              <div className={styles.callTime}></div>
              <div className={styles.callFunBox}>
                <div
                  className={styles.callHangup}
                  onClick={() => {
                    // 挂断
                    VideoCallStore.incomingHangup(i);
                  }}>
                  <i className="ico ico-call-hangup" />
                </div>
                <div
                  className={styles.callAnswer}
                  onClick={() => {
                    // 接听
                    if (VideoCallStore.status !== 'default') {
                      message.error((VideoCallStore.status === 'accepted' ? '通话' : '拨号') + '中，无法接听');
                      return;
                      // 挂断现在通话
                      // YunliRTC.hangup({ id: VideoCallStore.id });
                    }

                    VideoCallStore.status = 'accepted';
                    o.visible = false;
                    setTimeout(() => {
                      VideoCallStore.incomingRemove(i);
                      VideoCallStore.stopIncoming();
                    }, 500);

                    VideoCallStore.isCallVisible = true;
                    VideoCallStore.showCallTime();

                    VideoCallStore.type = o.type;
                    VideoCallStore.callPhoneNum = o.phoneNum;
                    VideoCallStore.callNickname = o.nickname;

                    if (o.type === 'conf') {
                      if (VideoCallStore.sdk === 'ly') {
                        // 凌壹会议
                        VideoCallStore.confInit();
                        setTimeout(() => {
                          YunliRTC.callConf(VideoCallStore.confInfo.THIRDCONFID, {
                            confId: VideoCallStore.confInfo.CONFID,
                            video: true,
                            media: VideoCallStore.remoteAudio()
                          });
                        }, 1000);
                      } else {
                        // 航美会议
                        VideoCallStore.isConfVisible = true;
                        VideoCallStore.confInit();

                        setTimeout(() => {
                          YunliRTC.acceptConf({
                            media: null
                          });
                          VideoCallStore.isConfLoading = false;
                          VideoCallStore.isConfIncoming = true;
                          VideoCallStore.isConfMemberVisible = true;
                          setTimeout(() => {
                            VideoCallStore.showLocalVideo(VideoCallStore.confVideo());
                          }, 3000);
                        }, 200);
                        // VideoCallStore.type = 'video';
                      }
                    } else if (o.type === 'video') {
                      setTimeout(() => {
                        YunliRTC.answer({
                          id: o.id,
                          media: VideoCallStore.remoteVideo(),
                          video: true,
                          callback: (res) => {
                            VideoCallStore.id = res.id;
                          }
                        });
                        VideoCallStore.showLocalVideo();
                      }, 100);
                      // YunliRTC.hangup();
                      // VideoCallStore.isConfVisible = true;
                      // YunliRTC.createConf([o.phoneNum], {
                      //   name: moment().format('YYYY-MM-DD HH:mm:ss'),
                      //   video: true,
                      //   media: VideoCallStore.remoteVideo(),
                      //   callback: (res) => {
                      //     // setTimeout(() => {
                      //     //   VideoCallStore.getMembers();
                      //     // }, 3000);
                      //   }
                      // });
                    } else {
                      setTimeout(() => {
                        YunliRTC.answer({
                          id: o.id,
                          media: VideoCallStore.remoteVideo(),
                          video: false,
                          callback: (res) => {
                            VideoCallStore.id = res.id;
                          }
                        });
                      }, 100);
                    }
                  }}>
                  <i className={'ico ico-call-' + o.type + ' ' + (o.type !== 'audio' ? styles.isVideo : '')} />
                </div>
              </div>
              <div className={styles.callLeftBtn}>
                {VideoCallStore.status === 'accepted' ? VideoCallStore.callLeftBtn : ''}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default inject('VideoCallStore')(observer(Incoming));
