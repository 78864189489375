// import { ua, getSession, onIncomingStream } from './regist-ly';
import { getConfig } from '../config';
import { post } from '../fetch';
import Stomp from 'stompjs';

const config = getConfig();

/**
 * 发送消息
 * @param {Number} number 号码
 * @param {Boolean} video 是否视频通话
 */
export async function sendMessage(id, { content = '', callback }) {
  let send = await post(
    config.apiSDK + '/rstoneCmd/Service/SendMessage',
    {
      TOKEN: config.info.TOKEN, // login.data.token
      PoliceNo: config.userName, // 发送人ID
      Type: 0, // 单聊0，群聊1
      Sender: config.userName,
      Receiver: id,
      MsgType: '0', //mq推送类别-文字0，图片1，语音2，视频3，文件40
      Content: content, //MsgType为0时为必填项
      AUDIO_TIME: '', //MsgType为2时为必填项
      FILE_OLD_NAME: '', //MsgType为40时为必填项
      FILE_SIZE: '', //MsgType为3，40时为必填项
      URL: '' //MsgType为1，2，3，40时为必填项
    },
    { retry: 1 }
  );
  if (callback) {
    callback(send);
  }
  return send;
}
/**
 * 历史消息
 * @param {Number} number 号码
 * @param {Boolean} video 是否视频通话
 */
export async function getMessages(id, { time = '', callback }) {
  let messages = await post(
    config.apiSDK + '/rstoneCmd/Service/GetChatMsgHis',
    {
      TOKEN: config.info.TOKEN, // login.data.token
      Group_ID: id, //群组编号
      BeginTime: time, //消息时间
      Top_N: 10, //消息条数
      TYPE: '0', //mq推送类别-文字0，图片1，语音2，视频3，文件40
      PoliceNo: config.userName
    },
    { retry: 1 }
  );
  if (callback) {
    callback(messages);
  }
  return messages;
}
/**
 * 新消息
 * @param {Number} number 号码
 * @param {Boolean} video 是否视频通话
 */
export async function getMessage({ callback }) {
  const { ip, port, info } = config;
  const userName = info.USERNAME,
    password = info.PWS,
    host = info.VHOST;
  if (config.msgWS && config.msgWS.connected) {
    config.msgWS?.disconnect();
  }
  config.msgWS = Stomp.over(new WebSocket(`wss://${ip}:${port}/ws`));
  config.msgWS.debug = () => {};
  // 同一个账号可以连接多个，但是只能有一个可以接收到消息，使用时需要关闭其他连接
  config.msgWS.connect(
    `${userName}`,
    `${password}`,
    () => {
      console.log('message client success');
      [
        // `/exchange/110108/FACE.110108.#`,
        // `/exchange/110108/ALARM.110108.#`,
        // `/exchange/110108/LOGIN_OUT.*`,
        `/exchange/${config.info.EXCHANGENAME}/POLICE.#.${config.userName}.#`
        // `/exchange/110108/PC.110108.#`,
        // `/exchange/110108/UNIT.#.110108`,
        // `/exchange/IMExchange/IM.UNIT_110108`,
        // `/exchange/IMExchange/INTERCOM.88784`
        // `/exchange/110108/LOCATE.110108.#`
      ].forEach((topic) => {
        config.msgWS.subscribe(
          topic,
          (e) => {
            if (e.command === 'MESSAGE') {
              const data = JSON.parse(e.body);
              console.log(data, 'message client');
              if (callback) {
                callback(data);
                // callback({
                //   content: data.CONTENT,
                //   userName: data.SENDERNAME,
                //   time: data.SEND_TIME
                // });
              }
            }
          },
          {
            'x-queue-name': config.userName, //队列消息
            durable: false, //必填项-false
            exclusive: false, //必填项-false
            autoDelete: false //必填项-false
          }
        );
      });
    },
    () => {
      // console.log(e);
    },
    `${host}`
  );
  // let messages = await post('/rstoneCmd/Service/GetChatMsgHis', {
  //   TOKEN: '78b6c4ea355b4285b6a29a93261866a4', // login.data.token
  //   Group_ID: id, //群组编号
  //   BeginTime: time, //消息时间
  //   Top_N: 10, //消息条数
  //   TYPE: '0', //mq推送类别-文字0，图片1，语音2，视频3，文件40
  //   PoliceNo: config.userName
  // });
  // if (callback) {
  //   callback(messages);
  // }
  // return messages;
}
