// import { ua, getSession, onIncomingStream } from './regist-ly';
import { getConfig } from '../config';
import { get, postForm } from '../fetch';
import { call } from './call';

const config = getConfig();

/**
 * 开始对讲
 * @param {Number} id 对讲组号码
 * @param {HTMLAudioElement} media 对讲播放Audio
 * @param {Function} callback 回调函数
 */
export function startTalk(id, { media, callback }) {
  const config = getConfig();
  // console.log(config, 'config...');
  // config.registWS.send(
  //   JSON.stringify({
  //     MSG: '获取到话权',
  //     STATUS: 2,
  //     intercomId: id,
  //     MSG_KEY: 'intercomUserStatus',
  //     user: config.userName
  //   })
  // );
  // config.msgWS.subscribe(topic, 'test', {
  //   'x-queue-name': 账号, //队列消息
  //   durable: false, //必填项-false
  //   exclusive: false, //必填项-false
  //   'auto-delete': true //必填项-true
  // });

  call(id, {
    video: false,
    media: media,
    callback: () => {
      setTimeout(async () => {
        // 开始对讲
        let send = await get(config.apiSDK + '/manager/intercom/intercomInfo!requestTalk.action', {
          intercomId: id,
          userId: config.userName
        });
        if (callback) {
          callback(send);
        }
      }, 500);
    }
  });
}
/**
 * 结束对讲
 * @param {Number} id 对讲组号码
 * @param {Function} callback 回调函数
 */
export async function endTalk(id, { callback }) {
  const config = getConfig();

  // let send = await get(config.apiSDK + '/manager/intercom/intercomInfo!relaceTalk.action', {
  //   intercomId: id,
  //   userId: config.userName
  // });
  let send = await get(config.apiSDK + '/manager/call/call!conference_interactive.action', {
    cmd: 'kick',
    roomid: id,
    data: 'all'
  });
  if (callback) {
    callback(send);
  }
  return send;
}
/**
 * 对讲列表
 * @param {Function} callback 回调函数
 */
export async function getTalks({ callback }) {
  const config = getConfig();
  // console.log(config, 'config...');
  let talks = await get(config.apiSDK + '/manager/intercom/intercomInfo!getIntercomListByUId.action', {
    userId: config.userName
  });
  if (callback) {
    callback(talks);
  }
  return talks;
}
/**
 * 创建对讲组
 * @param {Array} ids 对讲组成员id
 * @param {String} name 对讲组名称
 * @param {String} company 对讲组单位id
 * @param {Function} callback 回调函数
 */
export async function createTalk(ids = [], { name = '', company = '110108', callback }) {
  let create = await postForm(config.apiSDK + '/manager/intercom/intercomInfo!onSaveOrUpdate.action', {
    data: JSON.stringify({
      id: '',
      intercomId: '',
      intercomName: name,
      xzssdw: company
    })
  });
  let send = await postForm(config.apiSDK + '/manager/intercom/intercomInfo!saveYonghu.action', {
    data: JSON.stringify({
      id: create.id,
      yhIds: ids.join(',')
    })
  });
  if (callback) {
    callback(send);
  }
  return send;
}
/**
 * 删除对讲组
 * @param {Number} id 对讲组id
 * @param {Function} callback 回调函数
 */
export async function removeTalk(id, { callback }) {
  let send = await postForm(config.apiSDK + '/manager/intercom/intercomInfo!deleteById.action', {
    data: JSON.stringify({
      id: id
    })
  });
  if (callback) {
    callback(send);
  }
  return send;
}
