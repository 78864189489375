// 注册SIP - 凌壹
import { LOCALINFOKEY } from '../const';
import JsSIP from 'jssip';
import { getConfig, setConfig } from '../config';
import { get, post } from '../fetch';
import md5 from 'js-md5';

JsSIP.C.SESSION_EXPIRES = 120;
JsSIP.C.MIN_SESSION_EXPIRES = 120;

const config = getConfig();

let ua; // UA实例

let callbacks = []; // 会话状态变更回调

let sessionList = []; // 会话实例列表

let cause = {
  // 错误原因
  // 'Connection Error': 'Connection Error',
  // 'Request Timeout': 'Request Timeout',
  // 'SIP Failure Code': 'SIP Failure Code',
  'Internal Error': '内部错误',
  // Busy: 'Busy',
  Rejected: '对方已挂断',
  // Redirected: 'Redirected',
  Unavailable: '对方不在线',
  'Not Found': '您拨打的号码是空号，请查证后再拨。',
  // 'Address Incomplete': 'Address Incomplete',
  // 'Incompatible SDP': 'Incompatible SDP',
  // 'Missing SDP': 'Missing SDP',
  // 'Authentication Error': 'Authentication Error',
  Terminated: '已挂断',
  // 'WebRTC Error': 'WebRTC Error',
  Canceled: '已取消',
  'No Answer': '对方未接听',
  // Expires: 'Expires',
  // 'No ACK': 'No ACK',
  // 'Dialog Error': 'Dialog Error',
  // 'User Denied Media Access': 'User Denied Media Access',
  // 'Bad Media Description': 'Bad Media Description',
  // 'Add Xylink Meeting Failure': 'Add Xylink Meeting Failure',
  'RTP Timeout': 'RTP Timeout'
};

/**
 * 注册拨出、来电事件监听
 */
function listhenNewTRCSessionEvent() {
  const getType = (body) => {
    // const lines = body.split('\n');
    // let type = 'audio';
    // lines.forEach((line) => {
    //   const [key, val] = line.split('=');
    //   if (key === 'm') {
    //     type = val.split(' ')[0];
    //   }
    // });
    return (body || '').indexOf('m=video') !== -1 ? 'video' : 'audio';
  };

  ua.on('newRTCSession', (e) => {
    const { eventBus } = config;

    const { session, request } = e;
    const { direction, _id } = session;
    // 管理多会话实例
    if (!sessionList.filter((o) => o._id === _id).length) {
      sessionList.push(session);
    }

    console.log(direction === 'outgoing' ? '打电话' : '来电话啦', e);
    // 拨打返回session id
    if (direction === 'outgoing') {
      eventBus.emit('yunli-rtc-call-session', {
        id: session._id
      });
    }

    const listhener = (data) => {
      const { originator, message, response } = data;
      // 确认类型
      let type;
      if (originator === 'remote') {
        // 呼出，从remote中读取
        type = getType((message || response).body);
      } else {
        // 呼入，从request中读取
        type = getType(request.data);
      }

      data.cause = cause[data.cause] || data.cause;
      let res = {
        id: session._id,
        type,
        direction,
        ...data
      };
      callbacks.forEach((cb) => {
        cb(res);
      });
      console.log(data, e, res, 'new rtc session...');
    };

    // 接听 / 来电 振铃 / 失败 / 挂断
    ['accepted', 'progress', 'failed', 'ended'].forEach((evtName) => {
      session.on(evtName, (data) => {
        let stringData = request.data || data?.response?.data;
        // 获取来电或呼出信息
        if (stringData) {
          stringData.split('\r\n').forEach((o) => {
            o = o.split(':');
            data[o[0].toLocaleLowerCase()] = o.slice(1).join(':');
          });
          if (data.from) {
            data.from = data.from.match(/\d+/g)[0];
          }
          if (data.to) {
            data.to = data.to.match(/\d+/g)[0];
          }
        }
        listhener({ status: evtName, ...data });
      });
    });
  });
}

/**
 * 获取当前通话session实例
 * @returns session
 */
export function getSession(id) {
  return id ? sessionList.filter((o) => o._id === id)[0] : sessionList[sessionList.length - 1];
}

/**
 * 登录
 */
export async function login(options = {}) {
  const { isForce } = options;
  let localLogin = localStorage.getItem(LOCALINFOKEY + '-' + config.userName),
    info;
  if (localLogin && !isForce) {
    // 本地缓存中的登录信息
    info = JSON.parse(localLogin);
    setConfig({
      info: info
    });
    return Promise.resolve(info);
  } else {
    const res = await get(config.apiSDK + '/androidApi/getVoipForDDT.action', {
      userId: config.userName, //string类型，用户id
      userPwd: md5(config.userPsw), //string类型，用户md5加密密码（必须使用md5加密）
      userType: 2, //string类型，用户类型，2为pc用户，1为app用户(70000001 —— 70001000  之间是调度台 userType用2 ,70001000 以上的用户  是用户 userType用1)
      devType: 'WEB', //登录的设备类型  'WEB'代表是 web浏览器端,主要用于区分client
      imei: config.imei //设备登录的唯一标识
    });
    if (res.isSuccess) {
      info = { ...res, ...res.cmd, ...res.ddtinfo };
      delete info.cmd;
      delete info.ddtinfo;
      setConfig({
        info: info
      });
      localStorage.setItem(LOCALINFOKEY + '-' + config.userName, JSON.stringify(info));
      return Promise.resolve(info);
    }
    return Promise.reject(res);
  }
}

/**
 *
 * @param {Object} options
 *  ip
 *  port
 *  businesPort 凌壹无此参数
 *  userName
 *  userPsw
 *  userType 凌壹无此参数
 *  path 信令服务器url path
 * @returns state 1 成功 2 未注册 >=3 失败
 */
export async function regist(options) {
  setConfig(options);
  const { userName, userPsw, ip, port, path } = options;
  // User Agent Configuration
  const socket = new JsSIP.WebSocketInterface(`wss://${ip}:${port}${path}`);
  const configuration = {
    sockets: [socket],
    uri: `sip:${userName}@${ip}:${port}`,
    password: userPsw,
    register: true,
    contact_uri: `sip:${userName}@${ip};transport=ws`
  };
  config.uaWS = socket;

  // 注销已有ua
  if (config.ua) {
    config.ua.unregister();
  }

  // User Agent instance
  ua = new JsSIP.UA(configuration);
  config.ua = ua;

  ua.start();

  const p = new Promise((resolve) => {
    ua.on('registered', async () => {
      // 注册拨出、来电事件监听
      listhenNewTRCSessionEvent();

      // 自动登录
      login();

      /*
        {
          "ddtinfo": {
            "ddtid": 70000032,
            "ddtmm": "",
            "zxbz": 1,
            "ddtmz": "联通云粒",
            "ddyjs": 3,
            "UnitId": 110108
          },
          "sphyPort": 15675,
          "IP": "127.0.0.1",
          "voipPort": 5065,
          "cmd": {
            "LOGIN_TIME": "2022-07-25 19:27:42",
            "UNIT_ID": "110108",
            "MSG_UNIT_ID": "110108",
            "DEV_TYPE": "WEB",
            "LOCATE_QUEUE_NAME": "70000032_LOCATE",
            "LOCATE_QUEUE_ROUTE": "LOCATE.110108.#",
            "POLICE_ID": "70000032",
            "IM_ROUTINGKEY": "IM.broadcast",
            "HOST": "111.229.117.19",
            "POLICE_NAME": "联通云粒",
            "EXCHANGENAME": "110108",
            "IMEXCHANGE_ROUTE": "IM.UNIT_110108",
            "result": "1",
            "STATUS": "1",
            "UNIT_NAME": "市局",
            "GENDER": "",
            "USERNAME": "t01",
            "TOKEN": "1e93ef801f8244a7e26e2896c12a05cc",
            "IMEXCHANGE_NAME": "IMExchange",
            "Msg": "1e93ef801f8244a7e26e2896c12a05cc",
            "IM_EXCHANGENAME": "IMExchange",
            "VHOST": "/",
            "PORT": "15675",
            "VISIBLE_TAB": "",
            "POLICE_POST": "",
            "IMEI": "xxxxxx",
            "ROUTINGKEY": "FACE.110108.#,ALARM.110108.#,LOGIN_OUT.*,POLICE.#.70000032.#,PC.110108.#,UNIT.#.110108",
            "UNIT_SIGN": "4417c1248c83497afd675303e639bb11",
            "CATEGORY_SIGN": "171bea823451ba79a831278b89909a16",
            "PWS": "t01",
            "MD_INFO": "{\"result\":1,\"PORT\":\"5065\",\"USR\":\"70000032\",\"HOST\":\"192.168.60.29\",\"PWS\":\"1234\"}",
            "MOBILE_NO": "",
            "INTERCOM_EXCHANGE_ROUTE": "INTERCOM.88784",
            "POLICE_SIGN": "933334a6f1845ffc5bc3097cb53559dc",
            "CARD_ID": "",
            "PROGRAMME_ID": ""
          },
          "sphyIP": "111.229.117.19",
          "voipIP": "111.229.117.19",
          "sphyQz": "19"
        }
      */

      resolve(1);
    });

    ua.on('unregistered', () => {
      resolve(2);
    });

    ua.on('registrationFailed', () => {
      resolve(3);
    });
  });

  ua.on('sipEvent', function (e) {
    console.log('sipEvent: ', e);
  });

  return p;
}

/**
 * 取消注册
 */
export function unRegist() {
  // 清除会议登录token
  setConfig({
    token: ''
  });

  ua.stop();
  ua.unregister({ all: true });
  // console.log(config.uaWS, config.msgWS, config.confWS);
  if (config.uaWS?._ws?.readyState === 1) {
    config.uaWS?._ws?.close();
  }
  if (config.msgWS?.ws?.readyState === 1) {
    config.msgWS?.ws?.close();
  }
  if (config.confWS?.readyState === 1) {
    config.confWS?.close();
  }
}

/**
 * 是否已注册
 */
export function isRegisted() {
  return !!config.token;
}

/**
 * 注册事件变更监听函数
 * @param {Function} callback
 */
export async function onRegistStateChange(callback) {
  // 1 成功 2 未注册 >=3 失败
  ua.on('registered', () => {
    callback(1);
  });
  ua.on('unregistered', () => {
    callback(2);
  });
  ua.on('registrationFailed', () => {
    callback(3);
  });
}

/**
 * 来电事件监听
 */
export function onIncomingCall(callback) {
  callbacks.push(callback);
}

/**
 * 来电视频流
 */
export async function onIncomingStream() {
  return new Promise((resolve) => {
    let inboundStream = null;
    getSession()?.connection.addEventListener(
      'track',
      (e) => {
        if (e.streams && e.streams[0]) {
          const stream = e.streams[0];
          resolve(stream);
        } else {
          if (!inboundStream) {
            inboundStream = new MediaStream();
            resolve(inboundStream);
          }
          inboundStream.addTrack(e.track);
        }
      },
      false
    );
  });
}

/**
 * 退出登录
 */
export async function logout(options = {}) {
  const { callback } = options;
  const logout = await post('/rstoneCmd/Service/LogOut', {
    PoliceNo: config.userName, //登录用户ID
    Token: config.info.TOKEN, //登录用户 token
    imei: config.imei //设备登录的唯一标识
  });
  if (logout.isSuccess) {
    callback && callback(logout);
    localStorage.setItem(LOCALINFOKEY + '-' + config.userName, '');
  }
  return logout;
}

/**
 * 删除通话session实例
 * @returns session
 */
export function removeSession(id) {
  sessionList.forEach((o, i) => {
    if (o._id === id) {
      sessionList.splice(i, 1);
    }
  });
}
export { ua };
