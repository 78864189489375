import Main from '@/pages/Main';
import Element from '@/pages/Element';
import Demo from '@/pages/Demo';
import UI from '@/pages/UI';

const routers = [
  {
    path: '/',
    exact: true,
    key: 'main',
    component: Main,
    name: 'main'
  },
  {
    path: '/element',
    exact: true,
    key: 'element',
    component: Element,
    name: 'element'
  },
  {
    path: '/demo',
    exact: true,
    key: 'demo',
    component: Demo,
    name: 'demo'
  },
  {
    path: '/ui',
    exact: true,
    key: 'ui',
    component: UI,
    name: 'ui'
  }
];

export { routers };
