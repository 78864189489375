import React, { useState, useEffect } from 'react';
import { Segmented, Button, Select, Modal, Typography, Tooltip, Switch, message } from 'antd';
import { Modal as Modal2, Toast } from 'antd-mobile';
import { CloudTwoTone, DownloadOutlined, YuqueOutlined, CodeOutlined } from '@ant-design/icons';
import Prism from 'prismjs';
import eventBus from '@/plugins/eventBus';

import YunliRTC, { Turnplate } from '@packages/unity-platform-library';
import '../../assets/common.less';
import '../../assets/common.cyan.less';
import '../../assets/common.blue.less';
import '../../assets/common.darkblue.less';
import '../../assets/common.white.less';
// import YunliRTC, { Turnplate } from '@/plugins/YunliRTC';

import style from '../../assets/index.module.less';
import './common.diy.less';

import { ContactsList, EquipmentList, ElementHTML, CSSHTML } from '../../utils/const.js';

// import html2canvas from 'html2canvas';

const { Option } = Select;
const { Link } = Typography;

// YunliRTC.setSDK('hm');
let isRecordWorking = false;
let recordStartTime;

function Element({ history }) {
  const html = Prism.highlight(ElementHTML, Prism.languages.javascript, 'javascript');
  const [isCssDiy, setIsCssDiy] = useState(false);
  const [file, setFile] = useState({});
  const [regist] = useState(
    YunliRTC.getSDK() === 'ly'
      ? {
          // 凌壹
          eventBus: eventBus, // 事件通信，设为项目中公用的eventBus
          ip: 'www.t01md.com.cn',
          port: '15675',
          appId: 'beb7da4ced7c42a085c3c99697f9aa42',
          secretKey: 'ba2468bf083947c5954cf2ccd9f2c9ed',
          userName: '70000025',
          userPsw: '1234',
          userType: '2', // 2为pc用户，1为app用户
          confPsw: '70000025', // 视频会议密码
          path: '/tx',
          apiSDK: '/communication-service-ly'
        }
      : {
          // 航美
          eventBus: eventBus, // 事件通信，设为项目中公用的eventBus
          ip: '42.236.120.104',
          port: '7443',
          businesPort: '60002',
          userNick: '我',
          userName: '2405',
          userPsw: 'lthm%TGB2405?',
          // userName: '2013',
          // userPsw: 'lthm2022-xiao20',
          userType: '2'
        }
  );
  const [appCallId] = useState(YunliRTC.getSDK() === 'ly' ? '70001037' : '2300'); // 70001046

  useEffect(() => {
    // 演示页面用，没有登录态的情况下，会导致拉取不到列表

    // 通讯录
    Turnplate.setContactsList(ContactsList);

    // 监控
    Turnplate.setEquipmentList(EquipmentList);

    // 页面录制
    let superBuffers = []; // 分片文件，合并后再上传
    window.addEventListener('message', (e) => {
      // 上传视频会议视频
      const { name, value, index, total } = e.data;
      if (name === 'yunli-rtc-page-record-success') {
        superBuffers.push(value);
        if (index !== total - 1) {
          return;
        }

        // // base64 会有长度上限的问题，通过分片来解决
        // const file = Turnplate.base64ToBlob({ data: superBuffers });
        // if (file) {
        //   const formData = new FormData();
        //   formData.append('file', file);
        //   console.log(file);
        //   setFile(file);
        //   // if (cid) {
        //   //   // 有cid时，才需要上传视频文件和通话记录（没有参会人或没添加事件时，不需要上报）
        //   //   api.uploadAttachment(formData).then((res) => {
        //   //     if (res && res.code === '200' && res.data.url) {
        //   //       console.log('上传成功', file, res.data.url, total, cid);
        //   //     } else {
        //   //       console.log('上传失败', file);
        //   //     }
        //   //   });
        //   // }
        // }

        Turnplate.blobToFile({
          data: value,
          time: +new Date() - recordStartTime
        }).then((file) => {
          if (file) {
            const formData = new FormData();
            formData.append('file', file);
            console.log(file);
            setFile(file);
            // if (cid) {
            //   // 有cid时，才需要上传视频文件和通话记录（没有参会人或没添加事件时，不需要上报）
            //   api.uploadAttachment(formData).then((res) => {
            //     if (res && res.code === '200' && res.data.url) {
            //       console.log('上传成功', file, res.data.url, cid);
            //       this.saveRecord(
            //         {
            //           videoUrl: res.data.url
            //         },
            //         cid
            //       );
            //     } else {
            //       console.log('上传失败', file);
            //     }
            //   });
            // }
          }
        });

        // 清空分片文件
        superBuffers = [];
      } else if (name === 'yunli-rtc-page-record-working') {
        if (value) {
          isRecordWorking = true;
        }
      }
      return true;
    });
  }, []);

  return (
    <>
      <div className={style.app}>
        <h3>
          <CloudTwoTone style={{ fontSize: 34, position: 'relative', top: '6px' }} /> YunliRTC
        </h3>
        <div className={style.tabs}>
          <Segmented
            block
            defaultValue="组件"
            onChange={(value) => {
              setTimeout(() => {
                let url;
                if (value === '组件') {
                  url = '/element';
                } else if (value === '样式') {
                  url = '/ui';
                } else {
                  url = '/';
                }
                history.push(url);
                document.location.reload();
              }, 400);
            }}
            options={['API', '组件', '样式']}
          />
        </div>
        <div className={style.link}>
          <Tooltip title="npm i yunli-rtc">
            <Link
              onClick={() => {
                Modal.info({
                  title: '下载最新版本',
                  content: <div>npm i yunli-rtc</div>
                });
              }}>
              <DownloadOutlined /> 下载最新版本
            </Link>
          </Tooltip>
          <Tooltip title="示例代码 demo.js">
            <Link href="demo.js" download>
              <CodeOutlined /> 示例代码
            </Link>
          </Tooltip>
          <Tooltip title="YunliRTC音视频通话API和组件">
            <Link href="https://yunlizhihui.yuque.com/bgr0hu/gly4e7/wngvte" rel="noreferrer" target="_blank">
              <YuqueOutlined /> 语雀
            </Link>
          </Tooltip>
        </div>
        <div className={style.code}>
          <pre className="language-css">
            <code className="language-css" dangerouslySetInnerHTML={{ __html: html }}></code>
          </pre>
        </div>
        <Select
          defaultValue={YunliRTC.getSDK()}
          onChange={(value) => {
            YunliRTC.setSDK(value);
            document.location.reload();
          }}>
          <Option value="ly">凌壹</Option>
          <Option value="hm">航美</Option>
        </Select>
        <Button
          onClick={() => {
            // Turnplate.dial();
            Turnplate.dial({
              id: appCallId,
              name: 'xhlv'
            });
          }}>
          拨号
        </Button>
        <div className={style.dropList}>
          <Button>音视频通话</Button>
          <ul className={style.dropListCont}>
            <li>
              通话方式：
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  Turnplate.call({ id: appCallId, name: 'xxAPP1' });
                }}>
                语音通话
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  Turnplate.call({ id: appCallId, name: 'xxAPP1', video: true });
                }}>
                视频通话
              </Button>
            </li>
            <li>
              <li>
                视频通话样式：
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Turnplate.set({ isVideoBtnBottom: false });
                    message.success('切换成功');
                  }}>
                  默认
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Turnplate.set({ isVideoBtnBottom: true });
                    message.success('切换成功');
                  }}>
                  底部按钮
                </Button>
              </li>
            </li>
          </ul>
        </div>
        <Button
          onClick={() => {
            Turnplate.conf([
              // 2300, 2303
              // {
              //   id: 2005,
              //   name: 'xx'
              // },
              {
                id: 2300,
                name: 'xhlv'
              }
            ]);
          }}>
          视频会议
        </Button>
        <div className={style.dropList}>
          <Button>录制</Button>
          <ul className={style.dropListCont}>
            <li>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  postMessage('yunli-rtc-page-record-start');
                  recordStartTime = +new Date();
                  setFile({});
                }}>
                开始
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  postMessage('yunli-rtc-page-record-end');
                }}>
                停止
              </Button>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  postMessage('yunli-rtc-page-record-working');
                  setTimeout(() => {
                    if (isRecordWorking) {
                      message.success('插件正常，支持录制');
                    } else {
                      message.error('不支持录制，请正确安装插件');
                    }
                  }, 100);
                }}>
                检测
              </Button>
              <div style={{ textAlign: 'center', color: '#ccc' }}>{file.name}</div>
              <div>
                {file.name ? (
                  <video
                    autoPlay
                    controls
                    style={{ width: '500px', height: '280px' }}
                    src={URL.createObjectURL(file)}></video>
                ) : (
                  ''
                )}
              </div>
            </li>
          </ul>
        </div>
        {/* <Button
          onClick={() => {
            // Turnplate.conf();
          }}>
          对讲
        </Button> */}
        <Button
          onClick={() => {
            Turnplate.set({
              callMissList: [
                {
                  id: '15810391261',
                  time: '2023-03-31 08:33:38'
                },
                {
                  id: '2300',
                  time: '2023-03-31 08:33:38'
                },
                {
                  id: '18754346660',
                  time: '2023-03-31 08:33:38'
                },
                {
                  id: '18754346660',
                  time: '2023-03-31 08:33:38'
                },
                {
                  id: '18754346660',
                  time: '2023-03-31 08:33:38'
                },
                {
                  id: '18754346660',
                  time: '2023-03-31 08:33:38'
                }
              ]
            });
            Turnplate.set({
              isCallMissVisible: !Turnplate.get('isCallMissVisible')
            });
          }}>
          未接来电
        </Button>
        <div className={style.dropList}>
          <Button>切换账号</Button>
          <ul className={style.dropListCont}>
            <li>
              {YunliRTC.getSDK() === 'ly' ? (
                <>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      Turnplate.regist({
                        id: '70000025',
                        password: '1234'
                      });
                      message.success('切换成功');
                    }}>
                    70000025
                  </Button>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      Turnplate.regist({
                        id: '70000026',
                        password: '1234'
                      });
                      message.success('切换成功');
                    }}>
                    70000026
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      Turnplate.regist({
                        id: '2125',
                        password: 'lthm%2125'
                      });
                      message.success('切换成功');
                    }}>
                    2125
                  </Button>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      Turnplate.regist({
                        id: '2005',
                        password: 'lthm2022-xiao'
                      });
                      message.success('切换成功');
                    }}>
                    2005
                  </Button>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      Turnplate.regist({
                        id: '2006',
                        password: 'lthm2022-xiao'
                      });
                      message.success('切换成功');
                    }}>
                    2006
                  </Button>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      Turnplate.regist({
                        id: '2402',
                        password: 'lthm-2402'
                      });
                      message.success('切换成功');
                    }}>
                    网格员
                  </Button>
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      Turnplate.regist({
                        id: '2400',
                        password: 'lthm-2400'
                      });
                      message.success('切换成功');
                    }}>
                    街道
                  </Button>
                </>
              )}
            </li>
          </ul>
        </div>
        <div className={style.dropList}>
          <Button>样式</Button>
          <ul className={style.dropListCont}>
            <li>
              移动端样式：
              <Tooltip title="移动端（H5）目前仅支持音视频通话、来电显示">
                <Switch
                  onChange={(checked) => {
                    Turnplate.set({
                      isMobile: checked
                    });
                  }}
                />
              </Tooltip>
            </li>
            <li>
              整体主题样式：
              <Tooltip title="Cyan">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Turnplate.set({ theme: 'Cyan' });
                    message.success('切换成功');
                  }}>
                  青色
                </Button>
              </Tooltip>
              <Tooltip title="Blue">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Turnplate.set({ theme: 'Blue' });
                    message.success('切换成功');
                  }}>
                  蓝色
                </Button>
              </Tooltip>
              <Tooltip title="DarkBlue">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Turnplate.set({ theme: 'DarkBlue' });
                    message.success('切换成功');
                  }}>
                  深蓝色
                </Button>
              </Tooltip>
              <Tooltip title="White">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Turnplate.set({ theme: 'White' });
                    message.success('切换成功');
                  }}>
                  白色
                </Button>
              </Tooltip>
            </li>
            <li>
              自定义样式：
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  // 参考Turnplate/common.module.less新建less文件，将css样式名中的common前缀改成value中的css值'commonDiy'，在项目中手动加载
                  Turnplate.set({ theme: isCssDiy ? 'Blue' : 'Diy' });
                  if (!isCssDiy) {
                    Modal.info({
                      title: '自定义样式',
                      content: (
                        <div>
                          方式一：
                          <br />
                          参考 yunli-rtc/dist/css/common.cyan.less
                          新建less文件，将顶部的commonThemeCyan改为commonThemeDiy，然后在项目中手动加载：
                          <div style={{ color: '#666', marginBottom: '10px' }}>
                            <div>import &apos;./common.diy.less&apos;;</div>
                          </div>
                          配置
                          <br />
                          <div style={{ color: '#666' }}>
                            <div>{"<Turnplate options={{ theme: 'Diy' }} />"}</div>或
                            <div>{"Turnplate.set('{ theme: 'Diy' });"}</div>
                          </div>
                          <br />
                          <br />
                          方式二：
                          <br />
                          设置CSS全局变量
                          <br />
                          <pre style={{ color: '#666', height: '100px', overflow: 'auto' }}>{CSSHTML}</pre>
                        </div>
                      )
                    });
                  }
                  setIsCssDiy(!isCssDiy);
                }}>
                {isCssDiy ? '关闭' : '打开'}自定义样式
              </Button>
            </li>
          </ul>
        </div>
        <div>
          <div className={style.dropList}>
            <Button>直播</Button>
            <ul className={style.dropListCont}>
              <li>
                单个直播：
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Turnplate.play({
                      id: YunliRTC.getSDK() === 'ly' ? '8880004' : '2300',
                      name: 'test直播（监控拨号）'
                    });
                  }}>
                  监控拨号
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Turnplate.play({ id: appCallId, name: 'xx（App拨号）' });
                  }}>
                  App拨号
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Turnplate.getVideoUrl({
                      gbId: '11010000001310000165'
                    }).then((res) => {
                      const url = res.data?.wsFlv;
                      if (url) {
                        Turnplate.play({
                          id: url,
                          name: '视频流URL播放'
                        });
                      }
                    });
                  }}>
                  视频流URL
                </Button>
              </li>
              <li>
                多个直播：
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Turnplate.live();
                  }}>
                  拨号方式
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    // 需要从视频联动的接口中获取，再设置
                    Turnplate.setEquipmentList([
                      {
                        title: '监控',
                        children: [
                          {
                            suid: '8880007',
                            contactsId: '42010000001320000007',
                            contactsName: '1F-机房',
                            callId: '8880007',
                            parentName: '8880007',
                            type: '1',
                            jobDescription: '',
                            contactsLabelId: '42010000001320000007',
                            contactsLabelName: '1F-机房',
                            remark: '',
                            title: '1F-机房',
                            key: '0-0-0',
                            id: '42010000001320000007',
                            name: '1F-机房'
                          },
                          {
                            suid: '8880010',
                            contactsId: '42010000001320000004',
                            contactsName: '1F-休息区',
                            callId: '8880010',
                            parentName: '8880010',
                            type: '1',
                            jobDescription: '',
                            contactsLabelId: '42010000001320000004',
                            contactsLabelName: '1F-休息区',
                            remark: '',
                            title: '1F-休息区',
                            key: '0-0-1',
                            id: '42010000001320000004',
                            name: '1F-休息区'
                          },
                          {
                            suid: '8880009',
                            contactsId: '11010000001310000165',
                            contactsName: 'xx窗外',
                            callId: '8880009',
                            parentName: '8880009',
                            type: '1',
                            jobDescription: '',
                            contactsLabelId: '11010000001310000165',
                            contactsLabelName: 'xx窗外',
                            remark: '',
                            title: 'xx窗外',
                            key: '0-0-2',
                            id: '11010000001310000165',
                            name: 'xx窗外'
                          }
                        ],
                        key: '0-0'
                      }
                    ]);
                    Turnplate.live({
                      // 默认选中，可以不设置
                      // checked: [
                      //   { id: '8880011', name: 'xx窗外' },
                      //   { id: '8880004', name: '1F-休息区' }
                      // ],
                      onCancel: () => {
                        console.log('on cancel');
                      },
                      // 获取播放URL
                      url: (id) => {
                        return Turnplate.getVideoUrl({
                          gbId: id
                        }).then((res) => {
                          return res.data?.wsFlv;
                        });
                      }
                    });
                  }}>
                  视频流URL
                </Button>
              </li>
            </ul>
          </div>
          <div className={style.dropList}>
            <Button>音视频播放</Button>
            <ul className={style.dropListCont}>
              <li>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Turnplate.play({
                      id: '/test.mp4',
                      name: 'test视频播放'
                    });
                  }}>
                  视频播放
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Turnplate.play({
                      id: '/test.mp4',
                      name: 'test音频播放',
                      type: 'audio'
                    });
                  }}>
                  音频播放
                </Button>
              </li>
            </ul>
          </div>
          <Button
            onClick={() => {
              Turnplate.set({
                isTurnplateVisible: !Turnplate.get('isTurnplateVisible')
              });
            }}>
            转盘
          </Button>
          <div className={style.dropList}>
            <Button>邀请</Button>
            <ul className={style.dropListCont}>
              <li>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    Turnplate.invite({
                      // title: '自定义标题', // 自定义标题
                      // okText: '确定', // 自定义确认按钮
                      // callback: (res) => {
                      //   // 自定义确认回调
                      //   console.log(res);
                      //   message.info('自定义邀请事件');
                      // }
                    });
                  }}>
                  打开邀请
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    // 通讯录
                    Turnplate.setContactsList([
                      {
                        contactsLabelId: '10000100',
                        contactsLabelName: '政府部门',
                        level: '1',
                        parentLabelId: '',
                        isJointServiceUnit: '1',
                        remark: '备注',
                        count: 13,
                        children: [
                          {
                            contactsLabelId: '10000105',
                            contactsLabelName: '应急局',
                            level: '2',
                            parentLabelId: '10000100',
                            isJointServiceUnit: '1',
                            remark: '备注',
                            count: 2,
                            contactsChildren: [
                              {
                                contactsId: '1000000011',
                                contactsName: 'xx',
                                mobile: '2300',
                                type: '1',
                                jobDescription: '',
                                contactsLabelId: '10000105',
                                contactsLabelName: 'xx',
                                remark: '备注',
                                key: '0-0-1-0-0',
                                id: '1000000011',
                                name: 'xx'
                              },
                              {
                                contactsId: '1000000012',
                                contactsName: 'xx',
                                mobile: '2300',
                                type: '1',
                                jobDescription: '',
                                contactsLabelId: '10000105',
                                contactsLabelName: '应急局',
                                remark: '备注',
                                key: '0-0-1-0-1',
                                id: '1000000012',
                                name: 'xx'
                              }
                            ],
                            parentName: '政府部门',
                            title: '应急局',
                            children: [
                              {
                                contactsId: '1000000012',
                                contactsName: 'xx',
                                mobile: '2300',
                                type: '1',
                                jobDescription: '',
                                contactsLabelId: '10000105',
                                contactsLabelName: '应急局',
                                remark: '备注',
                                key: '0-0-1-0-0',
                                id: '1000000012',
                                name: 'xx'
                              }
                            ],
                            key: '0-0-1-0'
                          },
                          {
                            contactsLabelId: '10000106',
                            contactsLabelName: '水利局',
                            level: '2',
                            parentLabelId: '10000100',
                            isJointServiceUnit: '1',
                            remark: '备注',
                            count: 1,
                            contactsChildren: [
                              {
                                contactsId: '1000000013',
                                contactsName: 'xx',
                                mobile: '8880011',
                                type: '1',
                                jobDescription: '',
                                contactsLabelId: '10000106',
                                contactsLabelName: '水利局',
                                remark: '备注',
                                key: '0-0-1-1-0',
                                id: '1000000013',
                                name: 'xx'
                              }
                            ],
                            parentName: '政府部门',
                            title: '水利局',
                            children: [
                              {
                                contactsId: '1000000013',
                                contactsName: 'xx',
                                mobile: '8880011',
                                type: '1',
                                jobDescription: '',
                                contactsLabelId: '10000106',
                                contactsLabelName: '水利局',
                                remark: '备注',
                                key: '0-0-1-1-0',
                                id: '1000000013',
                                name: 'xx'
                              }
                            ],
                            key: '0-0-1-1'
                          }
                        ],
                        title: '政府部门',
                        key: '0-0-1'
                      }
                    ]);

                    // 监控
                    Turnplate.setEquipmentList([
                      {
                        title: '监控',
                        children: [
                          {
                            suid: '8880007',
                            contactsId: '42010000001320000007',
                            contactsName: '1F-机房',
                            callId: '8880007',
                            parentName: '8880007',
                            type: '1',
                            jobDescription: '',
                            contactsLabelId: '42010000001320000007',
                            contactsLabelName: '1F-机房',
                            remark: '',
                            title: '1F-机房',
                            key: '0-0-0',
                            id: '42010000001320000007',
                            name: '1F-机房'
                          },
                          {
                            suid: '8880010',
                            contactsId: '42010000001320000004',
                            contactsName: '1F-休息区',
                            callId: '8880010',
                            parentName: '8880010',
                            type: '1',
                            jobDescription: '',
                            contactsLabelId: '42010000001320000004',
                            contactsLabelName: '1F-休息区',
                            remark: '',
                            title: '1F-休息区',
                            key: '0-0-1',
                            id: '42010000001320000004',
                            name: '1F-休息区'
                          },
                          {
                            suid: '8880009',
                            contactsId: '11010000001310000165',
                            contactsName: 'xx窗外',
                            callId: '8880009',
                            parentName: '8880009',
                            type: '1',
                            jobDescription: '',
                            contactsLabelId: '11010000001310000165',
                            contactsLabelName: 'xx窗外',
                            remark: '',
                            title: 'xx窗外',
                            key: '0-0-2',
                            id: '11010000001310000165',
                            name: 'xx窗外'
                          }
                        ],
                        key: '0-0'
                      }
                    ]);

                    Turnplate.invite();
                  }}>
                  自定义通讯录和监控列表
                </Button>
              </li>
            </ul>
          </div>
          <Button
            onClick={() => {
              Turnplate.contacts();
            }}>
            通讯录
          </Button>
          <Button
            onClick={() => {
              Turnplate.control([
                // {
                //   id: appCallId,
                //   name: 'xx'
                // }
              ]);
            }}>
            调度人员
          </Button>
        </div>
        <Turnplate
          regist={regist}
          options={{
            theme: 'DarkBlue',
            // getContactsListUrl: '', // 自定义通讯录列表地址，为空可以设置不拉取
            // getEquipmentListUrl: '', // 自定义设备列表地址，为空可以设置不拉取
            // getLocationUrl: '', // 自定义获取通讯录地理位置地址，为空可以设置不拉取
            callLeftBtn: (
              <Button
                type="primary"
                className="commonBtn ant-btn-primary2"
                onClick={() => {
                  // EventReportStore.setVisible(true);
                  console.log('生成事件...');
                }}>
                生成事件
              </Button>
            ),
            confLeftBtn: (
              <Button
                type="primary"
                className="commonBtn ant-btn-primary2"
                onClick={() => {
                  // EventReportStore.setVisible(true);
                  console.log('生成事件...');
                }}>
                生成事件
              </Button>
            ),
            // confTopBtn: (
            //   <div className={style.confTopBtn}>
            //     <i className="ico ico-record-video"></i>录屏已开启
            //   </div>
            // ),
            // confCenterBtn: <TurnplateConfCenterBtn />,
            // confInvite: () => {
            //   // 自定义会议邀请
            //   commonStore.setShowAddPersonModal(true);
            // },
            // dialVideo: (num) => {
            //   // 自定义拨号盘拨打视频
            //   Turnplate.conf([num]);
            // },
            // isMobile: true, // 是否为移动端
            showToast: (options) => {
              // 移动端自定义提示
              Toast.show({
                content: options.content
              });
            },
            showModal: (options) => {
              // 移动端自定义弹窗
              const { content, onConfirm } = options;
              Modal2.confirm({
                content: content
              }).then((res) => {
                if (res) {
                  onConfirm();
                }
              });
            },
            isVideoBtnBottom: false, // 是否视频通话按钮在底部的样式（适用于自定义按钮较多的场景）
            isPlayLocalAudio: true, // 是否播放本地音频，用来录屏采集音频（默认false）
            // confTitle: '视频通话', // 视频会议标题
            // endTips: '', // 结束提示
            // isIncomingAllAudio: true, // 视频来电都用语音接听
            // isMuteHidden: true, // 语音通话不显示静音
            // isMemberBtnHidden: true, // 隐藏成员按钮
            // isCallMissVisible: true, // 未接来电
            // callMissModalContainer: () => document.querySelector('#main_page'), // 未接来电容器设置
            // callMissModalOffset: {
            //   // 未接来电容器坐标
            //   x: 433,
            //   y: 64
            // },
            isTurnplateVisible: false // 是否显示转盘，默认为true，可以手动关闭
          }}>
          <div
            onClick={() => {
              Turnplate.dial();
            }}>
            <em className="ico ico-turnplate1"></em>
            拨号
          </div>
          <div
            onClick={() => {
              Turnplate.invite();
            }}>
            <em className="ico ico-turnplate2"></em>
            视频会议
          </div>
          <div onClick={() => {}}>
            <em className="ico ico-turnplate3"></em>
            知识库
          </div>
          <div
            onClick={() => {
              Turnplate.control();
            }}>
            <em className="ico ico-turnplate4"></em>
            调度人员
          </div>
          <div onClick={() => {}}>
            <em className="ico ico-turnplate5"></em>
            档案
          </div>
          <div
            onClick={() => {
              Turnplate.contacts();
            }}>
            <em className="ico ico-turnplate6"></em>
            通讯录
          </div>
        </Turnplate>
      </div>
    </>
  );
}

export default Element;
