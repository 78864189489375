import { getConfig, setConfig } from '../config';
import { get, postForm } from '../fetch';
// import { ua } from './regist-ly';
import { call } from './call';
// import { get } from 'lodash';
import md5 from 'js-md5';

const config = getConfig();

const delay = {};

const conf = {
  init: () => {
    const { ip, port, eventBus } = config;

    if (!config.confWS) {
      config.confWS = new WebSocket(`wss://${ip}:${port}/ctrlws`);
      config.confWS.onmessage = (e) => {
        const data = JSON.parse(e.data);
        if (data.op === 'rspHeartbeat') {
          // 心跳
          setConfig({
            confClientId: data.clientId
          });
        } else if (data.op === 'rspLogin') {
          // 登录
          setConfig({
            confToken: data.token
          });
          conf.control(); // 控制会议
          conf.list(); // 自动拉取成员
        } else if (data.op === 'rspTerLeaveConf') {
          // 离开会议 indDelParticipates
        } else if (data.op === 'rspTerJoinConf') {
          // 加入会议 indAddParticipates
        } else if (data.op === 'rspGetConfTerList') {
          // 用户列表
          setConfig({
            confList: data.speaklist || []
          });
          /*
              [
                {
                  "clienttype": 5,
                  "orderno": 0,
                  "suid": 102017,
                  "starttime": "",
                  "type": 1,
                  "isonline": 1,
                  "cameras": [
                    {
                      "srcid": 0,
                      "name": "vd",
                      "iscameraon": false,
                      "priority": 0
                    }
                  ],
                  "devicecode": "",
                  "nickname": "云粒",
                  "capbilities": {
                    "hascallwaiting": false
                  },
                  "id": 102017,
                  "terminalip": "",
                  "ismuted": 0,
                  "bandwidth": 0,
                  "endtime": "",
                  "subtimespan": [],
                  "shortname": "",
                  "terid": 2,
                  "deptname": "",
                  "presetinfosList": [],
                  "protocoltype": 0,
                  "termtype": 277,
                  "fromtype": 0,
                  "specialtype": 0,
                  "ishandup": false,
                  "terminaltype": 0
                },
                {
                  "clienttype": 0,
                  "orderno": 0,
                  "suid": 102116,
                  "starttime": "",
                  "type": 3,
                  "isonline": 1,
                  "cameras": [
                    {
                      "srcid": 0,
                      "name": "video device",
                      "iscameraon": true,
                      "priority": 0
                    }
                  ],
                  "devicecode": "",
                  "nickname": "70001048",
                  "capbilities": {
                    "hascallwaiting": false
                  },
                  "id": 102116,
                  "terminalip": "1970001048",
                  "ismuted": 0,
                  "bandwidth": 2048,
                  "endtime": "",
                  "subtimespan": [],
                  "shortname": "5",
                  "terid": 3,
                  "deptname": "",
                  "presetinfosList": [],
                  "protocoltype": 1,
                  "termtype": 256,
                  "fromtype": 1,
                  "specialtype": 0,
                  "ishandup": false,
                  "terminaltype": 5
                }
              ]
              */
        } else if (data.op === 'indGatewayLayoutInfo') {
          // 会议信息
          setConfig({
            confInfo: data.layoutinfo[0] || {}
          });
          /*
              [
                {
                  "screenid": 1025,
                  "panelist": [
                    {
                      "optype": 3,
                      "polltime": 0,
                      "srcid": 0,
                      "tername": "云粒",
                      "suid": 102017,
                      "autopolllist": [
                        {
                          "apid": 0
                        }
                      ],
                      "paneindex": 0,
                      "terid": 2,
                      "channelid": 2,
                      "contenttype": 0,
                      "deptname": ""
                    },
                    {
                      "optype": 3,
                      "polltime": 0,
                      "srcid": 0,
                      "tername": "70001048",
                      "suid": 102116,
                      "autopolllist": [
                        {
                          "apid": 0
                        }
                      ],
                      "paneindex": 1,
                      "terid": 3,
                      "channelid": 3,
                      "contenttype": 0,
                      "deptname": ""
                    }
                  ],
                  "chairfollow": 1,
                  "layoutmode": 0,
                  "screentype": 1,
                  "channelid": 1,
                  "layouttype": 1
                }
              ]
              */
        }
        eventBus.emit(data.op, data);
        eventBus.emit('confChange', data);
      };
      config.confWS.onclose = () => {
        config.confWS = null;
        clearInterval(delay.heartbeat);
      };
      config.confWS.onopen = () => {
        // 心跳
        let heartbeat = () => {
          conf.heartbeat();
          // setTimeout(() => {
          //   config.confWS.close(); // 如果  5秒之后我们没有收到 后台返回的心跳检测数据 断开socket，断开后会启动重连机制
          // }, 5000);
        };
        clearInterval(delay.heartbeat);
        delay.heartbeat = setInterval(heartbeat, 10000);
        heartbeat();

        // 登录
        setTimeout(() => {
          conf.login();
        }, 1000);
      };
    } else {
      conf.control(); // 控制会议
      conf.list(); // 自动拉取成员
    }
  },
  initStyle: function () {
    let el = document.createElement('style');
    let css = `header,.MuiDialogTitle-root{zoom:0.8;}
      .MuiDrawer-paper>div{background:rgba(9, 60, 98, 1);}.MuiTab-textColorPrimary{color:#fff;}.MuiDrawer-paper>div>div>ul{background:rgba(15, 94, 153, 1);color:#fff;}.MuiAppBar-colorDefault{background:rgba(21, 129, 208, 1);;}
      .MuiAppBar-colorPrimary{background:linear-gradient(to bottom, rgba(0, 63, 128, 0.9) 0%,rgba(13, 112, 184, 0.95) 100%);}
      .MuiToolbar-gutters{padding-right:0;}
      .MuiListItem-gutters:first-child,header .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary,.MuiSnackbarContent-root,.MuiFab-root{display:none!important;}`;
    el.type = 'text/css';
    if (el.styleSheet) {
      el.styleSheet.cssText = css;
    } else {
      el.innerHTML = css;
    }
    window[config.confTarget].document.getElementsByTagName('head')[0].appendChild(el);
  },
  open: async (id, callback) => {
    const { eventBus } = config;
    let token = await conf.getToken();
    let iframe = document.getElementById(config.confTarget);
    if (iframe && !iframe.getAttribute('data-init')) {
      iframe.setAttribute('data-init', 1);
      iframe.onload = () => {
        let url = iframe.contentWindow.location.href;
        if (url.indexOf('sfu') !== -1 && url.indexOf('login') === -1) {
          setTimeout(() => {
            iframe.style.visibility = 'visible';
            conf.init();
            conf.initStyle();
          }, 1000);
        }
      };
    }
    delay.open = setTimeout(() => {
      window.open(
        config.apiSDK + `/sfu/?token=${token}&confId=${id}&videoMute=false&audioMute=false&password=&targetName=null`,
        config.confTarget
      );
    }, 1000);
    return new Promise((resolve) => {
      eventBus.once('rspGetConfTerList', () => {
        callback && callback(config);
        resolve(config);
      });
    });
  },
  getToken: async () => {
    if (config.token) {
      return config.token;
    } else {
      let login = await postForm(config.apiSDK + '/api/v3/user/login', {
        appId: config.appId, // String Y 32 字符 应用号
        account: config.userName,
        pwd: md5(config.confPsw), // 加密单独的会议密码，占时与用户名相同
        type: '1', //String N 短整型 用户类型，1:注册用户 2:锐势终端
        companyID: '' //String N <=255 字符 登录的平台的企业标识，为空
      });
      if (login.isSuccess) {
        setConfig({
          token: login.data.token,
          user: login.data
        });
        return login.data.token;
      }
    }
  },
  getMember: (id) => {
    // 根据用户名查找会议列表中的用户信息，管理员传空字符串，其他人传userName或terminalip或id
    const list = config.confList || [];
    console.log(id, list, 'get member...');
    let user;
    if (!id) {
      // 管理员
      user =
        list.filter((o) => {
          return o.id === config.user.suid;
        })[0] || list[0];
    }
    if (list) {
      list.forEach((o) => {
        if (id && (o.terminalip.indexOf(id) !== -1 || o.id === Number(id))) {
          user = o;
        }
      });
    }
    return user;
  },
  heartbeat: () => {
    // 心跳
    // if (config.confWS?.readyState === 1) {
    conf.send({
      op: 'heartbeat',
      token: config.confToken || '',
      confid: config.confId || '',
      clientId: config.confClientId || '',
      status: 0
    });
    // } else {
    //   conf.init();
    // }
  },
  login: () => {
    // 登录
    conf.send({
      op: 'login', //   string login
      account: config.userName, //   string 账号
      pwd: md5(config.confPsw), //   string 密码
      appid: config.appId, //   string 应用 id
      clientId: config.confClientId || '' //   string 客户端标识，心跳接口返回
    });
  },
  control: () => {
    // 控制
    conf.send({
      op: 'confcontrol', //   string login
      status: '1',
      confid: config.confId, //   string 应用 id
      token: config.confToken, //   string 应用 id
      appid: config.appId, //   string 应用 id
      clientId: config.confClientId || '' //   string 客户端标识，心跳接口返回
    });
  },
  list: async () => {
    const { eventBus } = config;
    // 获取视频会议中的用户列表
    conf.send({
      op: 'getconfterlist', //   string login
      confid: config.confId, //   string 应用 id
      token: config.confToken, //   string 应用 id
      appid: config.appId, //   string 应用 id
      clientId: config.confClientId || '' //   string 客户端标识，心跳接口返回
    });
    return new Promise((resolve) => {
      eventBus.once('rspGetConfTerList', () => {
        resolve(config.confList);
      });
    });
  },
  send: (data) => {
    // 发送ws信息
    if (config.confWS?.readyState === 1) {
      config.confWS.send(JSON.stringify(data));
    }
  }
};

/**
 * 邀请成员
 * @param {String} member 会议成员ID
 * @param {Boolean} isApp 是否为拥有hsUser的App账号
 * @param {Function} callback 回调
 */
export async function addMember(member, options = {}) {
  const { video = true, isApp = false, callback } = options;
  if (isApp) {
    // App账号特殊处理
    // console.log(user ? user.terminalip : (config.info.sphyQz || '19') + member);
    let invite = await postForm(config.apiSDK + '/api/v3/conference/invite', {
      rooms: '', //  String N  终端id，用逗号拼接
      participants: member, //  String N  与会人id，用逗号拼接，用户信息中的 hsUser 字段
      appId: config.appId, // String Y 32 字符 应用号，向平台申请
      token: config.token, //  String Y 字符 访问令牌
      confId: config.confId, //  String Y 长整型 会议号
      sendMsg: '1' //  String Y默认值
    });
    callback && callback(invite);
    return invite;
  } else {
    const user = conf.getMember(member); // 能取到user时，使用user.terminalip，否则说明没在用户列表中，为用户id
    if (video) {
      let invite = await postForm(config.apiSDK + '/api/v3/terminal/invite', {
        'terminal.deviceType': '5', // int Y =5 设置为 5
        'terminal.protocolType': '1', // int Y =1、2 协议类型1:SIP 协议2:H323 协议
        'terminal.bandwidth': '2048', // int Y 整数 呼叫带宽（kb/s）384,768,1024,2048,3072,4096
        'terminal.ip': user ? user.terminalip : (config.info.sphyQz || '19') + member, // String Y ip  呼叫前缀名（对方Sip终端部署的服务地址）+对方 SIP 平台终端号码
        appId: config.appId, // String Y 32 字符 应用号，向平台申请
        secretKey: config.secretKey, //  String N 字符 应用秘钥，向平台申请
        token: config.token, //  String Y 字符 访问令牌
        terId: '', //  String N 整型 终端入会 mc 返回的 terid，需要使用主持人的 terid 邀请才能成功
        terName: '', //  String N 字符 终端昵称
        confId: config.confId //  String Y 长整型 会议号
      });
      callback && callback(invite);
      return invite;
    }
  }
}

/**
 * 创建会议
 * @param {Array[string]} members 会议成员
 * @param {HTMLElement} media 媒体控制，Video | Audio Dom
 * @param {Boolean} video 视频会议？
 * @returns {Boolean} 是否创建成功
 */
export async function createConf(members, { media, video = true, type = '', name = '', password = '', callback }) {
  if (video) {
    let token = await conf.getToken();
    let create = await postForm(config.apiSDK + '/api/v3/conference/create', {
      appId: config.appId, // String Y   32 字符 应用号，向平台申请 无
      secretKey: config.secretKey, // String N   字符 应用秘钥，向平台申请 无
      token: token, // String Y   字符 登录用户的 token 无
      confPwd: password, // String N   <=50 字符 密码 无
      thirdConfId: '', // String N   <=50 字符且不能包含@第三方会议号app 范围内唯一，不传由系统默认生成无
      startTime: '', // String N   时间格式 会议开始时间yyyy-MM-ddHH:mm:ss无
      endTime: '', // String N   时间格式 结束时间yyyy-MM-ddHH:mm:ss无
      confType: type, // uint32 N   =1 会议类型（O） 1:普通(会议)会议 3:调度会议 4:访谈会议
      startType: '', // uint32 N   =2 或 3 开启方式（O）2 有人进入开启3 管理员进入开启2
      confName: name, // String N   <=200 字符 会议名称 无
      maxTerm: '', // String N   整型 最大终端数 100
      audioEnable: '', // String N   =0 或 1 是否启用音频 1
      videoEnable: '', // String N   =0 或 1 启用视频 1
      permanentEnable: '', //String N   =0 或 1 多次召开 1
      ctrlUserId: '', // String N   整型 会控者 无
      participants: '', // String N   整型拼串 与会人列表，userid 拼串，,分隔无
      rooms: '', // String N   整型拼串 与会终端（会议室）列表，userid'',  // 拼串，,分隔无
      micAutoEnable: '', //String N    =0 或 1 入会自动静音,0 否 1是0
      camaraAutoEnable: '', //String N    =0 或 1 入会自动打开视频,0否 1 是1
      encryptAlg: '', // String N    =0、1 或 2 媒体加密算法，0 无加密1 AES_1282 SM43 网安加密0
      msgType: '', // String N    1、2 拼串 发送通知的类型，1 表示短信2 表示 Email多个时以逗号分隔无
      videoSize: '', // uint32 N   =10 或 11 录播/直播分辨率 1010:720p11:1080p
      chairmans: '', // String N   整型拼串 联 席 主 持 人 列 表 ，userid拼串，；分隔无
      fileprops: '', // String N   base64 加 密格式File 的 JSONArray 的base64 加密格式。在特别说明中注释了 file 的相关属性无
      secKey: '', // String N   base64 加 密格式秘钥 String 类型的的base64 加密格式。无
      autoRec: '', // Uint32 N   =0 或 1 是否自动录制0 否1 是0
      watermarkEnable: '', //String'',   N   =0 或 1 是否启用水印 依据参数配置字段
      jsonp: '' // String N   字符 此项可以不填，若填写，则返回 jsonp 格式数据无
    });
    setConfig({
      confId: create.data?.confId
    });
    delay.create = setTimeout(async () => {
      // 马上打开会失败，延迟1秒打开
      await conf.open(create.data.thirdConfId, () => {
        if (members && members.length) {
          setTimeout(() => {
            members.forEach((o) => {
              addMember(o, { video: true });
            });
            setTimeout(() => {
              conf.list(); // 自动拉取成员
            }, 1000);
          }, 2000);
        }
        callback &&
          callback({
            id: create.data.confId
          });
      });
    }, 1000);
    /*
      if (list.isSuccess && list.data.list && list.data.list.length) {
        setConfig({
          confId: list.data.list[0].confId
        });
        // const { ip } = config;
        const number = list.data.list[0].thirdConfId;
        // console.log(
        //   ip,
        //   number,
        //   `https://111.229.117.19:15675/sfu/?token=${login.data.token}&confId=${number}&videoMute=false&audioMute=false&password=&targetName=null`,
        //   'conference'
        // );
      }
      */
  } else {
    const create = await postForm(config.apiSDK + '/androidApi/voip/createMeetingRoom.action', {
      userId: config.userName, //string类型，用户id
      userPwd: md5(config.userPsw), //string类型，用户md5加密密码（必须使用md5加密）
      userType: '2', //string类型，用户类型，2为pc用户，1为app用户(70000001 —— 7000100
      data: JSON.stringify({
        createId: config.userName, //创建者ID
        conferenceName: name, //会议名称
        groupId: '', //组id,没有则传空字符串
        conferenceType: '0', //固定值0，
        conferenceUserNum: 6, //成员的总量（包含自身）
        meetingMember: '', //添加成员id,多个以“,”隔开（包含自身）
        compType: '0', //固定值0
        size: '0' //固定值0
      }) //string类型,类似object,实际为string。传入object不被接收。
    });
    if (create.isSuccess) {
      create.name = create.name || name;
      call(create.conferenceId, { video: false, media: media });
      callback && callback(create);
    }
  }
}

export async function removeConf(id, { video = true, callback }) {
  if (video) {
    let token = await conf.getToken();
    let remove = await postForm(config.apiSDK + '/api/v3/conference/delete', {
      confId: id, // 会议id
      appId: config.appId, // String Y   32 字符 应用号，向平台申请 无
      secretKey: config.secretKey, // String N   字符 应用秘钥，向平台申请 无
      token: token // String Y   字符 登录用户的 token 无
    });
    if (remove.isSuccess) {
      callback && callback(remove);
    }
  } else {
    let remove = await postForm(config.apiSDK + '/androidApi/function/delMeetingByConfId.action', {
      id: id, // 会议id
      userId: config.userName, //string类型，用户id
      userPwd: md5(config.userPsw), //string类型，用户md5加密密码（必须使用md5加密）
      userType: '2' //string类型，用户类型，2为pc用户，1为app用户(70000001 —— 7000100
    });
    if (remove.isSuccess) {
      callback && callback(remove);
    }
  }
}

export async function getConf({ video = true, callback }) {
  if (video) {
    let token = await conf.getToken();
    let list = await postForm(config.apiSDK + '/api/v3/conference/list', {
      appId: config.appId, // String Y 32 字符 应用号
      secretKey: config.secretKey, // String N 字符 应用秘钥，向平台申请
      token: token, // String Y 字符 登录用户的 token
      currentPage: '1', // int N 正整数 当前页
      pageSize: '10', // int N 正整数 获取的个数
      scope: '0' // String N 字符 获取的数据范围。个人数据:0企业数据:1默认为 0
    }).catch((e) => {
      console.log(e);
    });
    if (list.isSuccess) {
      callback && callback(list.data);
      return list.data;
    }
    callback && callback({ data: [] });
    return { data: [] };
  } else {
    let list = await get(config.apiSDK + '/androidApi/voip/getMeetingList.action', {
      userId: config.userName, //string类型，用户id
      userPwd: md5(config.userPsw), //string类型，用户md5加密密码（必须使用md5加密）
      type: config.userType //string类型，用户类型，2为pc用户，1为app用户(70000001 —— 70001000  之间是调度台 userType用2 ,70001000 以上的用户  是用户 userType用1)
    }).catch((e) => {
      console.log(e);
    });
    if (list.isSuccess) {
      callback && callback(list.data);
      return list.data;
    }
    callback && callback([]);
    return [];
  }
}

export async function callConf(id, { video = true, media, confId, callback }) {
  if (video) {
    if (id) {
      // 拨打已有id的会议
      setConfig({
        confId: confId
      });
    } else {
      // 拨打会议列表中的第一个会议
      let list = await getConf({});
      if (list.isSuccess && list.data.list && list.data.list.length) {
        setConfig({
          confId: list.data.list[0].confId
        });
        id = list.data.list[0].thirdConfId;
      }
    }
    if (id) {
      await conf.open(id, callback);
    } else {
      // 列表为空，新建一个
      await createConf([], {
        media: media,
        video: true,
        name: '会议',
        callback: callback
      });
    }
  } else {
    call(id, { video: false, media: media });
  }
}

export async function openMemberVideo() {}

export async function getMembers({ video = true, callback }) {
  if (video) {
    const list = await conf.list();
    callback && callback(list);
    return list;
  }
}

export async function removeMember(member, options = {}) {
  const { video = true, locked = true, callback } = options;
  if (video) {
    const user = conf.getMember(member);
    config.confWS.send(
      JSON.stringify({
        op: 'dropter', //  string dropter
        confid: config.confId, //  long 会议号
        token: config.confToken, //  string 访问令牌appid string 应用 id
        clientId: config.confClientId, //  string 客户端id
        jointerid: user?.terid, //  int 被踢参会人terid，没有则传0
        suid: user?.id, //  int 用户 id
        locked: locked //  boolean 是否为踢掉True:踢掉False:挂断
      })
    );
    callback && callback();
  }
}

export async function muteConf(member = '', options = {}) {
  const { video = true, callback } = options;
  if (video) {
    const user = conf.getMember(member);
    config.confWS.send(
      JSON.stringify({
        op: 'muteone', //  string dropter
        confid: config.confId, //  long 会议号
        token: config.confToken, //  string 访问令牌appid string 应用 id
        clientId: config.confClientId, //  string 客户端id
        appid: config.appId, //  string 客户端id
        jointerid: user?.terid //  int 被踢参会人terid，没有则传0
      })
    );
    callback && callback(config);
  }
}

export async function unmuteConf(member = '', options = {}) {
  const { video = true, callback } = options;
  if (video) {
    const user = conf.getMember(member);
    config.confWS.send(
      JSON.stringify({
        op: 'cancelmute', //  string dropter
        confid: config.confId, //  long 会议号
        token: config.confToken, //  string 访问令牌appid string 应用 id
        clientId: config.confClientId, //  string 客户端id
        appid: config.appId, //  string 客户端id
        jointerid: user?.terid //  int 被踢参会人terid，没有则传0
      })
    );
    callback && callback(config);
  }
}

export async function focusConfVideo(member = '', options = {}) {
  const { video = true, callback } = options;
  if (video) {
    const user = conf.getMember(member);
    config.confWS.send(
      JSON.stringify({
        op: 'setlockvideo', //  string dropter
        suid: user?.suid, //  int 需要打开摄像头的参会人suid
        terid: user?.terid, //  int 需要打开摄像头的参会人terid
        srcid: user?.cameras[0]?.srcid, //  int 视频源id
        confid: config.confId, //  long 会议号
        token: config.confToken, //  string 访问令牌appid string 应用 id
        clientId: config.confClientId, //  string 客户端id
        appid: config.appId //  string 客户端id
      })
    );
    callback && callback(config);
  }
}

export async function unfocusConfVideo(member = '', options = {}) {
  const { video = true, callback } = options;
  if (video) {
    const user = conf.getMember(member);
    config.confWS.send(
      JSON.stringify({
        op: 'setunlockvideo', //  string dropter
        suid: user?.suid, //  int 需要打开摄像头的参会人suid
        terid: user?.terid, //  int 需要打开摄像头的参会人terid
        srcid: user?.cameras[0]?.srcid, //  int 视频源id
        confid: config.confId, //  long 会议号
        token: config.confToken, //  string 访问令牌appid string 应用 id
        clientId: config.confClientId, //  string 客户端id
        appid: config.appId //  string 客户端id
      })
    );
    callback && callback(config);
  }
}

export async function openConfVideo(member = '', options = {}) {
  const { video = true, callback } = options;
  if (video) {
    const user = conf.getMember(member);
    config.confWS.send(
      JSON.stringify({
        op: 'opencamera', //  string dropter
        terid: user?.terid, //  int 需要打开摄像头的参会人terid
        srcid: user?.cameras[0]?.srcid, //  int 视频源id
        confid: config.confId, //  long 会议号
        token: config.confToken, //  string 访问令牌appid string 应用 id
        clientId: config.confClientId, //  string 客户端id
        appid: config.appId //  string 客户端id
      })
    );
    callback && callback(config);
  }
}

export async function closeConfVideo(member = '', options = {}) {
  const { video = true, callback } = options;
  if (video) {
    const user = conf.getMember(member);
    config.confWS.send(
      JSON.stringify({
        op: 'closecamera', //  string dropter
        terid: user?.terid, //  int 需要打开摄像头的参会人terid
        srcid: user?.cameras[0]?.srcid, //  int 视频源id
        confid: config.confId, //  long 会议号
        token: config.confToken, //  string 访问令牌appid string 应用 id
        clientId: config.confClientId, //  string 客户端id
        appid: config.appId //  string 客户端id
      })
    );
    callback && callback(config);
  }
}

export async function endConf(id, options = {}) {
  const { callback } = options;
  let confId = id || config.confId;
  let end = await postForm(config.apiSDK + '/api/v3/conference/end', {
    appId: config.appId, //  String Y 32 字符 应用号，向平台申请 无
    secretKey: config.secretKey, //  String Y 字符 应用秘钥，向平台申请 无
    token: config.token, //  String Y 字符 登录用户 token 无
    confId: confId, //  String Y 长整型 邀请与会人的会议号 无
    jsonp: '' //  String N 字符 此项可以不填，若填写，则返回 jsonp 格式数据
  }).catch((e) => {
    console.log(e);
    callback && callback({});
  });
  callback && callback({ ...end, id: confId });
  window.open(`about:blank`, config.confTarget);
  clearTimeout(delay.open);
  clearTimeout(delay.create);
  let iframe = document.getElementById(config.confTarget);
  if (iframe) {
    iframe.style.visibility = '';
  }
}

export function onConfChange(callback) {
  const { eventBus } = config;
  eventBus.on('confChange', (data) => {
    callback && callback(data, config);
  });
}

export function acceptConf() {}

export function acceptAudioConf() {}
