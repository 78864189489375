import React, { useEffect, useState, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { message, Modal, List, Badge, Empty } from 'antd';
import Draggable from 'react-draggable';
import YunliRTC from '../../rtc';
import styles from './index.module.less';
import { toJS } from 'mobx';

const Incoming = ({ VideoCallStore }) => {
  const [space, setSpace] = useState(0); // 读取css间距

  // 拖动
  const [disabled, setDisabled] = useState(false);
  const draggleRef = useRef([]);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });

  const config = YunliRTC.getConfig();
  const eventBus = config.eventBus;

  useEffect(() => {
    setInterval(() => {
      if (VideoCallStore.incomingList.length) {
        VideoCallStore.isIncominngAnimate = !toJS(VideoCallStore.isIncominngAnimate);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    setSpace(parseInt(getComputedStyle(document.body).getPropertyValue('--ylr-space')));
  }, [VideoCallStore.incomingList]);

  const onStart = (_event, uiData, index) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current[index]?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    });
  };

  return (
    <>
      {VideoCallStore.isCallMissVisible && VideoCallStore.callMissList?.length ? (
        <Modal
          title="未接来电"
          className={
            VideoCallStore.css +
            'Dialog isSmall isMove' +
            (VideoCallStore.isCallMissMin ? ' isMin' : '') +
            (VideoCallStore.callMissModalOffset ? ' isOffset' : '')
          }
          width={400}
          zIndex={9}
          mask={false}
          maskClosable={false}
          wrapClassName={VideoCallStore.css + 'DialogWrap isNoMark'}
          open
          getContainer={VideoCallStore.callMissModalContainer}
          centered={!VideoCallStore.callMissModalOffset}
          style={
            VideoCallStore.callMissModalOffset
              ? {
                  left: 'calc(100vw + 20px)',
                  top: '14px',
                  margin:
                    (VideoCallStore.callMissModalOffset.y || 0) +
                    'px 0 0 ' +
                    (VideoCallStore.callMissModalOffset.x || 0) +
                    'px'
                }
              : {
                  left: 'calc(150vw - 170px)',
                  top: VideoCallStore.callMissModalContainer ? '14px' : ''
                }
          }
          footer={null}
          onOk={() => {}}
          onCancel={() => {
            VideoCallStore.isCallMissMin = true;
            // VideoCallStore.isCallMissVisible = false;
          }}
          modalRender={(modal) => (
            <Draggable disabled={disabled} bounds={bounds} onStart={(event, uiData) => onStart(event, uiData, 0)}>
              <div
                ref={(el) => {
                  draggleRef.current[0] = el;
                }}
                onMouseOver={() => {
                  if (disabled) {
                    setDisabled(false);
                  }
                }}
                onMouseOut={() => {
                  setDisabled(true);
                }}>
                {modal}
              </div>
            </Draggable>
          )}>
          <div
            className={styles.max + ' commonDialogMax isMinHide'}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}>
            {VideoCallStore.callMissList.length ? (
              <div
                className={styles.callMissIgnoreAll}
                onClick={() => {
                  eventBus.emit('yunli-rtc-turnplate-call-miss-ignore', toJS(VideoCallStore.callMissList));
                  VideoCallStore.callMissList = [];
                }}>
                <i className="ico ico-call-miss-ignore"></i>忽略全部
              </div>
            ) : (
              ''
            )}
          </div>
          <div
            className={styles.callMissModal + ' commonScroll isMinHide' + ' isCall'}
            style={{ display: VideoCallStore.isCallMissVisible ? '' : 'none' }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}>
            {VideoCallStore.callMissList.length ? (
              <List className="commonList">
                {VideoCallStore.callMissList.map((o, i) => (
                  <List.Item key={i}>
                    <List.Item.Meta title={o.id} description={o.time} />
                    <div>
                      <em
                        className="ico ico-call-miss-audio"
                        onClick={() => {
                          VideoCallStore.call({
                            id: o.id
                          });
                        }}></em>
                      <em
                        className="ico ico-call-miss-video"
                        onClick={() => {
                          VideoCallStore.call({
                            id: o.id,
                            video: true
                          });
                        }}></em>
                      {/* <em className="ico ico-call-miss-msg"></em> */}
                      <em
                        className="ico ico-call-miss"
                        onClick={() => {
                          eventBus.emit(
                            'yunli-rtc-turnplate-call-miss-ignore',
                            toJS(VideoCallStore.callMissList.splice(i, 1))
                          );
                        }}></em>
                    </div>
                  </List.Item>
                ))}
              </List>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => {
              onStart(event, uiData, 1);
              event.stopPropagation(); // 阻止外层的拖拽
            }}
            onDrag={() => {
              VideoCallStore.isMouseMoving = true;
            }}>
            <div
              ref={(el) => {
                draggleRef.current[1] = el;
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}>
              <div
                className={styles.minCallModal + ' isMinShow'}
                // style={{ display: VideoCallStore.isMin || VideoCallStore.isConfMin ? 'none' : '' }}
                onClick={() => {
                  if (!VideoCallStore.isMouseMoved()) {
                    VideoCallStore.isCallMissMin = !VideoCallStore.isCallMissMin;
                  }
                }}>
                <em className="ico ico-call-min-audio"></em>
                <div className={styles.time + ' ' + styles.text}>未接来电</div>
                <Badge count={VideoCallStore.callMissList.length} offset={[70, -107]}></Badge>
              </div>
            </div>
          </Draggable>
        </Modal>
      ) : (
        ''
      )}
      {VideoCallStore.isRegisted &&
        VideoCallStore.incomingList.map((o, i) => (
          <Modal
            title={o.type === 'conf' ? '视频会议' : o.type === 'audio' ? '来电提示' : '视频通话'}
            key={o}
            className={VideoCallStore.css + 'Dialog isSmall'}
            mask={false}
            maskClosable={false}
            wrapClassName={VideoCallStore.css + 'DialogWrap isNoMark isBottom'}
            style={{
              top: 'auto',
              bottom: (209 + (space ? space * 2 : 0)) * i + -2 + 'px',
              right: '19px',
              zIndex: '9999',
              position: 'absolute'
            }}
            width={278}
            open={o.visible}
            getContainer={VideoCallStore.modalContainer}
            footer={null}
            onOk={() => {}}
            onCancel={() => {
              // 挂断
              VideoCallStore.incomingHangup(i);
            }}>
            <div className={styles.turnplateRingContainer}>
              <div className={styles.defaultCallPhone}>
                <span>{o.phoneNum}</span>
                <em>{o.nickName}</em>
              </div>
              <div
                className={styles.rejectRingBtn}
                onClick={() => {
                  // 挂断
                  VideoCallStore.incomingHangup(i);
                }}>
                <i className="ico ico-call-hangup" />
              </div>
              <div
                className={styles.acceptRingBtn}
                onClick={() => {
                  // 接听
                  if (VideoCallStore.status !== 'default') {
                    message.error((VideoCallStore.status === 'accepted' ? '通话' : '拨号') + '中，无法接听');
                    return;
                    // 挂断现在通话
                    // YunliRTC.hangup({ id: VideoCallStore.id });
                  }

                  VideoCallStore.status = 'accepted';
                  o.visible = false;
                  setTimeout(() => {
                    VideoCallStore.incomingRemove(i);
                    VideoCallStore.stopIncoming();
                  }, 500);

                  VideoCallStore.isCallVisible = true;
                  VideoCallStore.showCallTime();

                  VideoCallStore.type = o.type;
                  VideoCallStore.callPhoneNum = o.phoneNum;
                  VideoCallStore.callNickname = o.nickname;

                  if (o.type === 'conf') {
                    if (VideoCallStore.sdk === 'ly') {
                      // 凌壹会议
                      VideoCallStore.confInit();
                      setTimeout(() => {
                        YunliRTC.callConf(VideoCallStore.confInfo.THIRDCONFID, {
                          confId: VideoCallStore.confInfo.CONFID,
                          video: true,
                          media: VideoCallStore.remoteAudio()
                        });
                      }, 1000);
                    } else {
                      // 航美会议
                      VideoCallStore.isConfVisible = true;
                      VideoCallStore.confInit();

                      setTimeout(() => {
                        YunliRTC.acceptConf({
                          media: null
                        });
                        VideoCallStore.isConfLoading = false;
                        VideoCallStore.isConfIncoming = true;
                        VideoCallStore.isConfMemberVisible = true;
                        setTimeout(() => {
                          VideoCallStore.showLocalVideo(VideoCallStore.confVideo());
                        }, 3000);
                      }, 200);
                      // VideoCallStore.type = 'video';
                    }
                  } else if (o.type === 'video') {
                    setTimeout(() => {
                      YunliRTC.answer({
                        id: o.id,
                        media: VideoCallStore.remoteVideo(),
                        video: true,
                        callback: (res) => {
                          VideoCallStore.id = res.id;
                        }
                      });
                      VideoCallStore.showLocalVideo();
                    }, 100);
                    // YunliRTC.hangup();
                    // VideoCallStore.isConfVisible = true;
                    // YunliRTC.createConf([o.phoneNum], {
                    //   name: moment().format('YYYY-MM-DD HH:mm:ss'),
                    //   video: true,
                    //   media: VideoCallStore.remoteVideo(),
                    //   callback: (res) => {
                    //     // setTimeout(() => {
                    //     //   VideoCallStore.getMembers();
                    //     // }, 3000);
                    //   }
                    // });
                  } else {
                    setTimeout(() => {
                      YunliRTC.answer({
                        id: o.id,
                        media: VideoCallStore.remoteVideo(),
                        video: false,
                        callback: (res) => {
                          VideoCallStore.id = res.id;
                        }
                      });
                    }, 100);
                  }
                }}>
                <i
                  className={
                    'ico ico-call-' +
                    (o.type !== 'audio' ? 'video' : 'audio') +
                    ' ' +
                    (o.type !== 'audio' ? styles.isVideo : '') +
                    (VideoCallStore.isIncominngAnimate ? ' ' + styles.isNormal : '')
                  }
                />
              </div>
            </div>
          </Modal>
        ))}
    </>
  );
};

export default inject('VideoCallStore')(observer(Incoming));
