import { makeAutoObservable } from 'mobx';
import * as API from '../service/apis/VideoCallApi';
import { message } from 'antd';
import YunliRTC from '../rtc';
// import eventBus from '../plugins/eventBus';
import incomingMp3 from '../assets/image/rtc/incoming.wav';
import moment from 'moment';
import toTreeData from '../plugins/utils/tree';
import incomingPositionIcon from '../assets/image/rtc/incoming-position.svg';
import fixWebmDuration from 'fix-webm-duration';

const config = YunliRTC.getConfig();

class VideoCallStore {
  constructor() {
    makeAutoObservable(this);
  }
  // 样式前缀（commonDialog commonBtn等）
  css = 'common';
  // 样式主题（Cyan Blue DarkBlue White，默认为 Cyan）
  theme = 'Cyan';
  // 设置主题
  setTheme = (name) => {
    let curClassName = document.body.className,
      theme = 'commonTheme' + name;
    document.body.className = curClassName.replace(/\s*commonTheme[^_\s]+\s?/g, '') + ' ' + theme;
  };
  // SDK版本（hm-航美、ly-凌壹）
  sdk = 'ly';
  // 是否移动端
  isMobile = false;
  // 是否注册登录成功（null-未注册 false-注册失败 true-注册成功）
  isRegisted = null;
  // 是否离线后自动登录（拨打电话前，保证登录状态）
  isAutoOnline = false;
  // 视频会议是否打开
  isConfVisible = false;
  // 视频会议成员是否打开
  isConfMemberVisible = false;
  // 是否视频会议加载中
  isConfLoading = true;
  // 是否为接听视频会议
  isConfIncoming = false;
  // 是否为视频会议，默认true，否则为音频会议（仅航美使用）
  isConfVideo = true;
  // 转盘是否打开，默认隐藏，否则不需要的场景会有闪烁的问题
  isTurnplateVisible = false;
  // 邀请是否打开
  isInviteVisible = false;
  // 调度人员是否打开
  isControlVisible = false;
  // 拨号盘是否打开
  isCallVisible = false;
  // 是否允许语音通话转为视频通话（默认为true）
  isAudioToVideo = true;
  // 是否允许语音通话转为视频会议（默认为false）
  isAudioToConf = false;
  // 是否允许音视频通话邀请人员（默认为true）
  isCallInvite = true;
  // 未接来电是否打开
  isCallMissVisible = false;
  // 是否本地视频前置（放大）
  isLocalViedoFront = false;
  // 是否视频通话按钮在底部的样式（适用于自定义按钮较多的场景）
  isVideoBtnBottom = false;
  // 视频播放是否打开
  isPlayerVisible = false;
  // 视频播放是否允许同时打开多个
  isPlayerMulti = true;
  // 视频直播是否打开
  isLiveVisible = false;
  // 视频直播是否为播放URL模式
  isLiveUrl = false;
  // 通讯录是否打开
  isContactsVisible = false;
  // 来电动画
  isIncominngAnimate = false;
  // 鼠标正在移动
  isMouseMoving = false;
  // 判断鼠标是否移动
  isMouseMoved = () => {
    const isMoved = this.isMouseMoving;
    this.isMouseMoving = false;
    return isMoved;
  };
  // 通话 session ID
  id = '';
  // 会议 session ID
  confId = '';
  // 视频播放器 session ID
  playerId = '';
  // 直播 呼叫ID
  liveCallId = [];
  // 直播 session ID
  liveId = {};
  // 直播名称
  liveName = {};
  // 直播标题
  liveTitle = '';
  // 拨号盘状态: 'accepted', 'progress', 'failed', 'ended'（default, calling, online）
  status = 'default';
  // 来电方向：'outgoing', 'incoming'
  direction = '';
  // 通话类型：'video', 'audio', 'conf'
  type = '';
  // 来电类型：'video', 'audio', 'conf'
  // incomingType = [];
  // 会议来电信息
  confInfo = {};
  // 来电列表（id-session ID type-来电类型 visible-是否可见 phoneNum-来电ID nickName-来电昵称）
  incomingList = [];
  // 音视频播放器列表
  playerList = [];
  // 根据session ID或号码查询某个player信息
  getPlayer = (id, phoneNum) => {
    const list = this.playerList.filter((o) => o.id === id || o.phoneNum === phoneNum);
    // console.log('get player', id, phoneNum, list);
    // 号码相同时，取后面的，需要优先更新一些内容，如状态等
    return list[list.length - 1];
  };
  // 根据session ID或号码查询某个player的index
  getPlayerIndex = (id, phoneNum) => {
    let index = -1;
    this.playerList.forEach((o, i) => {
      if ((o.id === id || o.phoneNum === phoneNum) && index === -1) {
        // 号码相同时，优先取前面的，一般先拨打的先关闭
        index = i;
      }
    });
    return index;
  };
  // 设备不支持的提示
  deviceSupportTips = '';
  // 会议自定义标题，默认为'视频会议'
  confTitle = '视频会议';
  // 邀请自定义标题，默认为'邀请成员'
  inviteTitle = '';
  // 邀请自定义提交按钮，默认为'邀请'
  inviteOkText = '';
  // 通话窗口自定义标题，默认为空
  callTitle = '';
  // 视频通话宽度
  callVideoWidth = 938;
  // 通话时间
  callTime = 0;
  // 通话的各种信息
  callInfo = {};
  // 是否静音
  isMute = false;
  // 是否开启摄像头
  isVideo = true;
  // 是否最大化
  isMax = false;
  // 是否最小化
  isMin = false;
  // 未接来电是否最小化
  isCallMissMin = true;
  // 会议是否最大化
  isConfMax = false;
  // 会议是否最小化
  isConfMin = false;
  // 是否隐藏静音功能（默认为false，可以隐藏不显示静音按钮，会议不允许隐藏）
  isMuteHidden = false;
  // 是否隐藏会议成员按钮（默认为false）
  isMemberBtnHidden = false;
  // 是否视频来电使用语音接听（默认为false）
  isIncomingAllAudio = false;
  // 是否播放本地音频，主要用来录屏的时候把本地音频录进去（默认为false）
  isPlayLocalAudio = false;
  // 本地视频
  localVideoId = 'yunli-rtc-local-video';
  localVideo = () => {
    return document.getElementById(this.localVideoId) || {};
  };
  // 来电视频
  remoteVideoId = 'yunli-rtc-remote-video';
  remoteVideo = () => {
    return document.getElementById(this.remoteVideoId) || {};
  };
  // 会议视频
  confVideoId = 'yunli-rtc-conf-video';
  confVideo = (index = config.userName) => {
    // 根据用户id作为所用，默认取调度台的id
    return document.getElementById(this.confVideoId + '-' + index) || {};
  };
  // 播放器视频（需要创建两个video，共用同一个video会有冲突，导致url无法播放）
  playerVideoId = 'yunli-rtc-player-video';
  playerUrlVideoId = 'yunli-rtc-player-url-video';
  playerVideo = (index) => {
    return document.getElementById(this.playerVideoId + '-' + index) || {};
  };
  playerUrlVideo = (index) => {
    let el = document.getElementById(this.playerUrlVideoId + '-' + index),
      nodeName = el.nodeName;
    if (!(nodeName === 'VIDEO' || nodeName === 'AUDIO')) {
      // 处理live-player时，获取内嵌原生元素
      el = el.querySelectorAll('audio,video')[0];
    }
    return el || {};
  };
  // 直播视频
  liveVideoId1 = 'yunli-rtc-live-video-1';
  liveVideoId2 = 'yunli-rtc-live-video-2';
  liveVideoId3 = 'yunli-rtc-live-video-3';
  liveVideoId4 = 'yunli-rtc-live-video-4';
  liveVideo = (num) => {
    return document.getElementById(this['liveVideoId' + num]) || {};
  };
  // 获取直播URL模式，不使用拨号
  getLiveVideoUrl = null;
  // 获取视频播放url
  getVideoUrl = async (params = {}) => {
    return await API.getVideoUrl(params);
  };
  // 来电音频
  remoteAudioId = 'yunli-rtc-remote-audio';
  remoteAudio = () => {
    return document.getElementById(this.remoteAudioId) || {};
  };
  // 对讲音频
  talkAudioId = 'yunli-rtc-talk-audio';
  talkAudio = () => {
    return document.getElementById(this.talkAudioId) || {};
  };
  // 铃声音频
  incomingAudioId = 'yunli-rtc-incoming-audio';
  incomingAudio = () => {
    return document.getElementById(this.incomingAudioId) || {};
  };
  // 弹窗容器设置
  modalContainer = null;
  // 未接来电弹窗容器设置
  callMissModalContainer = null;
  // 未接来电弹窗容器坐标（不设置则居中）
  callMissModalOffset = null;
  // 未接来电列表
  callMissList = [];
  // 通讯录对照表，查找每个账号对应的信息
  contactsHash = {};
  // 通讯录列表
  contactsList = [];
  // 监控列表
  equipmentList = [];
  // 结束通话提示（确认结束前面）
  endTips = '';
  // 音视频通话左侧按钮
  callLeftBtn = null;
  // 会议顶部按钮
  confTopBtn = null;
  // 会议左侧按钮
  confLeftBtn = null;
  // 会议中间按钮
  confCenterBtn = null;
  // 邀请会议名单
  confInviteList = [];
  // 会议成员对照表，没有则去通讯录中查找
  confMemberHash = {};
  // 会议成员列表
  confMemberList = [];
  // 会议成员视频补齐占位列表
  confMemberEmpty = [];

  // 初始化会议成员
  initMember = (o) => {
    o.suid = o.suid || o.id;
    o.nickname = o.nickname || o.name || o.id;
    o.isCloseVideo = false; // 是否关闭视频
    o.isOpenVideo = false; // 是否已经打开视频，避免重复打开
    // if (o.cameras && o.cameras.length && o.cameras[0].iscameraon) {
    //   o.isCloseVideo = false;
    // }
    if (o.terminalip && o.terminalip.indexOf(o.nickname) === -1) {
      // 假如nickname不是号码（rspGetConfTerList返回的不是号码，rspTerJoinConf返回的是号码），从terminalip中取
      o.nickname = o.terminalip.slice(2);
    }
    return o;
  };

  // 获取会议成员列表
  getMembers = () => {
    let get = () => {
      YunliRTC.getMembers({
        video: true,
        callback: (data) => {
          data.forEach((o) => {
            this.initMember(o);
          });
          this.confMemberList = data;
        }
      });
    };
    get();
    setTimeout(() => {
      // 凌壹的列表接口有很大的延迟，再请求一遍，防止出错
      get();
    }, 3000);
  };

  // 获取号码类型
  getCallType = (num = '') => {
    // 获取号码类型（0：调度台，1：通讯录人员，2：监控视频，3：外部号码）
    const nick = this.getContactName(num);
    // 未包含调度台给调度台拨打的情况，无法判断
    if (('' + num).match(/^888/g)) {
      // 监控
      return 2;
    } else if (nick === '未知人员') {
      // 外部号码
      return 3;
    } else {
      // 通讯录人员
      return 1;
    }
  };

  // 获取通讯录ID
  getContactsId = (num = '') => {
    const contact = this.contactsHash[num];
    if (contact) {
      return contact.contactsId;
    } else {
      return '';
    }
  };

  // 显示来电时间
  callTimeDelay = '';
  showCallTime = () => {
    this.callTime = 0;
    clearInterval(this.callTimeDelay);
    this.callTimeDelay = setInterval(() => {
      this.callTime++;
    }, 1000);
  };

  // 隐藏来电时间
  hideCallTime = () => {
    this.callTime = 0;
    clearInterval(this.callTimeDelay);
  };

  // 搜索通讯录列表URL
  searchContactsListUrl = null;
  // 搜索通讯录
  searchContacts = (params) => {
    if (this.searchContactsListUrl === '') {
      // 不拉取
      return [];
    }
    return API.searchContactsList(params).then((res) => {
      return res?.data || [];
    });
  };
  // 获取通讯录列表URL
  getContactsListUrl = null;
  // 通讯录已选列表
  contactsListChecked = [];
  // 获取通讯录列表
  getContactsList = async (callback) => {
    if (this.getContactsListUrl === '') {
      // 不拉取
      return [];
    }
    if (this.contactsList.length) {
      return this.contactsList;
    }
    const list = await API.getContactsList(
      {
        // contactsLabelId: '',
        // contactsName: '',
        // pageNum: 1,
        // pageSize: 100
      },
      this.getContactsListUrl
    );
    if (list.code === '200' && list.data?.length) {
      toTreeData(
        list.data,
        {
          title: 'contactsLabelName',
          children: 'contactsChildren'
        },
        (o) => {
          if (o.contactsId) {
            o.id = o.id || o.contactsId;
            o.name = o.name || o.contactsName;
            if (o.mobile) {
              o.title = o.name + ' ' + o.mobile;
              this.contactsHash[o.mobile] = o;
            }
          } else if (!o.children?.length) {
            o.disabled = true;
          }
        }
      );
      this.contactsList = list.data;
      callback && callback(list.data);
      return list.data;
    }
    return [];
  };
  // 设置通讯录列表
  setContactsList = (data) => {
    toTreeData(
      data,
      {
        title: 'contactsLabelName',
        children: 'contactsChildren'
      },
      (o) => {
        if (o.contactsId) {
          o.id = o.id || o.contactsId;
          o.name = o.name || o.contactsName;
          if (o.mobile) {
            o.title = o.name + ' ' + o.mobile;
            this.contactsHash[o.mobile] = o;
          }
        } else if (!o.children?.length) {
          o.disabled = true;
        }
      }
    );
    this.contactsList = data;
  };

  // 获取监控列表URL
  getEquipmentListUrl = null;
  // 监控已选列表
  equipmentListChecked = [];
  // 获取监控列表
  getEquipmentList = async (callback) => {
    if (this.getEquipmentListUrl === '') {
      // 不拉取
      return [];
    }
    if (this.equipmentList?.length) {
      return this.equipmentList;
    }
    const list = await API.getEquipmentList(
      {
        // contactsLabelId: '',
        // contactsName: '',
        // pageNum: 1,
        // pageSize: 100
      },
      this.getEquipmentListUrl
    );
    if (list.code === '200' && list.data?.length) {
      toTreeData(
        list.data,
        {
          title: ['contactsLabelName', 'contactsName'],
          children: 'equipmentChildren'
        },
        (o) => {
          if (o.contactsId) {
            o.id = o.id || o.contactsId;
            o.name = o.name || o.contactsName;
            if (o.callId) {
              this.contactsHash[o.callId] = o;
            }
          } else if (!o.children?.length) {
            o.disabled = true;
          }
        }
      );
      this.equipmentList = list.data;
      callback && callback(list.data);
      return list.data;
    }
    callback && callback([]);
    return [];
  };
  // 设置监控列表
  setEquipmentList = (data) => {
    toTreeData(
      data,
      {
        title: ['contactsLabelName', 'contactsName'],
        children: 'equipmentChildren'
      },
      (o) => {
        if (o.contactsId) {
          o.id = o.id || o.contactsId;
          o.name = o.name || o.contactsName;
          if (o.callId) {
            this.contactsHash[o.callId] = o;
          }
        } else if (!o.children?.length) {
          o.disabled = true;
        }
      }
    );
    this.equipmentList = data;
  };
  // 获取通讯录地理位置URL
  getLocationUrl = null;
  // 获取通讯录地理位置
  getLocation = async (id, callback) => {
    if (this.getLocationUrl === '') {
      // 不拉取
      return {};
    }
    let info = await API.getLocation(
      {
        callerNumber: id
      },
      this.getLocationUrl
    );
    // info = {
    //   code: '200',
    //   data: {
    //     address: '北京市XXX区XXX大厦',
    //     longitude: '113.425351',
    //     latitude: '34.546303'
    //   }
    // };
    if (info.code === '200' && info.data) {
      callback && callback(info.data);
      this.incomingLocation = info.data;
      return info.data;
    }
    this.incomingLocation = {};
    callback && callback({});
    return {};
  };
  // 来电定位信息
  incomingLocation = {};
  // 地图
  map = null;
  // 地图标记
  incomingMark = {};
  // 隐藏通讯录地理位置
  hideLocation = async (id) => {
    if (this.map && this.incomingMark[id]) {
      this.incomingMark[id].forEach((o) => {
        this.map.remove(o);
      });
      delete this.incomingMark[id];
    }
  };
  // 显示通讯录地理位置
  showLocation = async (id, res) => {
    if (this.map && res) {
      const position = [res.longitude, res.latitude];
      const icon = new window.YunliMapGL.Marker({
        src: incomingPositionIcon,
        position: position,
        offset: [0, 5],
        anchor: 'bottom',
        rotation: 0
      });
      this.map.add(icon);
      const text = new window.YunliMapGL.InfoWindow({
        content: `<div class="${this.css}InfoWindow">
            <div>${res.address}</div>
          </div>`,
        position,
        offset: [0, 50],
        anchor: 'bottom-center',
        zIndex: 1,
        stopEvents: true // 交互事件仅操作信息窗，不回传递到地图
      });
      this.map.add(text);
      this.map.setCenter(position);
      this.incomingMark[id] = [icon, text];
    }
  };

  // 人员调度发送任务
  sendTask = async (params) => {
    params.tenantId = 'default';
    params.userId = 'admin';
    return await API.sendTask(params);
  };

  // 给app推送来电消息
  sendPush = async (params) => {
    params.tenantId = 'default';
    params.userId = 'admin';
    return await API.sendPush(params);
  };

  // 会议
  conf = (ids = [], options = {}) => {
    const { eventBus, userName, userNick } = config;
    if (this.isRegisted === null) {
      // 未注册不可用
      eventBus?.emit('yunli-rtc-turnplate-disabled', {
        key: 'call',
        value: options
      });
      return;
    }
    if (!this.isRegisted && !this.isAutoOnline) {
      // 注册失败，且非自动重新登录
      eventBus?.emit('yunli-rtc-turnplate-login-tips', {
        key: 'conf',
        value: {
          ids,
          options
        }
      });
      return;
    }
    if (ids.length === 1 && ids[0].id === config.userName) {
      message.error('不能呼叫自己');
      return;
    }
    if (this.status !== 'default') {
      message.error((this.status === 'accepted' ? '通话' : '拨号') + '中，无法呼叫');
      return;
    }
    const isLY = this.sdk === 'ly';
    let admin;
    this.isConfVisible = true;

    this.confInit();

    if (!isLY) {
      // 航美，预制video
      admin = {
        id: userName,
        name: userNick,
        isonline: true,
        isOpenVideo: true
      };
      this.confMemberHash[admin.id] = admin;
      this.confMemberList = [this.initMember(admin)];

      // 航美增加一个假的，解决可能创建失败的问题，暂时没有更好的办法
      ids.push({
        id: 'null',
        name: '',
        isonline: false
      });
    }
    setTimeout(() => {
      let media = this.confVideo();
      if (!isLY) {
        // 航美显示自己的本地视频更清晰
        this.showLocalVideo(media);
        media = this.remoteAudio(); // 播放航美会议音频
      }
      YunliRTC.createConf(isLY ? [] : ids.map((o) => o.id || o), {
        name: moment().format('YYYY-MM-DD HH:mm:ss'),
        video: true,
        media: media,
        callback: ({ id }) => {
          this.confId = id;
          this.isConfMemberVisible = true;
          this.isConfLoading = false;
          this.status = 'accepted';
          YunliRTC.removeMember('null');

          // 开始会议
          eventBus.emit('yunli-rtc-turnplate-conf', {
            id,
            caller: {
              type: 0,
              id: config.userName,
              name: config.userNick
            }
          });
          console.log('会议开始...');

          ids.forEach((o) => {
            if (o && typeof o !== 'string' && o.name) {
              // 数组成员为对象且存在name值，缓存到hash中
              this.confMemberHash[o.id] = o;
            }
            // 凌壹通过addMember邀请成员，需要处理hsUser，所以不能放到createConf中邀请，因为createConf不支持hsUser的处理
            if (isLY) {
              const hsUser = this.contactsHash[o.id || o]?.hsUser; // 查询通讯录中id对应的账号hsUser值
              YunliRTC.addMember(hsUser || o.id || o, {
                isApp: !!hsUser,
                callback: (res) => {
                  // 未入会的昵称转换处理，未入会suid跟呼叫id不是一个值，原有hash匹配不上
                  this.confMemberHash[res?.data?.suid] = this.confMemberHash[o.id || o];
                  setTimeout(() => {
                    this.getMembers();
                  }, 1000);
                }
              });
            }
          });

          options.callback && options.callback();
        }
      });
    }, 100);
  };

  // 会议邀请成员（默认打开邀请，可以设置自定义函数）
  confInvite = null;

  // 会议初始化
  confInit = () => {
    this.isCallVisible = false; // 关闭音视频通话

    this.isConfVisible = true;
    this.isConfMemberVisible = false;
    this.isConfLoading = true;
    this.isConfIncoming = false;
    this.confMemberList = [];
    // this.confMemberHash = {}; // 不用清除，可以更好的利用现有的数据进行命中
    this.showCallTime();
    this.isMute = false;
    this.isVideo = true;
    this.isMax = false;
  };

  // 会议结束
  confEnd = () => {
    const { eventBus } = config;
    if (this.sdk != 'ly') {
      // 关闭航美会议的本地视频
      this.hideLocalVideo();
    }
    if (this.isConfIncoming) {
      // 退出会议
      YunliRTC.exitConf({
        callback: (id) => {
          console.log('会议结束...');
          eventBus.emit('yunli-rtc-turnplate-conf-end', {
            id: id || this.confId
          });
          this.confMemberList = [];
          this.confId = '';
          this.status = 'default';
        }
      });
    } else {
      // 结束会议
      YunliRTC.endConf('', {
        callback: ({ id }) => {
          console.log('会议结束...');
          eventBus.emit('yunli-rtc-turnplate-conf-end', {
            id: id || this.confId,
            caller: {
              type: 0,
              id: config.userName,
              name: config.userNick
            }
          });
          this.confMemberList = [];
          this.confId = '';
          this.status = 'default';
        }
      });
    }
    this.isConfVisible = false;
    this.hideCallTime();
  };

  // 铃声播放
  playIncoming = () => {
    this.incomingAudio().src = incomingMp3;
  };

  // 铃声关闭
  stopIncoming = () => {
    if (this.incomingList.length === 0) {
      this.incomingAudio().src = '';
    }
  };

  // playTimes = 0; // 播放视频-重拨次数（对方不在线时，每3秒重试拨号，推送来电消息给app）
  // playRetryDelay = 0; // 播放视频-重拨时钟

  // 播放视频
  play = ({ name, id, type = 'video', isPlayer = true, isAnswer }) => {
    // TODO: playerId可能还没得到又被调用play
    let url;
    id = '' + id; // 兼容数值类型
    let fileType =
      id
        .toLocaleLowerCase()
        .match(/\.\w+$/g)?.[0]
        .slice(1) || '';
    if (
      id.indexOf('http') !== -1 ||
      ['mp4', 'mov', 'm4v', 'quicktime'].indexOf(fileType) !== -1 ||
      id.startsWith('ws')
    ) {
      // 播放URL视频的情况
      url = id;
    }

    let player = this.playerList[0];

    if (id && this.getPlayerIndex(null, id) !== -1 && type === player.type) {
      // 拨号已经存在时，不再重复打开播放窗口
      return;
    }

    // 只允许播放一个时，挂断上一个通话
    if (!this.isPlayerMulti && player) {
      // 先挂断上一个视频通话
      clearTimeout(player.retryDelay);
      YunliRTC.hangup({ id: player.id });
    }

    setTimeout(() => {
      // 延迟太小时，航美可能挂断的事件，会覆盖掉新拨打的事件，导致异常无法播放
      // this.playTimes = 0;

      const playerKey = 'player-' + Math.random();
      const player = {
        id: '', // 拨号session ID
        key: playerKey, // 用来控制弹窗渲染的key
        phoneNum: id, // 拨号号码
        url, // 视频URL地址
        type, // 类型（audio video）
        status: 'progress',
        title: name, // 弹窗标题
        visible: true, // 弹窗是否显示
        times: 0, // 播放视频-重拨次数（对方不在线时，每3秒重试拨号，推送来电消息给app）
        retryDelay: 0 // 播放视频-重拨时钟
      };
      if (this.isPlayerMulti) {
        // 多个同时播放
        this.playerList.push(player);
      } else {
        // 只允许播放一个
        this.playerList[0] = player;
      }
      this.playerListId++;
      const playerIndex = this.playerList.length - 1;
      if (url) {
        // 直接播放文件
        // url = 'https://vjs.zencdn.net/v/oceans.mp4';
        if (!id.startsWith('ws')) {
          // ws使用live-player，不用设置src
          this.playerUrlVideo(playerIndex).src = url;
        }
      } else {
        this.playerUrlVideo(playerIndex).src = '';
        // 音视频通话
        if (isAnswer) {
          // 接听
          YunliRTC.answer({
            video: true,
            media: this.playerVideo(playerIndex),
            callback: (res) => {
              const o = this.playerList[playerIndex];
              o.id = res.id;
            }
          });
        } else {
          // 拨打
          YunliRTC.call(id, {
            video: true,
            media: this.playerVideo(playerIndex),
            isPlayer: isPlayer,
            callback: (res) => {
              console.log('player call first...');
              const o = this.playerList[playerIndex];
              o.id = res.id;
            }
          });
        }
      }
    }, 400);
  };

  // 来电信息（接听后移出，放到callPhoneNum和callNickname中）
  // incomingPhoneNum = [];
  // incomingNickname = [];

  // 拨打信息
  callPhoneNum = ''; // 号码
  callNickname = ''; // 昵称
  callTimes = 0; // 重拨次数（对方不在线时，每3秒重试拨号，推送来电消息给app）
  callTimesMax = 20; // 最大重拨次数（默认为20，设置为0时，可以关闭拨打重试的功能）
  callRetryDelay = 0; // 重拨时钟
  setCallPhoneNum = (num) => {
    this.callPhoneNum = num;
    this.callNickname = this.getContactName(num);
  };

  // 获取某个账号的昵称
  getContactName = (num = '') => {
    const user = this.contactsHash[('' + num).replace(/^0+/g, '')]; // 忽略开头的0，拨打手机号的情况
    return user ? user.name : '未知人员';
  };

  // 下线状态更新（仅修改状态，不主动下线）
  offline = (state = '') => {
    const { eventBus } = config;
    if (this.isRegisted !== null) {
      this.isRegisted = false;
      this.stopIncoming();
      this.incomingList = [];
      console.log('yunli-rtc 退出成功！', state);
      eventBus.emit('yunli-rtc-turnplate-logout', state);
    }
  };

  // 拨打
  call = (options) => {
    const { id, name = this.callNickname || '未知人员', video = false, callback, isRecall = false } = options;
    const { eventBus } = config;
    const isLY = this.sdk === 'ly';
    if (this.isRegisted === null) {
      // 未注册不可用
      eventBus?.emit('yunli-rtc-turnplate-disabled', {
        key: 'call',
        value: options
      });
      return;
    }
    if (!this.isRegisted && !this.isAutoOnline) {
      // 注册失败，且非自动重新登录
      eventBus?.emit('yunli-rtc-turnplate-login-tips', {
        key: 'call',
        value: options
      });
      return;
    }
    if (id === config.userName) {
      message.error('不能呼叫自己');
      return;
    }
    if (this.status !== 'default' && !isRecall) {
      message.error((this.status === 'accepted' ? '通话' : '拨号') + '中，无法呼叫');
      return;
    }
    this.isCallVisible = true;
    this.isMute = false;
    this.callPhoneNum = id;
    this.callNickname = name;
    this.status = 'progress';
    if (!isRecall) {
      this.callTimes = 0;
    }

    setTimeout(() => {
      YunliRTC.call(id, {
        // media: video ? this.remoteVideo() : this.remoteAudio(),
        media: this.remoteVideo(), // 统一使用Video，否则语音切换到视频会没有画面
        video: video,
        callback: (res) => {
          this.id = res.id;

          const { eventBus } = config;
          eventBus.emit('yunli-rtc-turnplate-call', {
            id: res.id,
            status: 'progress',
            direction: 'outgoing',
            type: video ? 'video' : 'audio',
            caller: {
              type: 0,
              id: config.userName,
              name: config.userNick
            },
            call: {
              type: this.getCallType(this.callPhoneNum),
              contactsId: this.getContactsId(this.callPhoneNum),
              id: this.callPhoneNum,
              name: this.callNickname
            }
          });

          if (video) {
            if (isLY) {
              this.showLocalVideo();
            } else {
              setTimeout(() => {
                // 航美的call回调有差异，会在接通或挂断的时候才返回，而不是创建client的时候返回
                if (this.status === 'accepted') {
                  this.showLocalVideo();
                }
              }, 100);
            }
          }

          callback && callback();
        }
      });
    }, 100);
  };

  // 通话转视频会议
  callConf = (ids = []) => {
    const { eventBus } = config;

    // 不先切视频的话，会变成语音会议
    YunliRTC.callVideo({
      id: this.id
    });

    setTimeout(() => {
      // 邀请成员，变成会议
      YunliRTC.addCallMember('', {
        callback: (res) => {
          // 开始会议
          eventBus.emit('yunli-rtc-turnplate-conf', {
            id: res.id,
            caller: {
              type: 0,
              id: config.userName,
              name: config.userNick
            }
          });
          console.log('会议开始...');

          setTimeout(() => {
            // 邀请所有成员到会议中
            ids.forEach((o) => {
              YunliRTC.addMember(o);
            });

            YunliRTC.openConfVideo('', {
              media: null
            });
            this.showLocalVideo(this.confVideo());
          }, 1000);
        }
      });

      this.isConfVideo = true;
      this.isCallVisible = false;
      this.isConfVisible = true;
      this.isConfMemberVisible = true;
    }, 1000);
  };

  // 拨号盘拨打视频（允许自定义拨打视频）
  dialVideo = null;

  // 本地视频流（使用中）
  localVideoStream = null;
  // 本地视频（使用中）
  localVideoDOM = null;
  // 显示本地视频（目前只允许一个，同时多个待支持）
  showLocalVideo = async (media) => {
    this.hideLocalVideo(); // 切断上一个摄像头视频流关联，否则会一直占用摄像头

    // this.isLocalViedoFront = false;
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: this.isConfVisible && this.isPlayLocalAudio ? true : false, // 解决录制的视频会议没有调度台音频的问题，但是会产生回音的问题
        video: true
      });
      if (media) {
        // 指定元素
        media.srcObject = stream;
        this.localVideoDOM = media;
      } else {
        // 音视频通话元素
        this.localVideoDOM = this.localVideo();
        this.localVideoDOM.srcObject = stream;
        this.localVideoStream = stream;
      }
      this.localVideoStream = stream;
    } catch (e) {
      message.error(this.deviceSupportTips);
    }
  };

  // 关闭本地视频
  hideLocalVideo = () => {
    if (this.localVideoStream) {
      try {
        let tracks = this.localVideoStream?.getTracks();
        if (tracks) {
          tracks[0] && tracks[0].stop();
          tracks[1] && tracks[1].stop();
        }
      } catch (e) {
        console.log(e);
      }
      if (this.localVideoDOM) {
        this.localVideoDOM.srcObject = null;
      } else {
        this.localVideo().srcObject = null;
      }

      this.localVideoStream = null;
    }
  };

  // 挂断
  hangup = () => {
    YunliRTC.setConfig({
      callOriginator: 'local'
    });
    this.hideLocation(this.id);
    clearTimeout(this.callRetryDelay);
    if (this.id) {
      // 还没被挂断
      YunliRTC.hangup({ id: this.id });
      this.id = '';
    } else {
      // 已经被挂断
      this.status = 'default';
      this.type = '';
      // 尝试挂断未接通的特殊情况
      try {
        YunliRTC.hangup();
      } catch (e) {
        console.log(e);
      }
    }
    this.stopIncoming();
    this.hideCallTime();
    this.hideLocalVideo();
    if (this.status === 'accepted') {
      // 通话中才关闭拨号盘
      this.isCallVisible = false;
      setTimeout(() => {
        this.status = 'default';
        this.type = '';
        this.callPhoneNum = '';
        this.callNickname = '';
        this.callTitle = '';
      }, 100);
    }
  };

  // 来电挂断
  incomingHangup = (index) => {
    YunliRTC.setConfig({
      callOriginator: 'local'
    });
    const { eventBus } = config;
    // this.isIncomingVisible[index] = false;
    // setTimeout(() => {
    const incoming = this.incomingList[index];
    if (incoming) {
      if (this.sdk !== 'ly' && incoming.type === 'conf') {
        // 挂断航美会议来电
        YunliRTC.exitConf();
      } else {
        YunliRTC.hangup({ id: incoming.id });
      }
      // this.incomingId = '';
      // this.incomingId.splice(index, 1);
    }
    // this.isIncomingVisible = false;
    // this.stopIncoming();
    // setTimeout(() => {
    //   this.incomingPhoneNum.splice(index, 1);
    //   this.incomingNickname.splice(index, 1);
    // }, 100);
    eventBus.emit('yunli-rtc-incoming-hangup');
    // }, 400);
  };

  // 来电移除
  incomingRemove = (index) => {
    this.incomingList.splice(index, 1);
  };

  // 视频播放器挂断
  playerHangup = (index) => {
    YunliRTC.setConfig({
      callOriginator: 'local'
    });
    const { eventBus } = config;
    const player = this.playerList[index];
    if (player && player.id) {
      clearTimeout(player.retryDelay);
      // 挂断通话
      YunliRTC.hangup({ id: player.id });
    } else {
      // 停止播放视频
      this.playerUrlVideo(index).src = '';
    }
    this.playerRemove(index);
    eventBus.emit('yunli-rtc-turnplate-player-hangup');
  };

  // 视频播放器删除
  playerRemove = (index) => {
    // console.log(index, 'remove');
    const player = this.playerList[index];
    if (player) {
      player.visible = false;
      setTimeout(() => {
        // 延迟清除，否则没有关闭动效
        this.playerList.splice(index, 1);
      }, 500);
    }
  };

  // 将单个或多个base64转为File文件
  // base64ToFile = ({ data, fileName = `page-record-${new Date().getTime()}.mp4` }) => {
  //   let mime,
  //     u8arrs = [],
  //     u8arrMerge,
  //     u8arrMergeIndex = 0,
  //     u8arrMergeLength = 0;
  //   data.forEach((o) => {
  //     // 获取单个Uint8Array内容
  //     let arr = o.split(',');
  //     if (arr.length > 1) {
  //       mime = arr[0].match(/:(.*?);/)[1]; /*文件类型*/
  //       let bstr = atob(arr[1]);
  //       let n = bstr.length;
  //       let u8arr = new Uint8Array(n);
  //       u8arrMergeLength += n;
  //       while (n--) {
  //         u8arr[n] = bstr.charCodeAt(n);
  //       }
  //       u8arrs.push(u8arr);
  //     }
  //   });

  //   // 合并Uint8Array数组
  //   u8arrMerge = new Uint8Array(u8arrMergeLength);
  //   u8arrs.forEach((o) => {
  //     u8arrMerge.set(o, u8arrMergeIndex);
  //     u8arrMergeIndex += o.length;
  //   });

  //   return new File([u8arrMerge], fileName, { type: mime });
  // };

  // 将ObjectURL的Blob文件转为File文件
  blobToFile = ({ data, time, fileName = `page-record-${new Date().getTime()}.mp4` }) => {
    return new Promise((resolve) => {
      let xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200) {
          let myBlob = xhr.response;
          fixWebmDuration(myBlob, time, (res) => {
            resolve(new File([res], fileName));
          });
        }
      };
      xhr.open('GET', data, true);
      xhr.send();
    });
  };

  // 是否显示提示（移动端）
  isToastVisible = false;

  // 提示（移动端）
  toast = null;

  // 显示提示（移动端）
  showToast = (options) => {
    this.toast = options;
    this.isToastVisible = true;
  };

  // 隐藏提示（移动端）
  hideToast = () => {
    this.isToastVisible = false;
  };

  // 是否显示提示（移动端）
  isModalVisible = false;

  // 确认框（移动端）
  modal = null;

  // 显示确认框（移动端）
  showModal = (options) => {
    this.modal = options;
    this.isModalVisible = true;
  };

  // 隐藏确认框（移动端）
  hideModal = () => {
    this.isModalVisible = false;
  };
}

export default new VideoCallStore();
