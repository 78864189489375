import { getConfig, setConfig } from '../config';
const config = getConfig();

// 注册SIP - 航美
let client;

let callbacks = []; // 会话状态变更回调

/**
 *
 * @param {Object} options
 *  ip
 *  port
 *  businesPort
 *  userName
 *  userPsw
 *  userType <0: SIP 1: 终端 2: 调度台>
 *  path 航美无此参数
 * @returns state 1 成功 2 未注册 >=3 失败
 */
export async function regist(options) {
  client = window.XYClient?.DispatcherClient?.getInstance();
  // if (client) {
  //   try {
  //     client.enableLog(false);
  //   } catch (e) {
  //     // 兼容不同版本
  //     client.enableLog = false;
  //   }
  // }
  setConfig(options);
  setConfig({
    user: {
      // 航美会议不用登录，需要手动设置一下用户信息
      suid: config.userName
    }
  });
  const p = new Promise((resolve) => {
    let retryTimes = 30; // 最多重试30次
    const forceLogin = () => {
      // 强制登录
      retryTimes--;
      try {
        client
          .forceLogin()
          .then(() => {
            resolve(1);
            console.log('强制登录成功');
          })
          .catch((e) => {
            console.log('强制登录失败：' + e);
            if (retryTimes > 0) {
              setTimeout(forceLogin, 100);
            } else {
              resolve(6);
            }
          });
      } catch (e) {
        console.log(e);
      }
    };
    client.registStateChange.on((para) => {
      /*
        state:
          1 - 'registed'
          2 - 'unregisted'
          3 - 'connect error'
          4 - 'password error'
          5 - 'user not exist'
          6 - 'server error'
          7 - 'other error'
          8 - 'repeat login'
          9 - 'type error'
          10 - '被强制下线'
      */
      const state = para.state;
      if (state === 8 || state === 7 || state === 6 || state === 3) {
        // 重复登录，强制重新登录
        forceLogin();
      } else {
        resolve(state);
      }
      options.callback && options.callback(state);
    });
  });
  options.password = options.userPsw;
  client.regist(options);
  // 获取航美通讯录，目前没什么用，先不处理了
  // setTimeout(() => {
  //   client.getGroups().then((groups) => {
  //     console.log(groups, 'groups...');
  //   });
  //   client.getGroupDetailById('9005').then((group) => {
  //     console.log(group);
  //   });
  //   client.getGroupDetailById('9878').then((group) => {
  //     console.log(group);
  //   });
  // }, 1000);
  // console.log('注册参数：', options);

  return p;
}

/**
 * 是否已注册
 */
export function isRegisted() {
  return client.isRegisted;
}

/**
 * 取消注册
 */
export function unRegist() {
  client.unRegist();
}

/**
 * 注册事件变更监听函数
 * @param {Function} callback
 */
export async function onRegistStateChange(callback) {
  client.registStateChange.on((para) => {
    callback(para.state);
  });
}

/**
 * 来电事件监听
 */
export function onIncomingCall(callback) {
  callbacks.push(callback);
}

/**
 * 来电事件监听
 */
export function runCallbacks(res) {
  const { eventBus } = config;
  console.log('run callbacks...', res);

  eventBus.emit('yunli-rtc-call-session', {
    id: res.id
  });

  callbacks.forEach((cb) => {
    cb(res);
  });
}

/**
 * 来电视频流
 */
export async function onIncomingStream() {
  return new Promise(() => {
    // client.incomingCall.on((para) => {
    //   mylog(
    //     'incoming call number: ' +
    //       para.call.number +
    //       ', isVideo: ' +
    //       para.call.isVideo +
    //       ', calltype: ' +
    //       para.call.callType +
    //       ', otherName: ' +
    //       para.call.otherName
    //   );
    //   _incomingCall = para.call;
    //   setCallEventListener(_incomingCall);
    // });
  });
}
