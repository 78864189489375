import React, { useEffect, useState } from 'react';
import { Tree, Button, message } from 'antd';
import { inject, observer } from 'mobx-react';
import YunliRTC from '../../rtc';
import convertHTMLToText from '../../plugins/utils/convertHTMLToText';
import styles from './index.module.less';

const Contacts = ({ VideoCallStore, onOk }) => {
  const [searchValue, setSearchValue] = useState(null); // 搜索关键词
  const [searchValueCache, setSearchValueCache] = useState(null); // 搜索关键词-已提交的缓存
  const [treeData, setTreeData] = useState([]); // 通讯录树
  const [expandedKeys, setExpandedKeys] = useState([]); // 树展开的keys
  const [autoExpandParent, setAutoExpandParent] = useState(true); // 树是否自动展开父节点
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [checkedContracts, setCheckedContracts] = useState([]); // 已选中的通讯录
  const { getContactsList, searchContacts } = VideoCallStore;

  let config = YunliRTC.getConfig();
  const eventBus = config.eventBus;

  const handleSearch = () => {
    // 搜索通讯录
    setSearchValueCache(searchValue);
    if (!searchValue) {
      setExpandedKeys([]);
      setAutoExpandParent(true);
      setSelectedKeys([]);
      return;
    }

    // 之前是调用接口进行搜索，其实本地进行搜索会更好一些 by xhlv
    searchContacts({
      contactsName: searchValue
    }).then((res) => {
      const keys = [];
      (res || []).forEach((constract) => {
        if (constract?.contactsId) {
          keys.push(constract?.contactsId);
        }
      });
      setExpandedKeys(keys);
      setAutoExpandParent(true);
      setSelectedKeys(keys);
    });
  };

  const handleInputChange = (e) => {
    const searchValue = e?.target?.value;
    setSearchValue(searchValue);
    // if (!searchValue) {
    //   setExpandedKeys([]);
    //   setSelectedKeys([]);
    // }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onSelect = (selectKeysValue, e) => {
    // setSelectedKeys(selectKeysValue);
    let el = e.nativeEvent.target;
    if (el.className === 'ant-tree-title') {
      el.parentNode.previousSibling?.click();
    } else if (el.className.startsWith('index_treeTitleName')) {
      el.parentNode.parentNode.parentNode.previousSibling?.click();
    } else {
      el.parentNode.parentNode.previousSibling?.click();
    }
  };

  const call = (key, value) => {
    // 拨打电话和会议
    if (key === 'call') {
      eventBus.emit('yunli-rtc-turnplate', {
        key,
        value: value
      });
    } else if (key === 'conf') {
      let value = checkedContracts.map((checked) => {
        if (checked?.mobile) {
          return checked?.mobile;
        }
      });
      value = value.filter((phone) => {
        if (phone) return phone;
      });
      if (!value?.length) {
        message.error('请选择会议人员');
        return;
      }
      eventBus.emit('yunli-rtc-turnplate', {
        key,
        value: value
      });
      onOk && onOk();
      VideoCallStore.isContactsVisible = false;
    }
  };

  const getChildData = (parentData, childData) => {
    const oneChild = [];
    parentData.children = oneChild;

    childData.forEach((child, childIndex) => {
      const contracts = child?.contactsChildren || [];
      oneChild.push({
        title: child.contactsLabelName,
        key: child.contactsLabelId,
        selectable: true
      });

      if (contracts) {
        const twoChild = [];
        parentData.children[childIndex].children = twoChild;
        contracts.forEach((contract) => {
          twoChild.push({
            title: (
              <div className={styles.treeTitle}>
                <span
                  className={styles.treeTitleName}
                  dangerouslySetInnerHTML={{
                    __html: convertHTMLToText(contract.contactsName + '（' + contract.mobile + '）', searchValueCache)
                  }}></span>
                {/* <span className={styles.treeTitleNum}>{contract.mobile}</span> */}
                <span className={styles.treeTitleIcon}>
                  <em
                    className="ico ico-phone"
                    onClick={() => {
                      call('call', {
                        id: contract.mobile,
                        name: contract.contactsName
                      });
                    }}></em>
                  <em
                    className="ico ico-video"
                    onClick={() => {
                      call('call', {
                        id: contract.mobile,
                        name: contract.contactsName,
                        video: true
                      });
                    }}></em>
                  <em
                    className="ico ico-msg"
                    onClick={() => {
                      eventBus.emit('yunli-rtc-turnplate', {
                        key: 'control',
                        value: [
                          {
                            id: contract.mobile,
                            name: contract.contactsName
                          }
                        ]
                      });
                    }}></em>
                </span>
              </div>
            ),
            selectable: true,
            key: contract.contactsId,
            mobile: contract.mobile
          });
        });
      }
    });

    return parentData;
  };

  const getTreeData = () => {
    // 获取树数据
    const data = [];
    getContactsList().then((res) => {
      (res || []).forEach((o) => {
        let parentData = {
          title: <span className={styles.parentTitle}>{o.contactsLabelName}</span>,
          key: o.contactsLabelId,
          checkable: true,
          selectable: true,
          disabled: o.disabled
          // switcherIcon: <span className={styles.treeIcon} />
        };
        const childData = o.children;

        if (childData) {
          parentData = getChildData(parentData, o.children);
        }
        data.push(parentData);
      });

      setTreeData(data);
    });
  };

  useEffect(() => {
    getTreeData();
  }, [searchValueCache]);

  useEffect(() => {
    if (VideoCallStore.isContactsVisible) {
      getTreeData();

      // 重置已选人员
      setCheckedContracts([]);
    }
  }, [VideoCallStore.isContactsVisible]);

  return (
    <>
      <div className={styles.addressBookContainer}>
        <div className={styles.searchWrapper}>
          <input
            className={styles.inputEl}
            placeholder="请输入关键词"
            // value={searchValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <Button type="primary" className={VideoCallStore.css + 'Btn'} onClick={handleSearch}>
            搜 索
          </Button>
        </div>
        <div className={styles.treeWrapper}>
          <Tree
            className={VideoCallStore.css + 'Tree'}
            rootClassName={styles.antTree}
            height={550}
            checkable
            multiple
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            selectedKeys={selectedKeys}
            checkedKeys={checkedContracts.map((o) => o.key)}
            onSelect={onSelect}
            onExpand={onExpand}
            onCheck={(key, { checkedNodes }) => {
              setCheckedContracts(checkedNodes);
            }}
            treeData={treeData}
            // style={{ color: '#B2B2B2' }}
          />
        </div>
      </div>
      <Button
        type="primary"
        block
        className={styles.quickMeetBtn + ' ' + VideoCallStore.css + 'Btn'}
        onClick={() => call('conf')}>
        一键会议
      </Button>
    </>
  );
};

export default inject('VideoCallStore')(observer(Contacts));
