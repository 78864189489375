import { get, post } from '../xhr/fetch';
import { getConfig } from '../../rtc/config';

const config = getConfig();

export function getContactsList(data, url) {
  // 通讯录
  return get(url || config.api + '/county-unified-command-app/contacts/getAllListAsTree', data);
}

export function getEquipmentList(data, url) {
  // 设备
  return get(url || config.api + '/county-unified-command-app/equipment/getAllListAsTree', data);
}

export function getLocation(data, url) {
  // 获取通讯录地理位置
  return post(url || config.api + '/county-unified-command-app/callerLocation/locate', data);
}

export function sendTask(data, url) {
  // 人员调度发送任务
  return post(url || config.api + '/county-unified-command-app/task/sendTask', data);
}

export function sendPush(data, url) {
  // 给app推送来电消息
  return post(url || config.api + '/county-mobile-message/getuiAppMessage/pushSingleByUserId', data);
}

// export function getContactsTree(params, url) {
//   // 获取通讯录
//   return get(url || config.api + '/county-unified-command-app/contacts/getAllListAsTree', params);
// }

export function searchContactsList(data, url) {
  //  搜索通讯录
  return post(url || config.api + '/county-unified-command-app/contacts/getList', data);
}

export function getVideoUrl(data, url) {
  // 获取视频播放地址
  return post(url || '/resopp-app/resource-open-platform/api/v1/video/play', data);
}
