import React, { useState, useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Spin } from 'antd';
import Draggable from 'react-draggable';
import YunliRTC from '../../rtc';
import style from './index.module.less';

const Player = ({ VideoCallStore }) => {
  const [callTime, setCallTime] = useState(0);
  const [mute, setMute] = useState(false); // 是否静音
  const [disabled, setDisabled] = useState(false);
  const draggleRef = useRef([]);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const timer = useRef(null);

  useEffect(() => {
    return () => {
      VideoCallStore.hangup();
      // eslint-disable-next-line no-use-before-define
      initStatus();
      clearInterval(timer.current);
    };
  }, []);

  useEffect(() => {
    setCallTime(0);
    clearInterval(timer.current);
    if (VideoCallStore.status === 'accepted') {
      timer.current = setInterval(() => {
        setCallTime((t) => t + 1);
      }, 1000);
    }
  }, [VideoCallStore.status]);

  const callMuteToggle = () => {
    if (mute) {
      YunliRTC.unmute();
    } else {
      YunliRTC.mute();
    }
    setMute(!mute);
  };

  const initStatus = () => {
    VideoCallStore.status = 'default';
    setMute(false);
    clearInterval(timer.current);
  };

  const timeFormat = (data) => {
    const _data = +data;
    const h = (Math.floor(_data / 3600) + '').padStart(2, 0);
    const m = (Math.floor((_data % 3600) / 60) + '').padStart(2, 0);
    const s = (((_data % 3600) % 60) + '').padStart(2, 0);
    return h === '00' ? `${m}:${s}` : `${h}:${m}:${s}`;
  };

  const onStart = (_event, uiData, index) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current[index]?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    });
  };

  return (
    <>
      {VideoCallStore.playerList.map((o, i) => (
        <Modal
          title={o.title || '视频页面'}
          className={VideoCallStore.css + 'Dialog isMove isSmall'}
          width={0}
          key={o.key}
          mask={false}
          maskClosable={false}
          wrapClassName={VideoCallStore.css + 'DialogWrap isNoMark isBottom'}
          open={o.visible}
          getContainer={VideoCallStore.modalContainer}
          style={{ top: 0, right: 860, marginRight: 20 * i + 'px', marginBottom: 20 * i + 'px' }}
          zIndex={5000}
          footer={null}
          onOk={() => {}}
          onCancel={() => {
            VideoCallStore.playerHangup(i);
          }}
          modalRender={(modal) => (
            <Draggable disabled={disabled} bounds={bounds} onStart={(event, uiData) => onStart(event, uiData, i)}>
              <div
                ref={(el) => {
                  draggleRef.current[i] = el;
                }}
                onMouseOver={() => {
                  if (disabled) {
                    setDisabled(false);
                  }
                }}
                onMouseOut={() => {
                  setDisabled(true);
                }}
                style={{ width: '838px' }}>
                {modal}
              </div>
            </Draggable>
          )}>
          <div
            className={style.callModal + ' ' + style.isPlayer}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}>
            <div className={style.callModalMask}>
              <div className={style.callContainer}>
                <div className={style.defaultCallPhone}></div>
                <div className={style.callStatus}>
                  <div
                    className={style.callingContent}
                    style={{
                      display: 'none'
                    }}>
                    <i />
                    <span>正在呼叫…</span>
                  </div>
                  <div
                    className={style.callingContent}
                    style={{
                      display: 'none'
                    }}>
                    <i />
                    <span>通话中</span>
                  </div>
                  <div
                    className={style.onlineContent}
                    style={{
                      display: 'flex'
                    }}>
                    <div className={style.callVideoPlayer + ' ' + VideoCallStore.css + 'Border'}>
                      <div>
                        {!o.url && o.status !== 'accepted' ? <Spin size="large" /> : ''}
                        {o.type === 'audio' ? (
                          <audio
                            id={VideoCallStore.playerUrlVideoId + '-' + i}
                            autoPlay
                            controls
                            style={{ display: o.url ? '' : 'none' }}
                          />
                        ) : (
                          <live-player
                            key={o.url}
                            id={VideoCallStore.playerUrlVideoId + '-' + i}
                            video-url={o.url}
                            controls="false"
                            alt=""
                            aspect="fullscreen"
                            stretch="true"
                            vce-ready
                            autoPlay
                            muted="false"
                            style={{ display: o.url ? '' : 'none' }}
                          />
                          // <video
                          //   id={VideoCallStore.playerUrlVideoId}
                          //   autoPlay
                          //   controls
                          //   style={{ display: o.url ? '' : 'none' }}
                          // />
                        )}
                        <video
                          id={VideoCallStore.playerVideoId + '-' + i}
                          autoPlay
                          style={{
                            ...(VideoCallStore.contactsHash[o.phoneNum]?.hsUser
                              ? { transform: 'scaleY(3) translateY(50px)', height: '33.3%' }
                              : {}),
                            display: o.url ? 'none' : ''
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style.callTime}>{VideoCallStore.status === 'progress' ? '' : timeFormat(callTime)}</div>
                <div
                  className={style.callFunBox}
                  style={{
                    visibility: VideoCallStore.status === 'accepted' ? '' : 'hidden'
                  }}>
                  <span className={mute ? style.callFunMuteOn : style.callFunMuteOff} onClick={callMuteToggle}>
                    <i />
                    <label>{mute ? '取消' : ''}静音</label>
                  </span>
                </div>
                <div
                  className={style.callHangup}
                  onClick={() => {
                    VideoCallStore.playerHangup(i);
                  }}></div>
              </div>
            </div>
          </div>
        </Modal>
      ))}
    </>
  );
};

export default inject('VideoCallStore')(observer(Player));
