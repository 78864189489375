// import { runCallbacks } from './regist-hm';
// const client = window.XYClient.DispatcherClient.getInstance();

/**
 * 发送消息
 * @param {Number} number 号码
 * @param {Boolean} video 是否视频通话
 */
export async function sendMessage() {}

/**
 * 新消息
 */
export async function getMessage() {}
