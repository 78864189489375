// HTML转标红关键字文本
const convertHTMLToText = (html, keyword = '') => {
  let el = document.createElement('div');
  el.innerHTML = html;
  let text = el.innerText,
    length = text.length,
    firstPosition;
  if (keyword) {
    // 关键字标红
    if (length > 50) {
      firstPosition = text.indexOf(keyword);
      if (firstPosition > 0) {
        text = text.slice(firstPosition >= 10 ? firstPosition - 10 : 0, firstPosition + 100);
      }
    }
    text = text.replace(
      new RegExp('(' + keyword + ')', 'g'),
      '<span class="ant-typography ant-typography-danger">$1</span>'
    );
  }
  return text;
};

export default convertHTMLToText;
