import React, { useState, useEffect, useRef } from 'react';
import {
  Segmented,
  Button,
  Select,
  Modal,
  Tabs,
  Tag,
  Space,
  Typography,
  Tooltip,
  Radio,
  Form,
  Input,
  TreeSelect,
  Cascader,
  DatePicker,
  Switch,
  Table
} from 'antd';
import Draggable from 'react-draggable';
import { CloudTwoTone, DownloadOutlined, YuqueOutlined, CodeOutlined } from '@ant-design/icons';
import Prism from 'prismjs';
// import eventBus from '@/plugins/eventBus';

import { Turnplate } from '@packages/unity-platform-library';
import '../../assets/common.less';
import '../../assets/common.cyan.less';
import '../../assets/common.blue.less';
import '../../assets/common.darkblue.less';
import '../../assets/common.white.less';
// import YunliRTC, { Turnplate } from '@/plugins/YunliRTC';

import style from '../../assets/index.module.less';
import './common.module.less';

import { UIHTML } from '../../utils/const.js';

// const { Option } = Select;
const { Link } = Typography;

function UI({ history }) {
  const html = Prism.highlight(UIHTML, Prism.languages.javascript, 'javascript');
  const [theme, setTheme] = useState('Cyan');
  const [isDialogVisible, setIsDialogVisible] = useState([]);

  // 拖动
  const [disabled, setDisabled] = useState(false);
  const draggleRef = useRef([]);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });

  useEffect(() => {
    Turnplate.set({
      theme: 'Cyan'
    });
  }, []);

  const onStart = (_event, uiData, index) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current[index]?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    });
  };

  return (
    <>
      <div className={style.app}>
        <h3>
          <CloudTwoTone style={{ fontSize: 34, position: 'relative', top: '6px' }} /> YunliRTC
        </h3>
        <div className={style.tabs}>
          <Segmented
            block
            defaultValue="样式"
            onChange={(value) => {
              setTimeout(() => {
                let url;
                if (value === '组件') {
                  url = '/element';
                } else if (value === '样式') {
                  url = '/ui';
                } else {
                  url = '/';
                }
                history.push(url);
                document.location.reload();
              }, 400);
            }}
            options={['API', '组件', '样式']}
          />
        </div>
        <div className={style.link}>
          <Tooltip title="npm i yunli-rtc">
            <Link
              onClick={() => {
                Modal.info({
                  title: '下载最新版本',
                  content: <div>npm i yunli-rtc</div>
                });
              }}>
              <DownloadOutlined /> 下载最新版本
            </Link>
          </Tooltip>
          <Tooltip title="示例代码 ui.js">
            <Link href="ui.js" download>
              <CodeOutlined /> 示例代码
            </Link>
          </Tooltip>
          <Tooltip title="YunliRTC音视频通话API和组件">
            <Link href="https://yunlizhihui.yuque.com/bgr0hu/gly4e7/wngvte" rel="noreferrer" target="_blank">
              <YuqueOutlined /> 语雀
            </Link>
          </Tooltip>
        </div>
        <div className={style.code}>
          <pre className="language-css">
            <code className="language-css" dangerouslySetInnerHTML={{ __html: html }}></code>
          </pre>
        </div>
        <h3>主题设置</h3>
        <ul>
          <li>
            <Radio.Group
              options={[
                { label: '青色 - Cyan', value: 'Cyan' },
                { label: '蓝色 - Blue', value: 'Blue' },
                { label: '深蓝色 - DarkBlue', value: 'DarkBlue' },
                { label: '白色 - White', value: 'White' }
              ]}
              onChange={({ target: { value } }) => {
                Turnplate.set({
                  theme: value
                });
                setTheme(value);
              }}
              value={theme}
              optionType="button"
            />
          </li>
        </ul>
        <div className="main_con clearfix api">
          <h2>按钮</h2>
          <ul>
            <li>
              <Button className="commonBtn">取消</Button>
              <Button type="primary" className="commonBtn">
                确定
              </Button>
              <Button type="primary" danger className="commonBtn">
                结束
              </Button>
            </li>
          </ul>
          <h2>文本</h2>
          <ul>
            <li>
              <Link>链接</Link>
              <br />
              <Link disabled>不可用链接</Link>
              <div className="c-success">成功</div>
              <div className="c-warning">警告</div>
              <div className="c-danger">失败</div>
              <div className="c-white">白色</div>
              <div className="c-text">正文</div>
              <div className="c-secondary">次文</div>
              <div className="c-week">弱文</div>
              <div className="c-light">浅文</div>
              <div className="f-left">居左</div>
              <div className="f-center">居中</div>
              <div className="f-right">居右</div>
              <div className="f-small">小号字体</div>
              <div>普通字体</div>
              <div className="f-big">大号字体</div>
              <div className="f-large">超大字体</div>
              <div className="f-bold">加粗字体</div>
            </li>
          </ul>
          <h2>图标</h2>
          <ul>
            <li>通用图标：</li>
            <li className="cont">
              <em className="ico ico-arrow-down"></em>
              <em className="ico ico-remove"></em>
              <em className="ico ico-del"></em>
              <em className="ico ico-close"></em>
              <em className="ico ico-close-white"></em>
              <em className="ico ico-add-white"></em>
              <em className="ico ico-sort"></em>
              <em className="ico ico-turnplate1"></em>
              <em className="ico ico-turnplate2"></em>
              <em className="ico ico-turnplate3"></em>
              <em className="ico ico-turnplate4"></em>
              <em className="ico ico-turnplate5"></em>
              <em className="ico ico-turnplate6"></em>
              <em className="ico ico-phone"></em>
              <em className="ico ico-msg"></em>
              <em className="ico ico-video"></em>
              <em className="ico ico-call-mute"></em>
              <em className="ico ico-call-unmute"></em>
              <em className="ico ico-call-video-close"></em>
              <em className="ico ico-call-video-open"></em>
              <em className="ico ico-call-member"></em>
              <em className="ico ico-call-to-audio"></em>
              <em className="ico ico-call-to-video"></em>
              <em className="ico ico-call-invite"></em>
              <em className="ico ico-event-edit"></em>
              <em className="ico ico-event-edit-done"></em>
              <em className="ico ico-event-link"></em>
              <em className="ico ico-event-link-done"></em>
              <em className="ico ico-record-audio"></em>
              <em className="ico ico-record-video"></em>
              <em className="ico ico-call-miss-ignore"></em>
              <em className="ico ico-max"></em>
              <em className="ico ico-min"></em>
              <em className="ico ico-camera"></em>
              <em className="ico ico-drill"></em>
            </li>
            <li className="cont">
              <em className="ico ico-call-audio"></em>
              <em className="ico ico-call-video"></em>
              <em className="ico ico-call-hangup"></em>
              <em className="ico ico-call-btn"></em>
              <em className="ico ico-call-btn-on"></em>
              <em className="ico ico-call-btn-del"></em>
              <em className="ico ico-call-btn-del-on"></em>
              <em className="ico ico-call-miss-audio"></em>
              <em className="ico ico-call-miss-video"></em>
              <em className="ico ico-call-miss-msg"></em>
              <em className="ico ico-call-miss"></em>
            </li>
            <li className="cont">
              <em className="ico ico-call-min-audio"></em>
              <em className="ico ico-call-min-video"></em>
            </li>
            <li>大小设置：</li>
            <li className="cont">
              <em className="ico ico-call-audio"></em>
              <em className="ico ico-call-audio ico-32"></em>
              <em className="ico ico-call-audio ico-16"></em>
              <em className="ico ico-call-audio ico-12"></em>
              <em className="ico ico-call-audio ico-10"></em>
            </li>
          </ul>
          <h2>对话框</h2>
          <ul>
            <li>
              <Button
                type="primary"
                className="commonBtn"
                onClick={() => {
                  setIsDialogVisible([true]);
                }}>
                大
              </Button>
              <Modal
                title="标题"
                centered
                className="commonDialog"
                width={1000}
                open={isDialogVisible[0]}
                okButtonProps={{ className: 'commonBtn' }}
                onOk={() => {
                  setIsDialogVisible([]);
                }}
                cancelButtonProps={{ className: 'commonBtn' }}
                onCancel={() => {
                  setIsDialogVisible([]);
                }}>
                <div className="c-white f-center f-big" style={{ height: '600px' }}>
                  内容
                </div>
              </Modal>
              <Button
                type="primary"
                className="commonBtn"
                onClick={() => {
                  setIsDialogVisible(['', true]);
                }}>
                中
              </Button>
              <Modal
                title="标题"
                centered
                className="commonDialog isSmall"
                width={600}
                open={isDialogVisible[1]}
                okButtonProps={{ className: 'commonBtn' }}
                onOk={() => {
                  setIsDialogVisible([]);
                }}
                cancelButtonProps={{ className: 'commonBtn' }}
                onCancel={() => {
                  setIsDialogVisible([]);
                }}>
                <div className="c-white f-center f-big" style={{ height: '300px' }}>
                  内容
                </div>
              </Modal>
              <Button
                type="primary"
                className="commonBtn"
                onClick={() => {
                  setIsDialogVisible(['', '', true]);
                }}>
                小
              </Button>
              <Modal
                title="标题"
                centered
                className="commonDialog isSmall"
                width={300}
                open={isDialogVisible[2]}
                okButtonProps={{ className: 'commonBtn' }}
                onOk={() => {
                  setIsDialogVisible([]);
                }}
                cancelButtonProps={{ className: 'commonBtn' }}
                onCancel={() => {
                  setIsDialogVisible([]);
                }}>
                <div className="c-white f-center f-big" style={{ height: '100px' }}>
                  内容
                </div>
              </Modal>
            </li>
            <li>
              <Button
                type="primary"
                className="commonBtn"
                onClick={() => {
                  setIsDialogVisible(['', '', '', true]);
                }}>
                最大化
              </Button>
              <Modal
                title="标题"
                centered
                className="commonDialog isSmall"
                width={600}
                open={isDialogVisible[3]}
                okButtonProps={{ className: 'commonBtn' }}
                onOk={() => {
                  setIsDialogVisible([]);
                }}
                cancelButtonProps={{ className: 'commonBtn' }}
                onCancel={() => {
                  setIsDialogVisible([]);
                }}>
                <div className="c-white f-center f-big" style={{ height: '300px' }}>
                  内容
                </div>
              </Modal>
              <Button
                type="primary"
                className="commonBtn"
                onClick={() => {
                  setIsDialogVisible(['', '', '', '', true]);
                }}>
                最小化
              </Button>
              <Modal
                title="标题"
                centered
                className="commonDialog isSmall"
                width={600}
                open={isDialogVisible[4]}
                okButtonProps={{ className: 'commonBtn' }}
                onOk={() => {
                  setIsDialogVisible([]);
                }}
                cancelButtonProps={{ className: 'commonBtn' }}
                onCancel={() => {
                  setIsDialogVisible([]);
                }}>
                <div className="c-white f-center f-big" style={{ height: '300px' }}>
                  内容
                </div>
              </Modal>
              <Button
                type="primary"
                className="commonBtn"
                onClick={() => {
                  setIsDialogVisible(['', '', '', '', '', true]);
                }}>
                可拖动
              </Button>
              <Modal
                title="标题"
                centered
                className="commonDialog isSmall isMove"
                width={600}
                open={isDialogVisible[5]}
                okButtonProps={{ className: 'commonBtn' }}
                onOk={() => {
                  setIsDialogVisible([]);
                }}
                cancelButtonProps={{ className: 'commonBtn' }}
                onCancel={() => {
                  setIsDialogVisible([]);
                }}
                modalRender={(modal) => (
                  <Draggable disabled={disabled} bounds={bounds} onStart={(event, uiData) => onStart(event, uiData, 0)}>
                    <div
                      ref={(el) => {
                        draggleRef.current[0] = el;
                      }}
                      onMouseOver={() => {
                        if (disabled) {
                          setDisabled(false);
                        }
                      }}
                      onMouseOut={() => {
                        setDisabled(true);
                      }}>
                      {modal}
                    </div>
                  </Draggable>
                )}>
                <div className="c-white f-center f-big" style={{ height: '300px' }}>
                  内容
                </div>
              </Modal>
              <Button
                type="primary"
                className="commonBtn"
                onClick={() => {
                  setIsDialogVisible(['', '', '', '', '', '', true]);
                }}>
                无遮挡
              </Button>
              <Modal
                title="标题"
                centered
                className="commonDialog isSmall"
                wrapClassName="commonDialogWrap isNoMark"
                width={600}
                style={{
                  left: 'calc(150vw - 300px)'
                }}
                mask={false}
                maskClosable={false}
                open={isDialogVisible[6]}
                okButtonProps={{ className: 'commonBtn' }}
                onOk={() => {
                  setIsDialogVisible([]);
                }}
                cancelButtonProps={{ className: 'commonBtn' }}
                onCancel={() => {
                  setIsDialogVisible([]);
                }}>
                <div className="c-white f-center f-big" style={{ height: '300px' }}>
                  内容
                </div>
              </Modal>
            </li>
          </ul>
          <h2>页卡</h2>
          <ul>
            <li>
              <Tabs
                className="commonTabs"
                defaultActiveKey="1"
                items={[
                  {
                    key: '1',
                    label: `通讯录`,
                    children: `Content of Tab Pane 1`
                  },
                  {
                    key: '2',
                    label: `设备`,
                    children: `Content of Tab Pane 2`
                  }
                ]}
              />
            </li>
            <li>
              <Tabs
                className="commonTabs"
                defaultActiveKey="1"
                type="card"
                tabPosition={'left'}
                items={[
                  {
                    key: '1',
                    label: `通讯录`,
                    children: `Content of Tab Pane 1`
                  },
                  {
                    key: '2',
                    label: `设备`,
                    children: `Content of Tab Pane 2`
                  }
                ]}
              />
            </li>
          </ul>
          <h2>表格</h2>
          <ul>
            <li>
              大表格：
              <Table
                className="commonTable"
                columns={[
                  {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text) => <a>{text}</a>
                  },
                  {
                    title: 'Age',
                    dataIndex: 'age',
                    key: 'age'
                  },
                  {
                    title: 'Address',
                    dataIndex: 'address',
                    key: 'address'
                  },
                  {
                    title: 'Tags',
                    key: 'tags',
                    dataIndex: 'tags',
                    render: (_, { tags }) => (
                      <>
                        {tags.map((tag) => {
                          let color = tag.length > 5 ? 'geekblue' : 'green';
                          if (tag === 'loser') {
                            color = 'volcano';
                          }
                          return (
                            <Tag color={color} key={tag}>
                              {tag.toUpperCase()}
                            </Tag>
                          );
                        })}
                      </>
                    )
                  },
                  {
                    title: 'Action',
                    key: 'action',
                    render: (_, record) => (
                      <Space size="middle">
                        <a>Invite {record.name}</a>
                        <a>Delete</a>
                      </Space>
                    )
                  }
                ]}
                dataSource={[
                  {
                    key: '1',
                    name: 'John Brown',
                    age: 32,
                    address: 'New York No. 1 Lake Park',
                    tags: ['nice', 'developer']
                  },
                  {
                    key: '2',
                    name: 'Jim Green',
                    age: 42,
                    address: 'London No. 1 Lake Park',
                    tags: ['loser']
                  },
                  {
                    key: '3',
                    name: 'Joe Black',
                    age: 32,
                    address: 'Sydney No. 1 Lake Park',
                    tags: ['cool', 'teacher']
                  }
                ]}
              />
              小表格：
              <Table
                className="commonTable isSmall"
                columns={[
                  {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text) => <a>{text}</a>
                  },
                  {
                    title: 'Age',
                    dataIndex: 'age',
                    key: 'age'
                  },
                  {
                    title: 'Address',
                    dataIndex: 'address',
                    key: 'address'
                  },
                  {
                    title: 'Tags',
                    key: 'tags',
                    dataIndex: 'tags',
                    render: (_, { tags }) => (
                      <>
                        {tags.map((tag) => {
                          let color = tag.length > 5 ? 'geekblue' : 'green';
                          if (tag === 'loser') {
                            color = 'volcano';
                          }
                          return (
                            <Tag color={color} key={tag}>
                              {tag.toUpperCase()}
                            </Tag>
                          );
                        })}
                      </>
                    )
                  },
                  {
                    title: 'Action',
                    key: 'action',
                    render: (_, record) => (
                      <Space size="middle">
                        <a>Invite {record.name}</a>
                        <a>Delete</a>
                      </Space>
                    )
                  }
                ]}
                dataSource={[
                  {
                    key: '1',
                    name: 'John Brown',
                    age: 32,
                    address: 'New York No. 1 Lake Park',
                    tags: ['nice', 'developer']
                  },
                  {
                    key: '2',
                    name: 'Jim Green',
                    age: 42,
                    address: 'London No. 1 Lake Park',
                    tags: ['loser']
                  },
                  {
                    key: '3',
                    name: 'Joe Black',
                    age: 32,
                    address: 'Sydney No. 1 Lake Park',
                    tags: ['cool', 'teacher']
                  }
                ]}
              />
            </li>
          </ul>
          <h2>表单</h2>
          <ul>
            <li>
              <Form
                className="commonForm"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                initialValues={{ size: 'default' }}
                size={'default'}
                style={{ maxWidth: 600 }}>
                <Form.Item label="Form Size" name="size">
                  <Radio.Group>
                    <Radio.Button value="small">Small</Radio.Button>
                    <Radio.Button value="default">Default</Radio.Button>
                    <Radio.Button value="large">Large</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Input">
                  <Input />
                </Form.Item>
                <Form.Item label="Select">
                  <Select>
                    <Select.Option value="demo">Demo</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item label="TreeSelect">
                  <TreeSelect
                    treeData={[{ title: 'Light', value: 'light', children: [{ title: 'Bamboo', value: 'bamboo' }] }]}
                  />
                </Form.Item>
                <Form.Item label="Cascader">
                  <Cascader
                    options={[
                      {
                        value: 'zhejiang',
                        label: 'Zhejiang',
                        children: [{ value: 'hangzhou', label: 'Hangzhou' }]
                      }
                    ]}
                  />
                </Form.Item>
                <Form.Item label="DatePicker">
                  <DatePicker />
                </Form.Item>
                <Form.Item label="Switch" valuePropName="checked">
                  <Switch />
                </Form.Item>
                <Form.Item label="Button">
                  <Button>Button</Button>
                </Form.Item>
              </Form>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default UI;
