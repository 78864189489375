import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { ConfigProvider } from 'antd';
// import zhCN from 'antd/locale/zh_CN';
import 'moment/locale/zh-cn';
import { routers } from '@/routes';
import stores from './stores';

import './index.less';

function App() {
  return (
    <Provider {...stores}>
      <ConfigProvider>
        <HashRouter>
          <Switch>
            {routers.map((route) => {
              return <Route key={route.key} {...route} />;
            })}
          </Switch>
        </HashRouter>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
