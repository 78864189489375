import axios from 'axios';
import { notification } from 'antd';
import Cookies from 'js-cookie';
// import { noAuthRedirect } from '../../plugins/utils/auth';
import YunliRTC from './index';
import qs from 'qs';
const errorTips = '系统繁忙，请稍后再试';

/**
 * 创建xhr实例
 * 路径前缀
 * 超时失败时间
 */
const service = axios.create({
  timeout: 50000,
  async: true,
  crossDomain: true,
  withCredentials: true
});

/**
 * @desc 设置服务请求拦截器
 * 定义token请求设置
 */
service.interceptors.request.use(
  (config) => {
    let { data, params = {} } = config;
    if ((data && data.mock) || params.mock) {
      config.baseURL = '/rap2/mock';
    }
    config.headers.token = Cookies.get('token');
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/**
 * @desc 设置服务响应拦截器
 * 截取返回状态 统一定义成功失败
 */
service.interceptors.response.use(
  async (response) => {
    const { isCustomException = false } = response.config;
    const data = response.data;
    const type = Object.prototype.toString.call(data);

    if (type === '[object Object]' || type === '[object Array]' || type === '[object String]') {
      // 兼容凌壹后台直接返回数组或对象数据，以及没有状态码的情况
      let data2;
      if (data.result === 1 || data.result === 'success' || data.ddtinfo) {
        data.code = '200';
        data.isSuccess = true;
      }
      if (data.result === '用户已登录') {
        // 先去掉提示，即使登录失败也不影响通话功能
        data.code = '200';
        data.msg = '';
        // data.msg = '登录失败，请退出该账号的其他终端';
      }
      if (data.code === '80011') {
        // 去掉结束会议失败的错误提示，解决快速关闭会异常时会有错误提示的问题
        data.code = '200';
        data.msg = '';
      }
      if (data.msg === '无效的令牌!') {
        // 登录态失效，强制重新登录
        if (response.config.__retry__) {
          response.config.__retry__--;
          if (response.config.__retry__ >= 0) {
            let res = await YunliRTC.login({
              isForce: true
            });
            let data = JSON.parse(response.config.data);
            data.TOKEN = res.TOKEN; // 设置新的token再重试
            response.config.data = JSON.stringify(data);
            return service(response.config);
          }
        }
      }
      if (type === '[object Array]' && !data.code) {
        // 即时消息等
        data2 = {
          code: '200',
          isSuccess: true,
          data: data
        };
      }
      if (type === '[object String]' && data === 'success') {
        // 对讲等
        data2 = {
          code: '200',
          isSuccess: true,
          data: data
        };
      }
      if (data.id) {
        // 兼容直接返回id对象的情况，凌壹后台
        data.code = '200';
        data.isSuccess = true;
        data.data = data.id;
      }

      const { code, status, message, msg } = data2 || data;

      if (code == '200' || (status === 'success' && response.config.url.indexOf('config/get_auth_client') !== -1)) {
        return Promise.resolve(data2 || data);
      } else {
        if (!isCustomException) {
          // 如果走自定义异常提示
          notification.error({
            message: '提示',
            description: msg || message || errorTips
          });
        }
        return Promise.reject(data);
      }
    } else {
      return Promise.reject(data);
    }
  },
  (error) => {
    if (error?.response?.status === '401' || error?.response?.status === '405') {
      // return noAuthRedirect();
    }
    notification.error({
      message: '提示',
      description: error.response.data.message || errorTips
    });
    return Promise.reject(error);
  }
);

function formatParam(param = {}) {
  if (Object.prototype.toString.call(param) === '[object URLSearchParams]') {
    return param;
  }
  Object.keys(param).forEach((key) => {
    if (param[key] === undefined || param[key] === null) {
      param[key] = '';
    }
  });
  return param;
}

const strategy = {
  postform(param) {
    param = formatParam(param);
    const config = {};
    config.method = 'post';
    config.data = qs.stringify(param);
    return config;
  },
  post(param) {
    param = formatParam(param);
    const config = {};
    config.method = 'post';
    config.data = param;
    if (param.ContentType) {
      config.headers = {
        'Content-Type': param.ContentType
      };
      delete param.ContentType;
    }
    return config;
  },
  get(param) {
    param = formatParam(param);
    const config = {};
    config.method = 'get';
    config.params = param;
    return config;
  },
  put(param) {
    param = formatParam(param);
    const config = {};
    config.method = 'put';
    config.data = param;
    return config;
  },
  delete(param) {
    param = formatParam(param);
    const config = {};
    config.method = 'delete';
    config.params = param;
    return config;
  },
  patch(param) {
    param = formatParam(param);
    const config = {};
    config.method = 'patch';
    config.data = param;
    return config;
  },
  download(param) {
    param = formatParam(param);
    const config = {
      responseType: 'arraybuffer'
    };
    config.method = 'get';
    config.data = param;
    return config;
  }
};

/**
 * 封装get请求
 * @param {*} url
 * @param {*} params
 * @param otherParams object
 */
const get = (url, params = {}, otherParams = {}) => {
  let _config = {
    url: url,
    method: 'get',
    ...otherParams
  };
  if (params.hasOwnProperty.call('data') && params.hasOwnProperty.call('json')) {
    _config['data'] = params.data;
  } else {
    _config['params'] = params;
  }
  return service(_config).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    }
  );
};

const post = (url, data, config = {}) => {
  if (config) {
    return service({
      url,
      __retry__: config.retry || 0, // 重试
      method: 'POST',
      data,
      config
    }).then(
      (res) => {
        return res;
      },
      (error) => {
        return error;
      }
    );
  }
};

const postForm = (url, data, config = {}) => {
  if (config) {
    config = strategy['postform'](data);
    return service({
      url,
      ...config
    }).then(
      (res) => {
        return res;
      },
      (error) => {
        return error;
      }
    );
  }
};

const _delete = (url, config = {}) => {
  return service
    .delete(url, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

const patch = (url, config = {}) => {
  return service
    .patch(url, config)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export { get, post, postForm, _delete, patch };
