import { setConfig, getConfig } from './config';
import { LOCALSDKKEY } from './const';

/*
  先信任以下地址，才能使用。

  凌壹：
    https://www.t01md.com.cn:15675

  航美：
    https://42.236.120.104:7443
    https://42.236.120.104:60002
*/

import * as registLY from './ly/regist';
import * as callLY from './ly/call';
import * as messageLY from './ly/message';
import * as talkLY from './ly/talk';
import * as broadcastLY from './ly/broadcast';
import * as confLY from './ly/conf';

import * as registHM from './hm/regist';
import * as callHM from './hm/call';
import * as messageHM from './hm/message';
import * as talkHM from './hm/talk';
import * as broadcastHM from './hm/broadcast';
import * as confHM from './hm/conf';

// 凌壹
const LY = {
  ...registLY,
  ...callLY,
  ...messageLY,
  ...talkLY,
  ...broadcastLY,
  ...confLY
};

// 航美
const HM = {
  ...registHM,
  ...callHM,
  ...messageHM,
  ...talkHM,
  ...broadcastHM,
  ...confHM
};

let YunliRTC;

function getSDK() {
  return localStorage.getItem(LOCALSDKKEY) || 'ly';
}

function setSDK(type) {
  Object.assign(YunliRTC, {
    ...(type === 'ly' ? LY : HM)
  });
  return localStorage.setItem(LOCALSDKKEY, type);
}

YunliRTC = {
  ...(getSDK() === 'ly' ? LY : HM),

  getSDK, // 获取SDK类型
  setSDK, // 设置SDK类型
  setConfig, // 设置配置文件
  getConfig // 配置获取文件

  // regist, // 注册
  // unRegist, // 取消注册
  // logout, // 退出登录
  // onRegistStateChange, // 注册状态变化回调

  // onIncomingCall, // 来电回调
  // onIncomingStream, // 来电视频流
  // call, // 拨打
  // answer, // 接听
  // hangup, // 挂断
  // mute, // 静音
  // unmute, // 取消静音
  // isMuted, // 是否静音

  // sendMessage, // 发送消息
  // getMessages, // 历史消息
  // getMessage, // 开启接收新消息

  // startTalk, // 开始对讲
  // endTalk, // 结束对讲
  // createTalk, // 创建对讲
  // removeTalk, // 删除对讲
  // getTalks, // 对讲列表

  // startBroadcast, // 开始广播
  // endBroadcast, // 结束广播
  // createBroadcast, // 创建广播
  // removeBroadcast, // 删除广播
  // getBroadcasts, // 广播列表

  // createConf, // 创建会议
  // removeConf, // 删除会议
  // getConf, // 获取会议列表
  // callConf, // 拨打会议
  // muteConf, // 会议-静音
  // unmuteConf, // 会议-取消静音
  // focusConfVideo, // 会议-设置主画面
  // unfocusConfVideo, // 会议-取消设置主画面
  // openConfVideo, // 会议-打开摄像头
  // closeConfVideo, // 会议-关闭摄像头
  // openMemberVideo, // 打开成员视频
  // endConf, // 结束会议
  // getMembers, // 获取成员列表
  // addMember, // 添加成员
  // removeMember, // 挂断成员
  // onConfChange // 会议状态变化回调
};

export default YunliRTC;
