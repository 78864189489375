import { runCallbacks } from './regist';
import { getConfig, setConfig } from '../config';
import { createConf, addMember } from './conf.js';
import { message } from 'antd';
import checkDeviceSupport from '../../plugins/utils/checkDeviceSupport';
const client = window.XYClient?.DispatcherClient?.getInstance();

const config = getConfig();

let currentCalls = {}; // 通话实例，按id索引存储和查找
let currentCall; // 备份最后一个通话实例，容错
let currentConference;
let answerMediaControl;
let becomeConfCallback; // 通话转会议的回调

// 保存通话实例
function setCurrentCall(id, call) {
  currentCalls[id] = call;
  currentCall = call;
}

// 获取通话实例
function getCurrentCall(id) {
  return currentCalls[id] || currentCall;
}

// 接听 / 来电 振铃 / 失败 / 挂断
// ['accepted', 'progress', 'failed', 'ended']

function convertCallstate(state) {
  var stateStr;
  switch (state) {
    case 1:
      stateStr = 'init';
      break;
    case 2:
      stateStr = 'normal';
      break;
    case 3:
      stateStr = 'callout';
      break;
    case 4:
      stateStr = 'incoming';
      break;
    case 5:
      // stateStr = 'ringing';
      stateStr = 'progress'; // 振铃
      break;
    case 6:
      // stateStr = 'connected';
      stateStr = 'accepted'; // 接听
      break;
    case 7:
      // stateStr = 'terminal';
      stateStr = 'ended'; // 挂断
      break;
    case 8:
      stateStr = 'hold';
      break;
    case 9:
      stateStr = 'unhold';
      break;
    default:
      break;
  }
  return stateStr;
}

function setCallEventListener(id, video, callback) {
  const { eventBus } = config;
  // console.log('航美单呼：', currentCall);
  if (callback) {
    eventBus.once('yunli-rtc-call-session', function (data) {
      callback && callback(data);
    });
  }
  let call = getCurrentCall(id);
  setConfig({
    callState: call.state
  });
  call.callStateChange.on((param) => {
    const status = convertCallstate(param.state);
    let from, to;
    if (param.sender.isCaller) {
      // 呼出
      from = config.userName;
      to = param.sender.number;
    } else {
      // 呼入
      from = param.sender.number;
      to = config.userName;
    }

    // 接听的来电不知道为什么会重复触发两次（拨出没问题），特殊处理，保证相同状态只执行一次
    if (config.callState != param.state) {
      console.log('航美状态：', status, param, config.callOriginator, call);
      setConfig({
        callState: param.state
      });
      runCallbacks({
        id: param.sender._sessionId,
        status,
        type: call._isVideo ? 'video' : 'audio', // 已call的类型为准，可能是语音转视频的通话，先audio后video
        direction: param.sender.isCaller ? 'outgoing' : 'incoming', // 外拨
        from,
        to,
        originator: config.callOriginator === 'local' ? 'local' : 'remote', // 无法区分动作是否来源于对方，统一按手动设置的callOriginator来处理
        cause: '已挂断' // 处理失败等状态原因，暂时没有cause原因内容，统一按已挂断来处理
      });

      setConfig({
        callOriginator: ''
      });
    }
  });

  call.onBecomeConference.on((param) => {
    console.log('当前单呼变成会议', param);
    currentConference = param.changeToConference;
    createConf('', {
      target: currentConference,
      callback: (res) => {
        if (becomeConfCallback) {
          becomeConfCallback(res);
          becomeConfCallback = null;
        }
      }
    });
  });
}

client?.incomingCall?.on((param) => {
  const config = getConfig();
  // console.log(
  //   'incoming call number: ' +
  //     param.call.number +
  //     ', video: ' +
  //     param.call.video +
  //     ', calltype: ' +
  //     param.call.callType +
  //     ', otherName: ' +
  //     param.call.otherName,
  //   param
  // );
  console.log('incomingCall...', param);
  setCurrentCall(param.call._sessionId, param.call);
  setCallEventListener(param.call._sessionId, param.call._isVideo);

  let from, to;
  if (param.call.isCaller) {
    // 呼出
    from = config.userName;
    to = param.call.number;
  } else {
    // 呼入
    from = param.call.number;
    to = config.userName;
  }

  // 手动触发振铃事件
  runCallbacks({
    id: param.call._sessionId,
    status: 'progress',
    type: param.call._isVideo ? 'video' : 'audio',
    direction: param.call.isCaller ? 'outgoing' : 'incoming', // 来电
    from,
    to,
    originator: config.callOriginator === 'local' ? 'local' : 'remote', // 无法区分动作是否来源于对方，统一按手动设置的callOriginator来处理
    cause: '已挂断' // 处理失败等状态原因，暂时没有cause原因内容，统一按已挂断来处理
  });

  setConfig({
    callOriginator: ''
  });
});

client?.handleAnswerIncomingCall?.on((call) => {
  // console.log(
  //   'handleAnswerIncomingCall call number: ' + call.number + ', video: ' + call.video + ', callType: ' + call.callType
  // );
  setCurrentCall(call._sessionId, call);
  setCallEventListener(call._sessionId, call._isVideo);
  if (call._isVideo) {
    call.hangdleRequestVideo(answerMediaControl);
  }
});

/**
 * 拨打
 * @param {Number} number 号码
 * @param {Boolean} video 是否视频通话
 */
export async function call(number, { video = true, media, callback }) {
  const deviceSupport = await checkDeviceSupport();
  if (!deviceSupport?.hasMicrophone && !deviceSupport?.hasWebcam) {
    message.error('未检测到摄像头和麦克风设备，部分功能无法正常使用');
  }
  if (!deviceSupport?.isMicrophoneAlreadyCaptured || !deviceSupport?.isWebcamAlreadyCaptured) {
    message.error('请检查浏览器是否打开摄像头和麦克风权限');
    if (navigator.getUserMedia) {
      navigator.getUserMedia(
        { video: true, audio: true },
        function onSuccess(stream) {
          console.log('开启成功');
          try {
            let tracks = stream.getTracks();
            tracks[0].stop();
            tracks[1].stop();
          } catch (e) {
            console.log(e);
          }
        },
        function onError(error) {
          console.log(error);
        }
      );
    }
  }
  try {
    const call = await client.makeCall(number, media, video);
    setCurrentCall(call._sessionId, call);
    setCallEventListener(call._sessionId, video, callback);
  } catch (error) {
    console.log('拨打失败...', error);
    // 执行回调，否则无法结束拨打，延迟执行让用户能看到拨打的过程
    setTimeout(() => {
      runCallbacks({
        id: 'error',
        status: 'ended',
        type: video ? 'video' : 'audio',
        direction: 'outgoing', // 外拨
        from: config.userName,
        to: number,
        originator: config.callOriginator === 'local' ? 'local' : 'remote', // 无法区分动作是否来源于对方，统一按手动设置的callOriginator来处理
        cause: '已挂断' // 处理失败等状态原因，暂时没有cause原因内容，统一按已挂断来处理
      });

      setConfig({
        callOriginator: ''
      });
    }, 1000);
  }
}

/**
 * 接听
 * @param {HTMLVideoElement} media 接收的媒体
 * @param {Boolean} video 是否视频通话
 */
export function answer({ id, media, video = true, callback }) {
  const call = getCurrentCall(id);
  answerMediaControl = media;
  call.answer(media, true);
  console.log(video);
  setCallEventListener(id, true, callback);
}

// 添加成员
export function addCallMember(member, options = {}) {
  const { callback } = options;
  becomeConfCallback = callback; // 设置通话转会议的回调
  const call = getCurrentCall();
  call.addMember(member); // 添加成员后，将转成会议

  // 再邀请一遍成员，call.addMember没有拉人
  setTimeout(() => {
    addMember(member);
  }, 500);
}

/**
 * 挂断
 */
export function hangup(options = {}) {
  const { id } = options;
  const call = getCurrentCall(id);
  currentConference ? currentConference.end() : call?.hangup();
}

/**
 * 静音
 */
export function mute(options = {}) {
  const { id } = options;
  const call = getCurrentCall(id);
  call.muteAudio();
}

/**
 * 解除静音
 */
export function unmute(options = {}) {
  const { id } = options;
  const call = getCurrentCall(id);
  call.unmuteAudio();
}

/**
 * 关闭摄像头
 */
export function muteVideo(options = {}) {
  const { id } = options;
  const call = getCurrentCall(id);
  call.muteVideo();
}

/**
 * 打开摄像头
 */
export function unmuteVideo(options = {}) {
  const { id } = options;
  const call = getCurrentCall(id);
  call.unmuteVideo();
}

/**
 * 是否静音（航美不支持，禁止调用）
 */
export function isMuted() {
  // const call = getCurrentCall(id);
  return false;
}

/**
 * 保持（对方只能听见保持状态的音乐，不挂断）
 */
export function hold(options = {}) {
  const { id } = options;
  const call = getCurrentCall(id);
  call.hold();
}

/**
 * 取消保持
 */
export function unhold(options = {}) {
  const { id } = options;
  const call = getCurrentCall(id);
  call.unhold();
}

/**
 * 分享屏幕
 */
export function shareScreen(options = {}) {
  const { id } = options;
  const call = getCurrentCall(id);
  call.shareScreen();
}

/**
 * 语音切换成视频通话
 */
export function callVideo(options = {}) {
  const { id } = options;
  const call = getCurrentCall(id);
  call.changeVideoCodec(config.userName + ',' + call.number);
}
