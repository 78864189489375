import React, { useState, useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, Button, List, Tabs, Divider, Spin, Tooltip, Row, Col, Avatar } from 'antd';
import Draggable from 'react-draggable';
import YunliRTC from '../../rtc';
import styles from './index.module.less';
import { toJS } from 'mobx';

const { TabPane } = Tabs;

const Conf = ({ VideoCallStore }) => {
  let config = YunliRTC.getConfig();
  // 会议成员昵称，优先查找会议成员列表，没有再找通讯录列表，没有则返回id
  let nickname = (id) => {
    let member = VideoCallStore.confMemberHash[id] || VideoCallStore.contactsHash[id];
    return member ? member.name : id;
  };

  const [isEndConfConfirmVisible, setEndConfConfirmVisible] = useState(false);
  const [memberListFilter, setMemberListFilter] = useState('1');
  const [videoWidth, setVideoWidth] = useState('24');

  // 拖动
  const [disabled, setDisabled] = useState(false);
  const draggleRef = useRef([]);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });

  // 显示隐藏成员
  function toggleMemberList() {
    VideoCallStore.isConfMemberVisible = !VideoCallStore.isConfMemberVisible;
  }

  // 时间格式化
  function timeFormat(data) {
    const _data = +data;
    const h = (Math.floor(_data / 3600) + '').padStart(2, 0);
    const m = (Math.floor((_data % 3600) / 60) + '').padStart(2, 0);
    const s = (((_data % 3600) % 60) + '').padStart(2, 0);
    return h === '00' ? `${m}:${s}` : `${h}:${m}:${s}`;
  }

  // 计算航美视频宽度，控制每行个数
  function countVideoWidth() {
    const num = VideoCallStore.confMemberList.filter((o) => o.isonline).length;
    let width, empty;
    if (num <= 1) {
      // 一宫格
      width = 24;
    } else if (num <= 4) {
      // 二宫格、四宫格
      width = 12;
    } else if (num <= 6 || num === 7 || num === 8 || num === 9) {
      // 六宫格、九宫格
      width = 8;
    } else if (num <= 16) {
      // 八宫格、十二宫格、十六宫格
      width = 6;
    } else if (num > 16) {
      // 其他
      width = 6;
    }

    setVideoWidth(width);

    // 空位补齐
    if (num === 3) {
      // 四宫格
      empty = 1;
    } else if (num === 5) {
      // 六宫格
      empty = 1;
    } else if (num === 7) {
      // 九宫格
      empty = 2;
    } else if (num === 8) {
      // 九宫格
      empty = 1;
    } else if (num === 9) {
      // 九宫格
      empty = 0;
    } else if (num > 8 && num < 12) {
      // 八宫格、十二宫格、十六宫格
      empty = 4 - (num % 4);
    } else if (num >= 12) {
      // 其他
      empty = num % 4 === 0 ? 0 : 4 - (num % 4);
    }
    VideoCallStore.confMemberEmpty = empty ? new Array(empty).fill('') : [];
  }

  useEffect(() => {
    countVideoWidth();
  }, [VideoCallStore.confMemberList]);

  const onStart = (_event, uiData, index) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current[index]?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    });
  };

  return (
    <>
      <Modal
        title={VideoCallStore.confTitle}
        className={
          VideoCallStore.css +
          'Dialog isSmall isMove ' +
          (VideoCallStore.isConfMax ? 'isMax' : '') +
          (VideoCallStore.isConfMin ? 'isMin' : '')
        }
        wrapClassName={VideoCallStore.css + 'DialogWrap isNoMark ' + (VideoCallStore.isConfMax ? 'isMax' : '')}
        centered
        style={{ left: 'calc(150vw - 504px)' }}
        mask={false}
        maskClosable={false}
        width={1008}
        open={VideoCallStore.isConfVisible}
        getContainer={VideoCallStore.modalContainer}
        footer={null}
        onOk={() => {}}
        onCancel={() => {
          VideoCallStore.isConfMin = true;
          VideoCallStore.isConfMax = false;
          // setEndConfConfirmVisible(true);
        }}
        modalRender={(modal) => (
          <Draggable disabled={disabled} bounds={bounds} onStart={(event, uiData) => onStart(event, uiData, 0)}>
            <div
              ref={(el) => {
                draggleRef.current[0] = el;
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}>
              {modal}
            </div>
          </Draggable>
        )}>
        <div
          className={styles.max + ' ' + styles['commonTheme' + VideoCallStore.theme] + ' commonDialogMax isMinHide'}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}>
          {VideoCallStore.confTopBtn}
          {timeFormat(VideoCallStore.callTime)}
          <em
            className="ico ico-max"
            onClick={() => {
              VideoCallStore.isConfMax = !VideoCallStore.isConfMax;
            }}></em>
        </div>
        <div
          className={styles.member + ' ' + styles['commonTheme' + VideoCallStore.theme] + ' isMinHide'}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}>
          {/* <div style={{ position: 'absolute', width: '900px', height: '450px' }}>
          <Spin />
        </div> */}
          <div className={styles.video}>
            <div className={styles.iframe + ' commonBorder'}>
              <div>
                {VideoCallStore.isConfLoading ? (
                  <div className={styles.loading}>
                    <Spin size="large" />
                  </div>
                ) : (
                  ''
                )}
                {VideoCallStore.sdk === 'ly' ? (
                  ''
                ) : (
                  <Row gutter={[]}>
                    {VideoCallStore.confMemberList
                      .filter((o) => o.isonline)
                      .map((o) => (
                        <Col span={videoWidth} key={o.id}>
                          <div className={styles.name}>
                            {o.ismuted ? (
                              <em className={styles.btnAudio + ' ' + styles.active}>
                                <i></i>
                              </em>
                            ) : (
                              ''
                            )}
                            {nickname(o.id)}
                          </div>
                          {o.isCloseVideo || !VideoCallStore.isConfVideo ? (
                            <Avatar size={80}>{nickname(o.id)}</Avatar>
                          ) : (
                            ''
                          )}
                          <video
                            id={VideoCallStore.confVideoId + '-' + o.id}
                            autoPlay
                            muted={o.ismuted ? true : false}
                            style={{ display: o.isCloseVideo ? 'none' : '' }}
                          />
                        </Col>
                      ))}
                    {VideoCallStore.confMemberEmpty.map((o, i) => (
                      <Col span={videoWidth} key={i}>
                        {' '}
                        <video />
                      </Col>
                    ))}
                  </Row>
                )}
                <iframe title="会议" name="yunliRTCConf" id="yunliRTCConf"></iframe>
              </div>
            </div>
            <span
              onClick={() => {
                if (VideoCallStore.isMute) {
                  YunliRTC.unmuteConf('');
                } else {
                  YunliRTC.muteConf('');
                }
                VideoCallStore.isMute = !VideoCallStore.isMute;

                // 航美更新音频状态
                if (VideoCallStore.sdk !== 'ly') {
                  let list = toJS(VideoCallStore.confMemberList);
                  list.forEach((o) => {
                    // 接听会议时，有可能调度台不是第一个
                    if ('' + o.id === config.userName) {
                      o.ismuted = VideoCallStore.isMute;
                    }
                  });
                  VideoCallStore.confMemberList = list;
                }
              }}>
              <em className={VideoCallStore.isMute ? 'ico ico-call-unmute' : 'ico ico-call-mute'}></em>
              {VideoCallStore.isMute ? '取消' : ''}静音
            </span>
            {VideoCallStore.isConfVideo ? (
              <span
                onClick={() => {
                  const list = toJS(VideoCallStore.confMemberList);
                  let media = VideoCallStore.confVideo();
                  if (VideoCallStore.isVideo) {
                    YunliRTC.closeConfVideo('', {
                      media: media
                    });
                  } else {
                    if (VideoCallStore.sdk !== 'ly') {
                      // 航美显示自己的本地视频
                      VideoCallStore.showLocalVideo(media);
                      media = null;
                    }
                    YunliRTC.openConfVideo('', {
                      media: media
                    });
                  }
                  VideoCallStore.isVideo = !VideoCallStore.isVideo;
                  list.forEach((o) => {
                    // 接听会议时，有可能调度台不是第一个
                    if ('' + o.id === config.userName) {
                      o.isCloseVideo = !VideoCallStore.isVideo;
                      o.isOpenVideo = VideoCallStore.isVideo;
                    }
                  });
                  VideoCallStore.confMemberList = list;
                }}>
                <em className={VideoCallStore.isVideo ? 'ico ico-call-video-close' : 'ico ico-call-video-open'}></em>
                {VideoCallStore.isVideo ? '关闭' : '打开'}摄像头
              </span>
            ) : (
              ''
            )}
            {VideoCallStore.isMemberBtnHidden ? (
              ''
            ) : (
              <span onClick={toggleMemberList}>
                <em className="ico ico-call-member"></em>
                成员{VideoCallStore.confMemberList.length ? '(' + VideoCallStore.confMemberList.length + ')' : ''}
              </span>
            )}
            {VideoCallStore.confCenterBtn}
            <div className={styles.right}>
              <Button
                type="primary"
                className={VideoCallStore.css + 'Btn'}
                danger
                onClick={() => {
                  setEndConfConfirmVisible(true);
                }}>
                结束会议
              </Button>
            </div>
            <div className={styles.left}>{VideoCallStore.confLeftBtn}</div>
          </div>
          <div className={styles.list} style={{ display: VideoCallStore.isConfMemberVisible ? '' : 'none' }}>
            <div
              className={styles.title}
              onClick={() => {
                VideoCallStore.getMembers();
              }}>
              <span>会议成员</span>
            </div>
            <Tabs
              className={VideoCallStore.css + 'Tabs'}
              defaultActiveKey="1"
              onChange={(e) => {
                setMemberListFilter(e);
              }}>
              <TabPane
                tab={'已入会(' + VideoCallStore.confMemberList.filter((o) => o.isonline).length + ')'}
                key="1"></TabPane>
              <TabPane
                tab={'未入会(' + VideoCallStore.confMemberList.filter((o) => !o.isonline).length + ')'}
                key="2"></TabPane>
            </Tabs>
            <List
              size="small"
              rowKey="id"
              dataSource={VideoCallStore.confMemberList}
              renderItem={(item) => (
                <List.Item
                  style={{
                    display:
                      (memberListFilter === '1' && item.isonline) || (memberListFilter === '2' && !item.isonline)
                        ? ''
                        : 'none'
                  }}>
                  <Tooltip placement="left" title={nickname(item.nickname) || nickname(item.suid)}>
                    <span
                      style={{
                        maxWidth: item.isonline ? '' : '110px'
                      }}
                      onClick={() => {
                        if (item.isonline) {
                          YunliRTC.focusConfVideo(item.terminalip || item.id);
                        }
                      }}>
                      {nickname(item.nickname) || nickname(item.suid)}
                    </span>
                  </Tooltip>
                  {item.suid !== config.user.suid ? (
                    item.isonline ? (
                      <>
                        <em
                          className={styles.btnAudio + (item.ismuted ? ' ' + styles.active : '')}
                          onClick={() => {
                            item.ismuted = !item.ismuted;
                            if (item.ismuted) {
                              YunliRTC.muteConf(item.terminalip || item.id);
                            } else {
                              YunliRTC.unmuteConf(item.terminalip || item.id);
                            }
                            VideoCallStore.confMemberList = [...toJS(VideoCallStore.confMemberList)];
                          }}>
                          <i></i>
                        </em>
                        {VideoCallStore.isConfVideo ? (
                          <em
                            className={styles.btnVideo + (item.isCloseVideo ? ' ' + styles.active : '')}
                            onClick={() => {
                              item.isCloseVideo = !item.isCloseVideo;
                              if (item.isCloseVideo) {
                                YunliRTC.closeConfVideo(item.terminalip || item.id, {
                                  media: VideoCallStore.confVideo(item.id)
                                });
                              } else {
                                YunliRTC.openConfVideo(item.terminalip || item.id, {
                                  media: VideoCallStore.confVideo(item.id)
                                });
                              }
                              item.isOpenVideo = !item.isCloseVideo;
                              VideoCallStore.confMemberList = [...toJS(VideoCallStore.confMemberList)];
                            }}>
                            <i></i>
                          </em>
                        ) : (
                          ''
                        )}
                        <em
                          className={styles.btnHangup}
                          onClick={() => {
                            item.isonline = false;
                            const mobile = item.terminalip || item.nickname; // 未拨打成功时，terminalip为空，号码在nickname中
                            const hsUser = VideoCallStore.contactsHash[mobile]?.hsUser; // 查询通讯录中id对应的账号hsUser值
                            YunliRTC.removeMember(hsUser || mobile, {
                              locked: false,
                              callback: () => {
                                // setTimeout(() => {
                                //   VideoCallStore.getMembers();
                                // }, 1000);
                              }
                            });
                            VideoCallStore.confMemberList = [...toJS(VideoCallStore.confMemberList)];
                          }}>
                          <i></i>
                        </em>
                      </>
                    ) : (
                      <em
                        className={styles.btnCall}
                        onClick={() => {
                          const mobile = item.terminalip || item.nickname; // 未拨打成功时，terminalip为空，号码在nickname中
                          const hsUser = VideoCallStore.contactsHash[mobile]?.hsUser; // 查询通讯录中id对应的账号hsUser值
                          YunliRTC.addMember(hsUser || mobile, {
                            isApp: !!hsUser
                          }).then(() => {
                            setTimeout(() => {
                              VideoCallStore.getMembers();
                            }, 1000);
                          });
                        }}>
                        <i></i>
                      </em>
                    )
                  ) : (
                    ''
                  )}
                </List.Item>
              )}
            />
            <div className={styles.footer}>
              <span
                className={styles.btnInvite}
                onClick={() => {
                  if (VideoCallStore.confInvite) {
                    VideoCallStore.confInvite();
                  } else {
                    VideoCallStore.isInviteVisible = true;
                  }
                  // VideoCallStore.confInviteList = [];
                }}>
                邀请成员
              </span>
              <Divider type="vertical" />
              <span
                className={styles.btnMute}
                onClick={() => {
                  let list = toJS(VideoCallStore.confMemberList);
                  list.forEach((o) => {
                    if (o.isonline) {
                      YunliRTC.muteConf(o.terminalip || o.id);
                      o.ismuted = true;
                    }
                  });
                  VideoCallStore.confMemberList = list;
                  VideoCallStore.isMute = true;
                }}>
                全员禁言
              </span>
            </div>
          </div>
        </div>
        <div
          className={styles.fold + ' ' + styles['commonTheme' + VideoCallStore.theme] + ' isMinHide'}
          onClick={toggleMemberList}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}>
          <em className={VideoCallStore.isConfMemberVisible ? '' : styles.isUnfold}></em>
          {VideoCallStore.isConfMemberVisible ? '收起' : '展开'}
        </div>
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => {
            onStart(event, uiData, 1);
            event.stopPropagation(); // 阻止外层的拖拽
          }}
          onDrag={() => {
            VideoCallStore.isMouseMoving = true;
          }}>
          <div
            ref={(el) => {
              draggleRef.current[1] = el;
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}>
            <div
              className={styles.minCallModal + ' isMinShow'}
              onClick={() => {
                if (!VideoCallStore.isMouseMoved()) {
                  VideoCallStore.isConfMin = !VideoCallStore.isConfMin;
                }
              }}>
              <em className="ico ico-call-min-video"></em>
              <div className={styles.time}>{timeFormat(VideoCallStore.callTime)}</div>
            </div>
          </div>
        </Draggable>
      </Modal>
      <Modal
        title="结束会议"
        className={VideoCallStore.css + 'Dialog isSmall isConfirm'}
        open={isEndConfConfirmVisible}
        onOk={() => {
          VideoCallStore.confEnd();
          setEndConfConfirmVisible(false);
        }}
        onCancel={() => {
          setEndConfConfirmVisible(false);
        }}
        cancelButtonProps={{ className: VideoCallStore.css + 'Btn' }}
        okButtonProps={{ className: VideoCallStore.css + 'Btn' }}
        okText="确认"
        cancelText="取消">
        <div className="cont">{VideoCallStore.endTips}确定结束会议？</div>
      </Modal>
    </>
  );
};

export default inject('VideoCallStore')(observer(Conf));
