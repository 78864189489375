import React, { useState, useEffect, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { message, Modal, Button } from 'antd';
import Draggable from 'react-draggable';
import SineWave from '../../plugins/utils/sinewave';
import YunliRTC from '../../rtc';
import styles from './index.module.less';

let keyUpInit;

const Call = ({ VideoCallStore }) => {
  const [isEndCallConfirmVisible, setEndCallConfirmVisible] = useState(false); // 确认结束通话
  const [num, setNum] = useState(''); // 点击的按钮
  const [mute, setMute] = useState(false); // 是否静音
  const [video, setVideo] = useState(true); // 是否打开摄像头
  const dialBtns = [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', '0', '#'];

  // 拖动
  const [disabled, setDisabled] = useState(false);
  const draggleRef = useRef([]);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });

  // 延迟
  let delay;

  let config = YunliRTC.getConfig();

  const dialBtnClick = (i) => {
    // 拨号-数字
    VideoCallStore.setCallPhoneNum(VideoCallStore.callPhoneNum + '' + i);
    setNum(i + '');
    clearTimeout(delay);
    delay = setTimeout(() => {
      setNum('');
    }, 200);
  };

  const dialBtnDelete = () => {
    // 拨号-删除
    setNum('clear');
    clearTimeout(delay);
    delay = setTimeout(() => {
      setNum('');
    }, 200);
    let num = VideoCallStore.callPhoneNum;
    if (!num) {
      return;
    }
    VideoCallStore.setCallPhoneNum(num.slice(0, -1));
  };

  useEffect(() => {
    if (!keyUpInit) {
      document.addEventListener('keyup', ({ keyCode, shiftKey, target }) => {
        const nodeName = target.nodeName;
        if (
          VideoCallStore.isCallVisible &&
          VideoCallStore.status === 'default' &&
          nodeName !== 'INPUT' &&
          nodeName !== 'TEXTAREA'
        ) {
          if (shiftKey) {
            if (keyCode === 51) {
              // #
              dialBtnClick('#');
            } else if (keyCode === 56) {
              // *
              dialBtnClick('*');
            }
          } else {
            if (keyCode === 8) {
              // 删除
              dialBtnDelete();
            } else if (keyCode >= 48 && keyCode <= 57) {
              // 0-9
              dialBtnClick(keyCode - 48);
            }
          }
        }
      });
      keyUpInit = true;
    }

    return () => {
      VideoCallStore.status = 'default';
      setMute(false);
    };
  }, []);

  useEffect(() => {
    if (VideoCallStore.status === 'accepted') {
      VideoCallStore.showCallTime();
      setMute(false);
    }
    if (VideoCallStore.isCallVisible) {
      let color = '0, 250, 250';
      if (VideoCallStore.theme === 'DarkBlue') {
        color = '0, 143, 255';
      } else if (VideoCallStore.theme === 'White') {
        color = '59, 119, 237';
      }
      new SineWave({
        el: document.getElementById('yunli-rtc-call-status'),
        speed: 8,
        width: 278,
        height: 44,
        waves: [
          {
            timeModifier: 1,
            lineWidth: 2,
            amplitude: 10 / 3,
            wavelength: 100 / 3,
            segmentLength: 20 / 3,
            opacity: 0.4
          },
          {
            timeModifier: 1,
            lineWidth: 2,
            amplitude: 50 / 3,
            wavelength: 100 / 3,
            opacity: 0.7
          },
          {
            timeModifier: 2,
            lineWidth: 2,
            amplitude: -50 / 3,
            wavelength: 50 / 3,
            segmentLength: 10 / 3,
            opacity: 0.2
          }
        ],
        initialize: function () {},
        resizeEvent: function () {
          var b = -1;
          var c = this.waves.length;
          while (++b < c) {
            var a = this.ctx.createLinearGradient(0, 0, this.width, 0);
            a.addColorStop(0, 'rgba(' + color + ', 0)');
            a.addColorStop(0.5, 'rgba(' + color + ', ' + this.waves[b].opacity + ')');
            a.addColorStop(1, 'rgba(' + color + ', 0)');
            this.waves[b].strokeStyle = a;
          }
          b = void 0;
          c = void 0;
          a = void 0;
        }
      });
    }
  }, [VideoCallStore.status]);

  const makeCall = () => {
    // 拨打电话
    let num = VideoCallStore.callPhoneNum;
    if (!num) {
      message.error('请输入号码');
      return;
    }
    VideoCallStore.call({
      id: num
    });
  };

  const makeCallVideo = () => {
    // 拨打视频
    let num = VideoCallStore.callPhoneNum;
    if (!num) {
      message.error('请输入号码');
      return;
    }

    if (VideoCallStore.dialVideo) {
      // 自定义拨号盘拨打视频
      VideoCallStore.dialVideo(num);
    } else {
      VideoCallStore.call({
        id: num,
        video: true
      });
    }
  };

  // 挂断
  const hangup = () => {
    const eventBus = config.eventBus;
    const { status, callTimes } = VideoCallStore;

    VideoCallStore.hangup();

    if (status === 'accepted') {
      VideoCallStore.isCallVisible = false;
    }

    // 未拨通重试的情况
    if (status === 'progress') {
      if (callTimes) {
        console.log('未接通...');
        eventBus.emit('yunli-rtc-turnplate-fail', VideoCallStore.callInfo);
      }
    }
  };

  // 静音切换
  const callMuteToggle = () => {
    let id = VideoCallStore.id;
    if (mute) {
      YunliRTC.unmute({ id: id });
    } else {
      YunliRTC.mute({ id: id });
    }
    setMute(!mute);
  };

  // 摄像头切换
  const callVideoToggle = () => {
    let id = VideoCallStore.id;
    if (video) {
      YunliRTC.muteVideo({ id: id });
      VideoCallStore.hideLocalVideo();
    } else {
      YunliRTC.unmuteVideo({ id: id });
      VideoCallStore.showLocalVideo();
    }
    setVideo(!video);
  };

  // 时间格式化
  const timeFormat = (data) => {
    const _data = +data;
    const h = (Math.floor(_data / 3600) + '').padStart(2, 0);
    const m = (Math.floor((_data % 3600) / 60) + '').padStart(2, 0);
    const s = (((_data % 3600) % 60) + '').padStart(2, 0);
    return h === '00' ? `${m}:${s}` : `${h}:${m}:${s}`;
  };

  // 获取通话类型
  const getCallType = () => 'isCall' + VideoCallStore.type.charAt(0).toUpperCase() + VideoCallStore.type.slice(1);

  const onStart = (_event, uiData, index) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current[index]?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    });
  };

  return (
    <>
      <Modal
        title={
          VideoCallStore.callTitle ||
          (VideoCallStore.status !== 'accepted' ? '拨号' : VideoCallStore.type === 'audio' ? '通话' : '视频通话')
        }
        className={VideoCallStore.css + 'Dialog isSmall isMove ' + (VideoCallStore.isMin ? 'isMin' : '')}
        width={
          VideoCallStore.status === 'accepted' && VideoCallStore.type === 'video' ? VideoCallStore.callVideoWidth : 300
        }
        mask={false}
        maskClosable={false}
        wrapClassName={VideoCallStore.css + 'DialogWrap isNoMark'}
        open={VideoCallStore.isCallVisible}
        getContainer={VideoCallStore.modalContainer}
        centered
        style={{
          left:
            VideoCallStore.status === 'accepted' && VideoCallStore.type === 'video'
              ? 'calc(150vw - ' + VideoCallStore.callVideoWidth / 2 + 'px)'
              : 'calc(150vw - 150px)'
        }}
        footer={null}
        onOk={() => {}}
        onCancel={() => {
          if (VideoCallStore.status === 'accepted') {
            VideoCallStore.isMin = true;
            // setEndCallConfirmVisible(true);
          } else {
            VideoCallStore.isCallVisible = false;
            hangup();
          }
        }}
        modalRender={(modal) => (
          <Draggable disabled={disabled} bounds={bounds} onStart={(event, uiData) => onStart(event, uiData, 0)}>
            <div
              ref={(el) => {
                draggleRef.current[0] = el;
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}>
              {modal}
            </div>
          </Draggable>
        )}>
        <div
          className={
            styles.max + ' ' + styles['commonTheme' + VideoCallStore.theme] + ' ' + ' commonDialogMax isMinHide'
          }
          onMouseDown={(event) => {
            event.stopPropagation();
          }}>
          {VideoCallStore.status === 'accepted' && VideoCallStore.type === 'video' && VideoCallStore.isVideoBtnBottom
            ? timeFormat(VideoCallStore.callTime)
            : ''}
          {/* {VideoCallStore.status === 'accepted' ? (
            <em
              className="ico ico-min"
              title="最小化"
              onClick={() => {
                VideoCallStore.isMin = !VideoCallStore.isMin;
              }}></em>
          ) : (
            ''
          )} */}
        </div>
        <div
          className={
            styles.callModal +
            ' isMinHide' +
            ' ' +
            styles['commonTheme' + VideoCallStore.theme] +
            ' ' +
            styles[getCallType()] +
            ' ' +
            getCallType()
          }
          style={{ display: VideoCallStore.isCallVisible ? '' : 'none' }}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}>
          <div className={styles.callModalMask}>
            <div
              className={styles.callDefaultContainer}
              style={{
                display: VideoCallStore.status === 'default' ? 'block' : 'none'
              }}>
              <div className={styles.defaultCallPhone}>
                <span>{VideoCallStore.callPhoneNum}</span>
                <em>
                  {VideoCallStore.callPhoneNum && VideoCallStore.callPhoneNum.length > 3
                    ? VideoCallStore.callNickname
                    : ''}
                </em>
              </div>
              <div className={styles.callBox}>
                {dialBtns.map((i) => (
                  <span
                    key={i}
                    className={'ico ico-call-btn' + (num === i + '' ? '-on' : '') + ' ' + styles.callBtn}
                    onClick={() => dialBtnClick(i)}>
                    {i}
                  </span>
                ))}
              </div>
              <div className={styles.callFunBox}>
                <span className={'ico ico-call-video ' + styles.callBtnCallVideo} onClick={makeCallVideo}></span>
                <span className={'ico ico-call-audio ' + styles.callBtnCall} onClick={makeCall}></span>
                <span
                  className={'ico ico-call-btn-del' + (num === 'clear' ? '-on' : '') + ' ' + styles.callBtnDelete}
                  onClick={dialBtnDelete}></span>
              </div>
            </div>
            <div
              className={
                styles.callContainer +
                (VideoCallStore.status === 'accepted' &&
                VideoCallStore.type === 'video' &&
                VideoCallStore.isVideoBtnBottom
                  ? ' ' + styles.isVideoBtnBottom
                  : '')
              }
              style={{
                display: VideoCallStore.status !== 'default' ? 'block' : 'none'
              }}>
              <div className={styles.defaultCallPhone}>
                <span>{VideoCallStore.callPhoneNum}</span>
                <em>
                  {VideoCallStore.callPhoneNum && VideoCallStore.callPhoneNum.length > 3
                    ? VideoCallStore.callNickname
                    : ''}
                </em>
              </div>
              <div className={styles.callStatus}>
                <div
                  className={styles.callingContent}
                  style={{
                    display: VideoCallStore.status === 'accepted' && VideoCallStore.type === 'video' ? 'none' : 'flex'
                  }}>
                  <canvas id="yunli-rtc-call-status" />
                  <span>{VideoCallStore.status === 'progress' ? '正在呼叫…' : ''}</span>
                </div>
                <div
                  className={styles.onlineContent}
                  style={{
                    display: VideoCallStore.status === 'accepted' && VideoCallStore.type === 'video' ? 'flex' : 'none'
                  }}>
                  <div className={styles.callVideoPlayer}>
                    <video
                      id={VideoCallStore.localVideoId}
                      className={VideoCallStore.isLocalViedoFront ? '' : styles.isSmall}
                      autoPlay
                    />
                    <video
                      id={VideoCallStore.remoteVideoId}
                      className={VideoCallStore.isLocalViedoFront ? styles.isSmall : ''}
                      style={
                        VideoCallStore.contactsHash[VideoCallStore.callPhoneNum]?.hsUser
                          ? VideoCallStore.isLocalViedoFront
                            ? { transform: 'scaleY(3) translateY(13px)', height: '8.5%' }
                            : { transform: 'scaleY(3) translateY(51px)', height: '33.5%' }
                          : {}
                      }
                      autoPlay
                    />
                    <div
                      className={styles.change}
                      onClick={() => {
                        VideoCallStore.isLocalViedoFront = !VideoCallStore.isLocalViedoFront;
                      }}></div>
                  </div>
                </div>
              </div>
              <div className={styles.callTime}>
                {VideoCallStore.status === 'progress' ? '' : timeFormat(VideoCallStore.callTime)}
              </div>
              <div
                className={styles.callFunBox}
                style={{
                  visibility: !VideoCallStore.isMuteHidden && VideoCallStore.status === 'accepted' ? '' : 'hidden'
                }}>
                <span className={mute ? styles.callFunMuteOn : styles.callFunMuteOff} onClick={callMuteToggle}>
                  <i />
                  <label>{mute ? '取消' : ''}静音</label>
                </span>
              </div>
              <div className={styles.callHangup}>
                <em
                  className="ico ico-call-hangup"
                  onClick={() => {
                    // 挂断
                    if (VideoCallStore.status === 'accepted') {
                      setEndCallConfirmVisible(true);
                    } else {
                      hangup();
                    }
                  }}></em>
                {VideoCallStore.isAudioToVideo &&
                VideoCallStore.status === 'accepted' &&
                VideoCallStore.type === 'audio' ? (
                  <div
                    className={styles.callToVideo}
                    title="切换到视频"
                    onClick={() => {
                      // 先切换到视频，然后切换到视频会议
                      if (VideoCallStore.type === 'audio') {
                        if (VideoCallStore.isAudioToConf) {
                          VideoCallStore.callConf();
                        } else {
                          // 只转视频的代码，暂时不用了
                          YunliRTC.callVideo({
                            id: VideoCallStore.id
                          });
                          VideoCallStore.type = 'video';
                          setTimeout(() => {
                            VideoCallStore.showLocalVideo();
                          }, 500);
                        }
                      } else {
                        VideoCallStore.type = 'audio';
                        VideoCallStore.hideLocalVideo();
                      }
                    }}>
                    <em className="ico ico-call-to-video"></em>
                  </div>
                ) : (
                  ''
                )}
                {VideoCallStore.isCallInvite && VideoCallStore.status === 'accepted' ? (
                  <div
                    className={styles.callInvite}
                    title="邀请成员"
                    onClick={() => {
                      if (VideoCallStore.confInvite) {
                        VideoCallStore.confInvite();
                      } else {
                        VideoCallStore.isInviteVisible = true;
                      }
                      // VideoCallStore.confInviteList = [];
                    }}>
                    <em className="ico ico-call-invite"></em>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className={styles.callLeftBtn}>
                {VideoCallStore.status === 'accepted' ? VideoCallStore.callLeftBtn : ''}
              </div>
            </div>
          </div>
          {VideoCallStore.status === 'accepted' &&
          VideoCallStore.type === 'video' &&
          VideoCallStore.isVideoBtnBottom ? (
            <div className={styles.member + ' ' + styles.isCall}>
              <div className={styles.video}>
                {VideoCallStore.isCallInvite && VideoCallStore.status === 'accepted' ? (
                  <span
                    onClick={() => {
                      if (VideoCallStore.confInvite) {
                        VideoCallStore.confInvite();
                      } else {
                        VideoCallStore.isInviteVisible = true;
                      }
                    }}>
                    <em className="ico ico-call-invite2"></em>
                    邀请成员
                  </span>
                ) : (
                  ''
                )}
                <span onClick={callMuteToggle}>
                  <em className={mute ? 'ico ico-call-unmute' : 'ico ico-call-mute'}></em>
                  {mute ? '取消' : ''}静音
                </span>
                {VideoCallStore.sdk !== 'ly' ? (
                  <span onClick={callVideoToggle}>
                    <em className={video ? 'ico ico-call-video-close' : 'ico ico-call-video-open'}></em>
                    {video ? '关闭' : '打开'}摄像头
                  </span>
                ) : (
                  ''
                )}
                {VideoCallStore.confCenterBtn}
                <div className={styles.right}>
                  <Button
                    type="primary"
                    className={VideoCallStore.css + 'Btn'}
                    danger
                    onClick={() => {
                      // 挂断
                      if (VideoCallStore.status === 'accepted') {
                        setEndCallConfirmVisible(true);
                      } else {
                        hangup();
                      }
                    }}>
                    结束通话
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => {
            onStart(event, uiData, 1);
            event.stopPropagation(); // 阻止外层的拖拽
          }}
          onDrag={() => {
            VideoCallStore.isMouseMoving = true;
          }}>
          <div
            ref={(el) => {
              draggleRef.current[1] = el;
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}>
            <div
              className={styles.minCallModal + ' isMinShow'}
              onClick={() => {
                if (!VideoCallStore.isMouseMoved()) {
                  VideoCallStore.isMin = !VideoCallStore.isMin;
                }
              }}>
              {VideoCallStore.type === 'video' ? (
                <em className="ico ico-call-min-video"></em>
              ) : (
                <em className="ico ico-call-min-audio"></em>
              )}
              <div className={styles.time}>
                {VideoCallStore.status === 'progress' ? '' : timeFormat(VideoCallStore.callTime)}
              </div>
            </div>
          </div>
        </Draggable>
      </Modal>
      <Modal
        title="结束通话"
        className={VideoCallStore.css + 'Dialog isSmall isConfirm'}
        open={isEndCallConfirmVisible}
        onOk={() => {
          hangup();
          setEndCallConfirmVisible(false);
        }}
        onCancel={() => {
          setEndCallConfirmVisible(false);
        }}
        cancelButtonProps={{ className: VideoCallStore.css + 'Btn' }}
        okButtonProps={{ className: VideoCallStore.css + 'Btn' }}
        okText="确认"
        cancelText="取消">
        <div className="cont">{VideoCallStore.endTips}确定结束通话？</div>
      </Modal>
    </>
  );
};

export default inject('VideoCallStore')(observer(Call));
