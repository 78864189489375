import React, { useEffect } from 'react';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import VideoCallStore from '../../stores/VideoCallStore';
import Main from './Main';
import YunliRTC from '../../rtc';
import { ConfigProvider } from 'antd';
// import zhCN from 'antd/locale/zh_CN';

/*
  备忘：
  1、通讯录中的hsUser用来判断是否为APP端用户，会议时hsUser用户调用不同的接口；
  
  eventBus:
    yunli-rtc-turnplate API接口
    yunli-rtc-turnplate-disabled 未注册不可用（用于没有权限时，使用系统默认的通话功能）
    yunli-rtc-turnplate-login 登录
    yunli-rtc-turnplate-logout 登出
    yunli-rtc-turnplate-login-tips 登录提示（用于提示用户需要登录，才能使用功能）
    yunli-rtc-turnplate-incoming 来电
    yunli-rtc-turnplate-call-miss-ignore 未接来电忽略
    yunli-rtc-turnplate-call 开始呼叫
    yunli-rtc-turnplate-start 开始通话
    yunli-rtc-turnplate-hangup 挂断通话
    yunli-rtc-turnplate-fail 未接通（主动挂断或呼叫失败）
    yunli-rtc-turnplate-conf 开始会议
    yunli-rtc-turnplate-conf-join 成员加入会议
    yunli-rtc-turnplate-conf-end 结束会议
    yunli-rtc-turnplate-player-hangup 播放器挂断
*/

const stores = { VideoCallStore };

configure({ enforceActions: 'never', isolateGlobalState: true }); // 关闭enforceActions

function Turnplate({ options = {}, regist, children }) {
  const eventBus = regist.eventBus;

  YunliRTC.setConfig({
    eventBus: eventBus
  });

  useEffect(() => {
    options.userNick = options.userNick || options.userName; // 未设置昵称时，取用户名
    eventBus.emit('yunli-rtc-turnplate', {
      key: 'set',
      value: options
    });
  }, []);
  return (
    <Provider {...stores}>
      <ConfigProvider>
        <Main regist={regist}>{children}</Main>
      </ConfigProvider>
    </Provider>
  );
}

// 方法绑定
const emit = (key, value, options) => {
  let config = YunliRTC.getConfig();
  const { eventBus } = config;

  eventBus?.emit('yunli-rtc-turnplate', {
    key,
    value,
    options
  });
};
[
  'regist',
  'dial',
  'call',
  'callConf',
  'conf',
  'hangup',
  'control',
  'live',
  'contacts',
  'invite',
  'play',
  'playerHangup',
  'setContactsList',
  'getContactsList',
  'setEquipmentList',
  'getEquipmentList'
].forEach((o) => {
  Turnplate[o] = (value, options) => {
    if (['live', 'setContactsList', 'getContactsList', 'setEquipmentList', 'getEquipmentList', 'play'].includes(o)) {
      // 不需要注册
      emit(o, value, options);
    } else if (VideoCallStore.isRegisted !== null || !VideoCallStore[o]) {
      // 已注册
      emit(o, value, options);
    } else {
      // 未注册，不可用的状态
      VideoCallStore[o](value, options);
    }
  };
});

// // 音视频电话
// Turnplate.call = (options) => {
//   VideoCallStore.call(options);
// };

// // 视频会议
// Turnplate.conf = (ids, options) => {
//   VideoCallStore.conf(ids, options);
// };

// 获取属性
Turnplate.get = (key) => {
  return VideoCallStore[key];
};

// 设置属性
Turnplate.set = (value) => {
  if (value) {
    for (var i in value) {
      VideoCallStore[i] = value[i];

      // 设置主题样式
      if (i === 'theme') {
        VideoCallStore.setTheme(value[i]);
      }
    }
  }
};

// 将单个或多个base64转为File文件
// Turnplate.base64ToFile = (options) => {
//   return VideoCallStore.base64ToFile(options);
// };

// 将ObjectURL的Blob文件转为File文件
Turnplate.blobToFile = (options) => {
  return VideoCallStore.blobToFile(options);
};

// 获取视频播放url
Turnplate.getVideoUrl = (value) => {
  return VideoCallStore.getVideoUrl(value);
};

export default Turnplate;
