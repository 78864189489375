import { ua, getSession, onIncomingStream, removeSession } from './regist';
import { message } from 'antd';
import { getConfig } from '../config';
import checkDeviceSupport from '../../plugins/utils/checkDeviceSupport';

const config = getConfig();

/**
 * 拨打
 * @param {Number} number 号码
 * @param {Boolean} video 是否视频通话
 */
export async function call(number, { video = true, isPlayer = false, media, callback }) {
  const { ip, eventBus } = config;
  const deviceSupport = await checkDeviceSupport();
  if (!deviceSupport?.hasMicrophone && !deviceSupport?.hasWebcam) {
    message.error('未检测到摄像头和麦克风设备，部分功能无法正常使用');
  }
  if (!deviceSupport?.isMicrophoneAlreadyCaptured || !deviceSupport?.isWebcamAlreadyCaptured) {
    message.error('请检查浏览器是否打开摄像头和麦克风权限');
    if (navigator.getUserMedia) {
      navigator.getUserMedia(
        { video: true, audio: true },
        function onSuccess(stream) {
          console.log('开启成功');
          try {
            let tracks = stream.getTracks();
            tracks[0].stop();
            tracks[1].stop();
          } catch (e) {
            console.log(e);
          }
        },
        function onError(error) {
          console.log(error);
        }
      );
    }
  }
  if (isPlayer) {
    // 播放器模式强制关闭自己的摄像头和麦克风，目前暂时没有关闭摄像头的接口
    deviceSupport.isMicrophoneAlreadyCaptured = false;
    deviceSupport.isWebcamAlreadyCaptured = false;
  }
  const options = {
    mediaConstraints: {
      audio: deviceSupport.isMicrophoneAlreadyCaptured,
      video: video ? deviceSupport.isWebcamAlreadyCaptured : false
    },
    rtcOfferConstraints: {
      offerToReceiveAudio: true,
      offerToReceiveVideo: video
    }
  };

  eventBus.once('yunli-rtc-call-session', function (data) {
    callback && callback(data);
  });

  ua.call(`sip:${number}@${ip}`, options);

  const stream = await onIncomingStream();
  media.srcObject = stream;
}

/**
 * 接听
 * @param {HTMLVideoElement} media 接收的媒体
 * @param {Boolean} video 是否视频通话
 */
export async function answer({ media, video = true, callback, id }) {
  const session = getSession(id);
  if (!session) {
    return;
  }
  const deviceSupport = await checkDeviceSupport();
  const options = {
    mediaConstraints: {
      audio: deviceSupport.isMicrophoneAlreadyCaptured,
      video: video ? deviceSupport.isWebcamAlreadyCaptured : false
    },
    rtcOfferConstraints: {
      offerToReceiveAudio: true,
      offerToReceiveVideo: video
    }
  };
  session.answer(options);
  callback &&
    callback({
      id: session._id
    });

  const stream = await onIncomingStream();
  media.srcObject = stream;
}

/**
 * 挂断
 */
export function hangup(options = {}) {
  const session = getSession(options.id);
  if (!session) {
    return;
  }
  try {
    session.terminate();
  } catch (e) {
    console.log(e);
  }
  if (options.id) {
    removeSession(options.id);
  }
}

/**
 * 静音
 */
export function mute(options = {}) {
  const session = getSession(options.id);
  if (!session) {
    return;
  }
  session.mute();
}

/**
 * 解除静音
 */
export function unmute(options = {}) {
  const session = getSession(options.id);
  if (!session) {
    return;
  }
  session.unmute();
}

/**
 * 是否静音
 */
export function isMuted(options = {}) {
  const session = getSession(options.id);
  if (!session) {
    return false;
  }
  // { audio: false, video: false }
  return session.isMuted();
}
