import axios from 'axios';
import { message } from 'antd';
import qs from 'qs';
// import _ from 'lodash';

/**
 * 创建xhr实例
 * 路径前缀
 * 超时失败时间
 */
const service = axios.create({
  timeout: 500000,
  async: true,
  crossDomain: true,
  withCredentials: true,
  baseURL: '/',
  validateStatus: (status) => status >= 200 && status <= 500 // 默认的
});

/**
 * @desc 设置服务请求拦截器
 * 定义token请求设置
 */
service.interceptors.request.use(
  (config) => {
    config.headers['blade-auth'] = 'bearer ' + sessionStorage.getItem('web_token');
    config.headers['token'] = sessionStorage.getItem('web_token');
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/**
 * @desc 设置服务响应拦截器
 * 截取返回状态 统一定义成功失败
 */
service.interceptors.response.use(
  (response) => {
    const { config, data, status, headers } = response;
    const type = Object.prototype.toString.call(data);
    if (config.responseType === 'arraybuffer') {
      let fileName = headers['content-disposition'] || 'response.xls';
      fileName = fileName.replace('filename=', '');
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return Promise.resolve(data);
    }
    // if (config.url.startsWith('/auth/oauth/token')) {
    //   if (status === 200) {
    //     return Promise.resolve(data);
    //   } else {
    //     sessionStorage.removeItem('web_token');
    //     return Promise.reject(data);
    //   }
    // }
    // if (status !== 200) {
    //   return Promise.reject(data);
    // }
    if (status === '401') {
      message.destroy();
      message.error({
        content: '登录失效，请重新登录',
        className: 'messageInfo'
      });
      sessionStorage.removeItem('web_token');
      window.location = '/login';
      return Promise.reject(data);
    }
    if (type === '[object Object]' || type === '[object Array]') {
      let data2; // 兼容后台直接返回数组的情况，凌壹后台
      if (data.result === 1 || data.result === 'success' || data.ddtinfo) {
        data.code = '200';
        data.isSuccess = true;
      }
      if (type === '[object Array]' && data.length && !data.code) {
        data2 = {
          code: '200',
          isSuccess: true,
          data: data
        };
      }
      if (data.id) {
        // 兼容直接返回id对象的情况，凌壹后台
        data.code = '200';
        data.isSuccess = true;
        data.data = data.id;
      }

      const { code, msg } = data2 || data;
      // return Promise.resolve(data);
      if (config.url.startsWith('/api/ylv/v1/realplay/') && code === '0') {
        return Promise.resolve(data);
      }
      if (code == '200' || code === 'OK') {
        return Promise.resolve(data);
      } else if (code === '400' || code === '401') {
        // message.error('登录失效，请重新登录');
        sessionStorage.removeItem('web_token');
        if (window.location.pathname !== '/login') {
          window.location = '/login';
        }

        return Promise.reject(data);
      } else {
        if (msg) {
          message.destroy();
          message.error({
            content: msg,
            className: 'messageInfo'
          });
        }
        console.log(code, msg, 111);
        return Promise.reject(data);
      }
    } else {
      return Promise.reject(data);
    }
  },
  (err) => {
    if (err && err.response && err.response.status === '401') {
      message.destroy();
      message.error({
        content: '登录失效，请重新登录',
        className: 'messageInfo'
      });
      sessionStorage.removeItem('web_token');
      window.location = '/login';
    }
    return Promise.reject(err);
  }
);

function formatParam(param = {}) {
  if (Object.prototype.toString.call(param) === '[object URLSearchParams]') {
    return param;
  }
  Object.keys(param).forEach((key) => {
    if (param[key] === undefined || param[key] === null) {
      param[key] = '';
    }
  });
  return param;
}

const strategy = {
  postform(param) {
    param = formatParam(param);
    const config = {};
    config.method = 'post';
    config.data = qs.stringify(param);
    return config;
  },
  post(param) {
    param = formatParam(param);
    const config = {};
    config.method = 'post';
    config.data = param;
    if (param.ContentType) {
      config.headers = {
        'Content-Type': param.ContentType
      };
      delete param.ContentType;
    }
    return config;
  },
  get(param) {
    param = formatParam(param);
    const config = {};
    config.method = 'get';
    config.params = param;
    return config;
  },
  put(param) {
    param = formatParam(param);
    const config = {};
    config.method = 'put';
    config.data = param;
    return config;
  },
  delete(param) {
    param = formatParam(param);
    const config = {};
    config.method = 'delete';
    config.params = param;
    return config;
  },
  patch(param) {
    param = formatParam(param);
    const config = {};
    config.method = 'patch';
    config.data = param;
    return config;
  },
  download(param) {
    param = formatParam(param);
    const config = {
      responseType: 'arraybuffer'
    };
    config.method = 'get';
    config.data = param;
    return config;
  }
};

const request = (url, method = 'get', param) => {
  method = method.toLowerCase();
  const config = strategy[method](param);
  return service({
    url,
    ...config
  });
};

export const get = (url, params) => {
  return request(url, 'get', params);
};

export const post = (url, params) => {
  return request(url, 'post', params);
};

export const postForm = (url, params) => {
  return request(url, 'postForm', params);
};

export const deleteRequest = (url, params) => {
  return request(url, 'delete', params);
};

export const put = (url, params) => {
  return request(url, 'put', params);
};
export const patch = (url, params) => {
  return request(url, 'patch', params);
};
export const download = (url, params) => {
  return request(url, 'download', params);
};

export default request;
