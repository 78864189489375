export const ContactsList = [
  {
    contactsLabelId: '790335737616400384',
    contactsLabelName: '1',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-11-16 19:13:09',
    modifyTime: '2022-11-16 19:13:09',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '787315092905459712',
    contactsLabelName: '测试新增44',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-11-08 11:10:11',
    modifyTime: '2022-11-08 11:10:11',
    count: 15,
    children: [
      {
        contactsLabelId: '787457683717038080',
        contactsLabelName: 'test002',
        level: '2',
        parentLabelId: '787315092905459712',
        isJointServiceUnit: '0',
        remark: '',
        createTime: '2022-11-08 20:36:47',
        modifyTime: '2022-11-08 20:36:47',
        count: 12,
        contactsChildren: [
          {
            contactsId: '790339312975347712',
            contactsName: '1111',
            mobile: '15810391261',
            type: '0',
            jobDescription: '1',
            contactsLabelId: '787457683717038080',
            contactsLabelName: 'test002',
            remark: '',
            createTime: '2022-11-16 19:27:21',
            modifyTime: '2022-12-26 19:03:21'
          },
          {
            contactsId: '790335588823465984',
            contactsName: '1',
            mobile: '15810391261',
            type: '0',
            jobDescription: '1',
            contactsLabelId: '787457683717038080',
            contactsLabelName: 'test002',
            remark: '',
            createTime: '2022-11-16 19:12:33',
            modifyTime: '2022-11-16 19:12:33'
          },
          {
            contactsId: '787459856467173379',
            contactsName: '程九',
            mobile: '15810668209',
            type: '0',
            jobDescription: '6',
            contactsLabelId: '787457683717038080',
            contactsLabelName: 'test002',
            remark: '批量导入',
            createUserId: '202211041045011038041173547159552',
            createTime: '2022-11-08 20:45:25',
            modifyUserId: '202211041045011038041173547159552',
            modifyTime: '2022-11-08 20:45:25'
          },
          {
            contactsId: '787459856467173377',
            contactsName: '程十',
            mobile: '13241722925',
            type: '0',
            jobDescription:
              '3岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述岗位描述',
            contactsLabelId: '787457683717038080',
            contactsLabelName: 'test002',
            remark: '批量导入',
            createUserId: '202211041045011038041173547159552',
            createTime: '2022-11-08 20:45:25',
            modifyUserId: '202211041045011038041173547159552',
            modifyTime: '2022-11-08 20:45:25'
          },
          {
            contactsId: '787459856467173376',
            contactsName: '程三',
            mobile: '123123123',
            type: '0',
            jobDescription: '2',
            contactsLabelId: '787457683717038080',
            contactsLabelName: 'test002',
            remark: '批量导入',
            createUserId: '202211041045011038041173547159552',
            createTime: '2022-11-08 20:45:25',
            modifyUserId: '202211041045011038041173547159552',
            modifyTime: '2022-11-08 20:45:25'
          },
          {
            contactsId: '787458534716153860',
            contactsName: '程八',
            mobile: '15810668209',
            type: '0',
            jobDescription: '5',
            contactsLabelId: '787457683717038080',
            contactsLabelName: 'test002',
            remark: '批量导入',
            createUserId: '202211041045011038041173547159552',
            createTime: '2022-11-08 20:40:10',
            modifyUserId: '202211041045011038041173547159552',
            modifyTime: '2022-11-08 20:40:10'
          },
          {
            contactsId: '787458534716153859',
            contactsName: '程七',
            mobile: '15810668209',
            type: '0',
            jobDescription: '4',
            contactsLabelId: '787457683717038080',
            contactsLabelName: 'test002',
            remark: '批量导入',
            createUserId: '202211041045011038041173547159552',
            createTime: '2022-11-08 20:40:10',
            modifyUserId: '202211041045011038041173547159552',
            modifyTime: '2022-11-08 20:40:10'
          },
          {
            contactsId: '787458534716153858',
            contactsName: '程六',
            mobile: '13241722925',
            type: '0',
            jobDescription: '3',
            contactsLabelId: '787457683717038080',
            contactsLabelName: 'test002',
            remark: '批量导入',
            createUserId: '202211041045011038041173547159552',
            createTime: '2022-11-08 20:40:10',
            modifyUserId: '202211041045011038041173547159552',
            modifyTime: '2022-11-08 20:40:10'
          },
          {
            contactsId: '787458534716153857',
            contactsName: '程五',
            mobile: '13241722925',
            type: '0',
            jobDescription: '2',
            contactsLabelId: '787457683717038080',
            contactsLabelName: 'test002',
            remark: '批量导入',
            createUserId: '202211041045011038041173547159552',
            createTime: '2022-11-08 20:40:10',
            modifyUserId: '202211041045011038041173547159552',
            modifyTime: '2022-11-08 20:40:10'
          },
          {
            contactsId: '787458534716153856',
            contactsName: '程四',
            mobile: '13241722925',
            type: '0',
            jobDescription: '1',
            contactsLabelId: '787457683717038080',
            contactsLabelName: 'test002',
            remark: '批量导入',
            createUserId: '202211041045011038041173547159552',
            createTime: '2022-11-08 20:40:10',
            modifyUserId: '202211041045011038041173547159552',
            modifyTime: '2022-11-08 20:40:10'
          },
          {
            contactsId: '787457844535042048',
            contactsName: '程二',
            mobile: '13241722925',
            type: '0',
            jobDescription: '体化指挥中心123',
            contactsLabelId: '787457683717038080',
            contactsLabelName: 'test002',
            remark: '',
            createTime: '2022-11-08 20:37:26',
            modifyTime: '2022-11-08 20:37:26'
          },
          {
            contactsId: '787457787282792448',
            contactsName: '程一',
            mobile: '13241722925',
            type: '0',
            jobDescription: '一体化指挥中心123',
            contactsLabelId: '787457683717038080',
            contactsLabelName: 'test002',
            remark: '',
            createTime: '2022-11-08 20:37:12',
            modifyTime: '2022-11-08 20:37:12'
          }
        ]
      },
      {
        contactsLabelId: '787457649126612992',
        contactsLabelName: 'test001',
        level: '2',
        parentLabelId: '787315092905459712',
        isJointServiceUnit: '0',
        remark: '',
        createTime: '2022-11-08 20:36:39',
        modifyTime: '2022-11-08 20:36:39',
        count: 3,
        contactsChildren: [
          {
            contactsId: '790334467308519424',
            contactsName: '12',
            mobile: '15810391261',
            type: '0',
            jobDescription: '1',
            contactsLabelId: '787457649126612992',
            contactsLabelName: 'test001',
            remark: '',
            createTime: '2022-11-16 19:08:06',
            modifyTime: '2022-11-16 19:10:01'
          },
          {
            contactsId: '790334033911087104',
            contactsName: '1',
            mobile: '15810391261',
            type: '0',
            jobDescription: '1',
            contactsLabelId: '787457649126612992',
            contactsLabelName: 'test001',
            remark: '',
            createTime: '2022-11-16 19:06:23',
            modifyTime: '2022-11-16 19:06:23'
          },
          {
            contactsId: '787458534716153861',
            contactsName: '程九123',
            mobile: '15810668209',
            type: '0',
            jobDescription: '6123',
            contactsLabelId: '787457649126612992',
            contactsLabelName: 'test001',
            remark: '批量导入',
            createUserId: '202211041045011038041173547159552',
            createTime: '2022-11-08 20:40:10',
            modifyUserId: '202211041045011038041173547159552',
            modifyTime: '2022-11-08 20:43:07'
          }
        ]
      }
    ]
  },
  {
    contactsLabelId: '787315025184227328',
    contactsLabelName: '测试新增33',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-11-08 11:09:55',
    modifyTime: '2022-11-08 11:09:55',
    count: 0,
    children: [],
    contactsChildren: [
      {
        contactsId: '790335433722298368',
        contactsName: '1',
        mobile: '15810391261',
        type: '0',
        jobDescription: '1',
        contactsLabelId: '787315025184227328',
        contactsLabelName: '测试新增33',
        remark: '',
        createTime: '2022-11-16 19:11:56',
        modifyTime: '2022-11-16 19:12:03'
      }
    ]
  },
  {
    contactsLabelId: '787299282816270336',
    contactsLabelName: 'ddddddddd',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-11-08 10:07:21',
    modifyTime: '2022-11-08 10:07:21',
    count: 0,
    children: [],
    contactsChildren: [
      {
        contactsId: '790334986559160320',
        contactsName: '12312312',
        mobile: '15810391261',
        type: '0',
        jobDescription: '1231',
        contactsLabelId: '787299282816270336',
        contactsLabelName: 'ddddddddd',
        remark: '',
        createTime: '2022-11-16 19:10:10',
        modifyTime: '2022-11-16 19:10:10'
      }
    ]
  },
  {
    contactsLabelId: '787298571290345472',
    contactsLabelName: 'testtesttesttesttest',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-11-08 10:04:32',
    modifyTime: '2022-11-08 10:04:32',
    count: 0,
    children: [
      {
        contactsLabelId: '787298700344885248',
        contactsLabelName: 'test22',
        level: '2',
        parentLabelId: '787298571290345472',
        isJointServiceUnit: '0',
        remark: '',
        createTime: '2022-11-08 10:05:03',
        modifyTime: '2022-11-08 10:05:03',
        count: 0
      }
    ]
  },
  {
    contactsLabelId: '770431233912184832',
    contactsLabelName: '测试新增一级标签25',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:59:45',
    modifyTime: '2022-09-22 20:59:45',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770431219089514496',
    contactsLabelName: '测试新增一级标签24',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:59:42',
    modifyTime: '2022-09-22 20:59:42',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770431197409157120',
    contactsLabelName: '测试新增一级标签23',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:59:37',
    modifyTime: '2022-09-22 20:59:37',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770431180350922752',
    contactsLabelName: '测试新增一级标签22',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:59:32',
    modifyTime: '2022-09-22 20:59:32',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770431163221385216',
    contactsLabelName: '测试新增一级标签21',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:59:28',
    modifyTime: '2022-09-22 20:59:28',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770431147572436992',
    contactsLabelName: '测试新增一级标签20',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:59:25',
    modifyTime: '2022-09-22 20:59:25',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770431130874912768',
    contactsLabelName: '测试新增一级标签19',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:59:21',
    modifyTime: '2022-09-22 20:59:21',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770431115674755072',
    contactsLabelName: '测试新增一级标签18',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:59:17',
    modifyTime: '2022-09-22 20:59:17',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770431099707039744',
    contactsLabelName: '测试新增一级标签17',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:59:13',
    modifyTime: '2022-09-22 20:59:13',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770431083789656064',
    contactsLabelName: '测试新增一级标签16',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:59:09',
    modifyTime: '2022-09-22 20:59:09',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770431065955475456',
    contactsLabelName: '测试新增一级标签15',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:59:05',
    modifyTime: '2022-09-22 20:59:05',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770431048553308160',
    contactsLabelName: '测试新增一级标签14',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:59:01',
    modifyTime: '2022-09-22 20:59:01',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770431029221761024',
    contactsLabelName: '测试新增一级标签13',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:58:56',
    modifyTime: '2022-09-22 20:58:56',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770431009693081600',
    contactsLabelName: '测试新增一级标签12',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:58:52',
    modifyTime: '2022-09-22 20:58:52',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770430894731403264',
    contactsLabelName: '测试新增一级标签2',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:58:24',
    modifyTime: '2022-09-22 20:58:24',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '770430258505818112',
    contactsLabelName: '测试新增一级标签1',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-22 20:55:53',
    modifyTime: '2022-09-22 20:55:53',
    count: 0,
    children: [
      {
        contactsLabelId: '770430834807382016',
        contactsLabelName: '测试新增二级标签1',
        level: '2',
        parentLabelId: '770430258505818112',
        isJointServiceUnit: '0',
        remark: '',
        createTime: '2022-09-22 20:58:10',
        modifyTime: '2022-09-22 20:58:10',
        count: 0
      }
    ]
  },
  {
    contactsLabelId: '5',
    contactsLabelName: '测试标签1',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '0',
    remark: '',
    createTime: '2022-09-20 19:25:00',
    modifyTime: '2022-09-20 19:25:00',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '10000500',
    contactsLabelName: '小区物业',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '1',
    remark: '备注',
    createTime: '2022-08-17 18:35:23',
    createUserId: 'admin',
    modifyUserId: 'admin',
    modifyTime: '2022-08-17 18:35:23',
    count: 4,
    children: [
      {
        contactsLabelId: '10000501',
        contactsLabelName: '郑州恒大名都物业',
        level: '2',
        parentLabelId: '10000500',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:23',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:23',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000029',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000501',
            contactsLabelName: '郑州恒大名都物业',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:51',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:51',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000502',
        contactsLabelName: '万科城市之光物业',
        level: '2',
        parentLabelId: '10000500',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:23',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:23',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000030',
            contactsName: '孙雪梅',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000502',
            contactsLabelName: '万科城市之光物业',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:51',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:51',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000503',
        contactsLabelName: '融创城市花园物业',
        level: '2',
        parentLabelId: '10000500',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:23',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:23',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000031',
            contactsName: '乔彤',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000503',
            contactsLabelName: '融创城市花园物业',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:51',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:51',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000504',
        contactsLabelName: '恒大现代城',
        level: '2',
        parentLabelId: '10000500',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:23',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:23',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000032',
            contactsName: '马薇薇',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000504',
            contactsLabelName: '恒大现代城',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:51',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:51',
            hsUser: '102602'
          }
        ]
      }
    ]
  },
  {
    contactsLabelId: '10000300',
    contactsLabelName: '医院',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '1',
    remark: '备注',
    createTime: '2022-08-17 18:35:22',
    createUserId: 'admin',
    modifyUserId: 'admin',
    modifyTime: '2022-08-17 18:35:22',
    count: 4,
    children: [
      {
        contactsLabelId: '10000301',
        contactsLabelName: '河南省人民医院',
        level: '2',
        parentLabelId: '10000300',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:22',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:22',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000021',
            contactsName: '王素敏',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000301',
            contactsLabelName: '河南省人民医院',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:50',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:50',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000302',
        contactsLabelName: '郑州市儿童医院',
        level: '2',
        parentLabelId: '10000300',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:22',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:22',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000022',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000302',
            contactsLabelName: '郑州市儿童医院',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:50',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:50',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000303',
        contactsLabelName: '郑州大学第三附属医院',
        level: '2',
        parentLabelId: '10000300',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:22',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:22',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000023',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000303',
            contactsLabelName: '郑州大学第三附属医院',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:50',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:50',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000304',
        contactsLabelName: '郑州市第二中医院',
        level: '2',
        parentLabelId: '10000300',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:22',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:22',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000024',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000304',
            contactsLabelName: '郑州市第二中医院',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:50',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:50',
            hsUser: '102602'
          }
        ]
      }
    ]
  },
  {
    contactsLabelId: '10000400',
    contactsLabelName: '消防队',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '1',
    remark: '备注',
    createTime: '2022-08-17 18:35:22',
    createUserId: 'admin',
    modifyUserId: 'admin',
    modifyTime: '2022-08-17 18:35:22',
    count: 4,
    children: [
      {
        contactsLabelId: '10000404',
        contactsLabelName: '郑州市高新区消防队',
        level: '2',
        parentLabelId: '10000400',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:23',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:23',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000028',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000404',
            contactsLabelName: '郑州市高新区消防队',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:51',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:51',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000401',
        contactsLabelName: '河南省郑州市二七区郑州消防',
        level: '2',
        parentLabelId: '10000400',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:22',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:22',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000025',
            contactsName: '朱斌',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000401',
            contactsLabelName: '河南省郑州市二七区郑州消防',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:50',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:50',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000402',
        contactsLabelName: '郑州市消防救援支队',
        level: '2',
        parentLabelId: '10000400',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:22',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:22',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000026',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000402',
            contactsLabelName: '郑州市消防救援支队',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:51',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:51',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000403',
        contactsLabelName: '郑东新区消防大队',
        level: '2',
        parentLabelId: '10000400',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:22',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:22',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000027',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000403',
            contactsLabelName: '郑东新区消防大队',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:51',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:51',
            hsUser: '102602'
          }
        ]
      }
    ]
  },
  {
    contactsLabelId: '10000200',
    contactsLabelName: '网格员',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '1',
    remark: '备注',
    createTime: '2022-08-17 18:35:21',
    createUserId: 'admin',
    modifyUserId: 'admin',
    modifyTime: '2022-08-17 18:35:21',
    count: 27,
    children: [
      {
        contactsLabelId: '10000201',
        contactsLabelName: '中原区网格1',
        level: '2',
        parentLabelId: '10000200',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:21',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:21',
        count: 18,
        contactsChildren: [
          {
            contactsId: '787813090332557312',
            contactsName: 'xx',
            mobile: '15810391261',
            type: '0',
            jobDescription: '网格员测试',
            contactsLabelId: '10000201',
            contactsLabelName: '中原区网格1',
            remark: '批量导入',
            createUserId: '20210508134141840584246283341824',
            createTime: '2022-11-09 20:09:07',
            modifyUserId: '20210508134141840584246283341824',
            modifyTime: '2022-11-16 19:06:11'
          },
          {
            contactsId: '787813090324168704',
            contactsName: 'xx',
            mobile: '70001038',
            type: '0',
            jobDescription: '网格员测试',
            contactsLabelId: '10000201',
            contactsLabelName: '中原区网格1',
            remark: '批量导入',
            createUserId: '20210508134141840584246283341824',
            createTime: '2022-11-09 20:09:07',
            modifyUserId: '20210508134141840584246283341824',
            modifyTime: '2022-11-09 20:09:07',
            hsUser: '102603'
          },
          {
            contactsId: '1572894729463398402',
            contactsName: '测试新增网格员',
            mobile: '11111111312',
            type: '0',
            jobDescription: '网格员',
            contactsLabelId: '10000201',
            contactsLabelName: '中原区网格1',
            remark: '备注',
            createTime: '2022-09-22 18:25:01',
            modifyTime: '2022-09-22 18:25:01'
          }
        ]
      },
      {
        contactsLabelId: '10000202',
        contactsLabelName: '中原区网格2',
        level: '2',
        parentLabelId: '10000200',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:21',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:21',
        count: 4,
        contactsChildren: [
          {
            contactsId: '1000000016',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000202',
            contactsLabelName: '中原区网格2',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:49',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:49',
            hsUser: '102602'
          },
          {
            contactsId: '100000057',
            contactsName: '李四',
            mobile: '18302458790',
            type: '0',
            jobDescription: '',
            contactsLabelId: '10000202',
            contactsLabelName: '中原区网格2',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-07-25 10:22:05',
            modifyUserId: 'admin',
            modifyTime: '2022-07-25 10:22:05'
          },
          {
            contactsId: '100000056',
            contactsName: '张三',
            mobile: '13263291661',
            type: '0',
            jobDescription: '',
            contactsLabelId: '10000202',
            contactsLabelName: '中原区网格2',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-07-25 10:22:05',
            modifyUserId: 'admin',
            modifyTime: '2022-07-25 10:22:05'
          }
        ]
      },
      {
        contactsLabelId: '10000203',
        contactsLabelName: '中原区网格3',
        level: '2',
        parentLabelId: '10000200',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:21',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:21',
        count: 2,
        contactsChildren: [
          {
            contactsId: '52',
            contactsName: 'xx33',
            mobile: '18610295435',
            type: '0',
            jobDescription: '网格员-巡逻',
            contactsLabelId: '10000203',
            contactsLabelName: '中原区网格3',
            remark: '',
            createTime: '2022-11-08 10:37:23',
            modifyTime: '2022-11-08 11:11:41'
          },
          {
            contactsId: '1000000017',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000203',
            contactsLabelName: '中原区网格3',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:50',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:50',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000204',
        contactsLabelName: '金水区网格1',
        level: '2',
        parentLabelId: '10000200',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:21',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:21',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000018',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000204',
            contactsLabelName: '金水区网格1',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:50',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:50',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000205',
        contactsLabelName: '金水区网格2',
        level: '2',
        parentLabelId: '10000200',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:21',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:21',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000019',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000205',
            contactsLabelName: '金水区网格2',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:50',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:50',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000206',
        contactsLabelName: '二七区网格1',
        level: '2',
        parentLabelId: '10000200',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:21',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:21',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000020',
            contactsName: '孙雪梅',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000206',
            contactsLabelName: '二七区网格1',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:50',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:50',
            hsUser: '102602'
          }
        ]
      }
    ]
  },
  {
    contactsLabelId: '10000600',
    contactsLabelName: '巡查人员',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '1',
    remark: '备注',
    createTime: '2022-08-17 18:35:21',
    createUserId: 'admin',
    modifyUserId: 'admin',
    modifyTime: '2022-08-17 18:35:21',
    count: 0,
    children: []
  },
  {
    contactsLabelId: '10000100',
    contactsLabelName: '政府部门',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '1',
    remark: '备注',
    createTime: '2022-08-17 18:35:20',
    createUserId: 'admin',
    modifyUserId: 'admin',
    modifyTime: '2022-08-17 18:35:20',
    count: 13,
    children: [
      {
        contactsLabelId: '10000105',
        contactsLabelName: '应急局',
        level: '2',
        parentLabelId: '10000100',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:21',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:21',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000012',
            contactsName: 'xx',
            mobile: '8880006',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000105',
            contactsLabelName: '应急局',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:49',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:49'
          }
        ]
      },
      {
        contactsLabelId: '10000106',
        contactsLabelName: '水利局',
        level: '2',
        parentLabelId: '10000100',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:21',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:21',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000013',
            contactsName: 'xx',
            mobile: '8880011',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000106',
            contactsLabelName: '水利局',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:49',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:49'
          }
        ]
      },
      {
        contactsLabelId: '10000107',
        contactsLabelName: '环保局',
        level: '2',
        parentLabelId: '10000100',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:21',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:21',
        count: 1,
        contactsChildren: [
          {
            contactsId: '1000000014',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000107',
            contactsLabelName: '环保局',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:49',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:49',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000101',
        contactsLabelName: '城管局',
        level: '2',
        parentLabelId: '10000100',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:20',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:20',
        count: 3,
        contactsChildren: [
          {
            contactsId: '1000000004',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000101',
            contactsLabelName: '城管局',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:48',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:48',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000102',
        contactsLabelName: '公安局1',
        level: '2',
        parentLabelId: '10000100',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:20',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:20',
        count: 4,
        contactsChildren: [
          {
            contactsId: '1000000008',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000102',
            contactsLabelName: '公安局1',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:49',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:49',
            hsUser: '102602'
          }
        ]
      },
      {
        contactsLabelId: '10000103',
        contactsLabelName: '政法委',
        level: '2',
        parentLabelId: '10000100',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:20',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:20',
        count: 2,
        contactsChildren: [
          {
            contactsId: '1000000010',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000103',
            contactsLabelName: '政法委',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:49',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:49',
            hsUser: '102602'
          },
          {
            contactsId: '1000000009',
            contactsName: 'xx',
            mobile: '70001037',
            type: '1',
            jobDescription: '',
            contactsLabelId: '10000103',
            contactsLabelName: '政法委',
            remark: '备注',
            createUserId: 'admin',
            createTime: '2022-08-17 20:51:49',
            modifyUserId: 'admin',
            modifyTime: '2022-08-17 20:51:49',
            hsUser: '102602'
          }
        ]
      }
    ]
  }
];
export const EquipmentList = [
  {
    contactsLabelId: '20000200',
    contactsLabelName: '金水区',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '1',
    remark: '备注',
    createTime: '2022-08-17 18:35:24',
    createUserId: 'admin',
    modifyUserId: 'admin',
    modifyTime: '2022-08-17 18:35:24',
    count: 3,
    children: [
      {
        contactsLabelId: '20000201',
        contactsLabelName: '中州大道',
        level: '2',
        parentLabelId: '20000200',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:24',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:24',
        count: 1,
        equipmentChildren: [
          {
            contactsId: '2000000008',
            contactsName: '中州大道实验小学门口',
            address: '中州大道实验小学门口',
            callId: '8880006',
            longitude: '113.674947259521',
            latitude: '34.7512003392343',
            contactsLabelId: '20000201',
            remark: '备注',
            tenantId: 'default'
          }
        ]
      },
      {
        contactsLabelId: '20000202',
        contactsLabelName: '农业路',
        level: '2',
        parentLabelId: '20000200',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:24',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:24',
        count: 1,
        equipmentChildren: [
          {
            contactsId: '2000000009',
            contactsName: '农业路工贸家电门口',
            address: '农业路工贸家电门口',
            callId: '8880011',
            longitude: '113.670140740967',
            latitude: '34.768969844391',
            contactsLabelId: '20000202',
            remark: '备注',
            tenantId: 'default'
          }
        ]
      },
      {
        contactsLabelId: '20000203',
        contactsLabelName: '会展中心',
        level: '2',
        parentLabelId: '20000200',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:24',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:24',
        count: 1,
        equipmentChildren: [
          {
            contactsId: '2000000010',
            contactsName: '会展中心北门',
            address: '会展中心北门',
            callId: '8880004',
            longitude: '113.706532952881',
            latitude: '34.7613548104937',
            contactsLabelId: '20000203',
            remark: '备注',
            tenantId: 'default'
          }
        ]
      }
    ]
  },
  {
    contactsLabelId: '20000100',
    contactsLabelName: '中原区',
    level: '1',
    parentLabelId: '',
    isJointServiceUnit: '1',
    remark: '备注',
    createTime: '2022-08-17 18:35:23',
    createUserId: 'admin',
    modifyUserId: 'admin',
    modifyTime: '2022-08-17 18:35:23',
    count: 7,
    children: [
      {
        contactsLabelId: '20000103',
        contactsLabelName: '万达广场',
        level: '2',
        parentLabelId: '20000100',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:24',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:24',
        count: 2,
        equipmentChildren: [
          {
            contactsId: '2000000003',
            contactsName: '万达广场东门',
            address: '万达广场东门',
            callId: '8880011',
            longitude: '113.655377862549',
            latitude: '34.7266552121965',
            contactsLabelId: '20000103',
            remark: '备注',
            tenantId: 'default'
          },
          {
            contactsId: '2000000004',
            contactsName: '万达广场北门',
            address: '万达广场北门',
            callId: '8880004',
            longitude: '113.710309503174',
            latitude: '34.7715080329054',
            contactsLabelId: '20000103',
            remark: '备注',
            tenantId: 'default'
          }
        ]
      },
      {
        contactsLabelId: '20000104',
        contactsLabelName: '郑州植物园',
        level: '2',
        parentLabelId: '20000100',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:24',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:24',
        count: 2,
        equipmentChildren: [
          {
            contactsId: '2000000005',
            contactsName: '郑州植物园出口',
            address: '郑州植物园出口',
            callId: '8880006',
            longitude: '113.643361566162',
            latitude: '34.7472510409586',
            contactsLabelId: '20000104',
            remark: '备注',
            tenantId: 'default'
          },
          {
            contactsId: '2000000006',
            contactsName: '郑州植物园南门',
            address: '郑州植物园南门',
            callId: '8880011',
            longitude: '113.733998773193',
            latitude: '34.7703799587585',
            contactsLabelId: '20000104',
            remark: '备注',
            tenantId: 'default'
          }
        ]
      },
      {
        contactsLabelId: '20000105',
        contactsLabelName: '中原西路',
        level: '2',
        parentLabelId: '20000100',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:24',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:24',
        count: 1,
        equipmentChildren: [
          {
            contactsId: '2000000007',
            contactsName: '中原西路156号融创现代城东门',
            address: '中原西路156号融创现代城东门',
            callId: '8880004',
            longitude: '113.674603936768',
            latitude: '34.7286303771971',
            contactsLabelId: '20000105',
            remark: '备注',
            tenantId: 'default'
          }
        ]
      },
      {
        contactsLabelId: '20000101',
        contactsLabelName: '科学大道',
        level: '2',
        parentLabelId: '20000100',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:23',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:23',
        count: 1,
        equipmentChildren: [
          {
            contactsId: '2000000001',
            contactsName: '科学大道与中山大道交叉口',
            address: '科学大道与中山大道交叉口',
            callId: '8880004',
            longitude: '113.623792169189',
            latitude: '34.7235512861268',
            contactsLabelId: '20000101',
            remark: '备注',
            tenantId: 'default'
          }
        ]
      },
      {
        contactsLabelId: '20000102',
        contactsLabelName: '建设西路',
        level: '2',
        parentLabelId: '20000100',
        isJointServiceUnit: '1',
        remark: '备注',
        createTime: '2022-08-17 18:35:23',
        createUserId: 'admin',
        modifyUserId: 'admin',
        modifyTime: '2022-08-17 18:35:23',
        count: 1,
        equipmentChildren: [
          {
            contactsId: '2000000002',
            contactsName: '万科城市花园小区',
            address: '万科城市花园小区',
            callId: '8880006',
            longitude: '113.6378684021',
            latitude: '34.7365305652168',
            contactsLabelId: '20000102',
            remark: '备注',
            tenantId: 'default'
          }
        ]
      }
    ]
  }
];
export const ElementHTML = `import YunliRTC, { Turnplate } from 'yunli-rtc';
import eventBus from '@/plugins/eventBus';

// eventBus列表
yunli-rtc-turnplate API接口
yunli-rtc-turnplate-login 登录
yunli-rtc-turnplate-logout 登出
yunli-rtc-turnplate-incoming 来电
yunli-rtc-turnplate-call-miss-ignore 未接来电忽略
yunli-rtc-turnplate-call 开始呼叫
yunli-rtc-turnplate-start 开始通话
yunli-rtc-turnplate-hangup 挂断通话
yunli-rtc-turnplate-fail 未接通（主动挂断或呼叫失败）
yunli-rtc-turnplate-conf 开始会商
yunli-rtc-turnplate-conf-join 成员加入会商
yunli-rtc-turnplate-conf-end 结束会商
yunli-rtc-turnplate-player-hangup 播放器挂断

eventBus.emit('yunli-rtc-turnplate-start', (res) => {
  console.log(res);
});

// 切换账号
Turnplate.regist({
  id: '70000025', // 账号
  password: '1234' // 密码
});

// 打音视频电话
Turnplate.call({
  id: '70001037', // 设备号或手机号
  name: '云粒APP1', // 名称或姓名
  video: true // 是否为视频通话，默认为false
});

// 会商
Turnplate.conf([ // 拉入会商的设备号或手机号或对象数组，可以为空
  '70001037',
  {
    id: '70001037',
    name: 'xx'
  }
]);

// 拨号
Turnplate.dial();
Turnplate.dial({ // 默认拨打号码，可以为空
  id: '70001037',
  name: 'xhlv'
});

// 音视频播放器
Turnplate.play({
  type: 'video', // 音频（audio）、视频（video），默认为视频
  id: '8880004', // 直播设备号、手机号、视频文件Url地址、视频流Url地址
  name: 'test直播' // 直播名称
});

// 人员调度
Turnplate.control([ // 设置选中人员，可以为空
  {
    id: '70001037',
    name: 'xx'
  }
]);

// 通讯录
Turnplate.contacts(() => { // 回调，可以为空
  
});

// 邀请
Turnplate.invite({
  title: '邀请成员',
  okText: '邀请',
  callback: () => { // 回调，可以为空
  
  }
});

// 设置通讯录列表
Turnplate.setContactsList([]);

// 设置监控列表
Turnplate.setEquipmentList([
  {
    title: '监控',
    children: [
      {
        suid: '8880006',
        contactsId: '42010000001320000007',
        contactsName: '1F-机房',
        callId: '8880006',
        parentName: '8880006',
        type: '1',
        jobDescription: '',
        contactsLabelId: '42010000001320000007',
        contactsLabelName: '1F-机房',
        remark: '',
        title: '1F-机房',
        key: '0-0-0',
        id: '42010000001320000007',
        name: '1F-机房'
      },
      {
        suid: '8880004',
        contactsId: '42010000001320000004',
        contactsName: '1F-休息区',
        callId: '8880004',
        parentName: '8880004',
        type: '1',
        jobDescription: '',
        contactsLabelId: '42010000001320000004',
        contactsLabelName: '1F-休息区',
        remark: '',
        title: '1F-休息区',
        key: '0-0-1',
        id: '42010000001320000004',
        name: '1F-休息区'
      }
    ]
  }
]);

// 直播
Turnplate.live({
  checked: [ // 默认选中，可以不设置
    { id: '8880011', name: '云粒窗外' },
    { id: '8880004', name: '1F-休息区' }
  ],
  url: (id) => { // 播放URL的获取，可以自定义url获取的方式，不设置则默认为拨号方式（拨打ID）播放
    return Turnplate.getVideoUrl({
      gbId: id
    }).then((res) => {
      return res.data?.wsFlv;
    });
  }
});

// 设置属性
Turnplate.set({ // 属性值（以下均为默认值）
  theme: 'Blue', // 样式主题（Blue Dark，默认为 Blue）
  css: 'common', // 样式前缀，可以配置自定义样式
  getContactsListUrl: '', // 自定义通讯录列表地址，为空可以设置不拉取
  getEquipmentListUrl: '', // 自定义设备列表地址，为空可以设置不拉取
  getLocationUrl: '', // 自定义获取通讯录地理位置地址，为空可以设置不拉取
  callLeftBtn: '', // 自定义电话中的左侧按钮
  confLeftBtn: '', // 自定义会商中的左侧按钮
  confTopBtn: '', // 自定义会商中的顶部按钮
  confCenterBtn: '', // 自定义会商中的中间按钮
  endTips: '', // 结束通话提示（确认结束前面）
  isMobile: false, // 是否移动端
  isRegisted: false, // 是否注册登录成功
  isAutoOnline: false, // 是否离线后自动登录（拨打电话前，保证登录状态）
  isConfVisible: false, // 视频会议是否打开
  isMemberBtnHidden: false, // 是否隐藏会商成员按钮（默认为false）
  isMuteHidden: false, // 是否隐藏静音功能（默认为false，可以隐藏不显示静音按钮，会商不允许隐藏）
  isTurnplateVisible: true, // 转盘是否打开
  isInviteVisible: false, // 邀请是否打开
  isControlVisible: false, // 调度人员是否打开
  isCallVisible: false, // 拨号盘是否打开
  isIncomingVisible: false, // 来电浮层是否打开
  isPlayerVisible: false, // 视频播放是否打开
  isPlayerMulti: true, // 视频播放是否允许同时打开多个
  isIncominngAnimate: false, // 来电动画
  isIncomingAllAudio: false, // 是否视频来电使用语音接听（默认为false）
  id: '', // session ID
  incomingId: '', // 来电 session ID
  playerId: '', // 视频播放器 session ID
  status: 'default', // 拨号盘状态: 'accepted', 'progress', 'failed', 'ended'（default, calling, online）
  direction: '', // 来电方向：'outgoing', 'incoming'
  type: '', // 通话类型：'video', 'audio', 'conf'
  incomingType: '', // 来电类型：'video', 'audio', 'conf'
  callTimesMax: 20, // 最大重拨次数（默认为20，设置为0时，可以关闭拨打重试的功能）
  confInfo: {}, // 会商来电信息
  confTitle: '视频会议', // 会商自定义标题，默认为'视频会议'
  playerTitle: '', // 视频播放器模式title设置
  isMute: false, // 是否静音
  isVideo: true, // 是否开启摄像头
  isMax: false, // 是否最大化
  isMin: false, // 是否最小化
});

// 读取属性
Turnplate.get('status'); // 属性名称字符串

// 插入DOM
<Turnplate
  regist={{
    eventBus: eventBus, // 事件通信，设为项目中公用的eventBus
    ip: 'www.t01md.com.cn',
    port: '15675',
    appId: 'beb7da4ced7c42a085c3c99697f9aa42',
    secretKey: 'ba2468bf083947c5954cf2ccd9f2c9ed',
    userName: '70000025',
    userPsw: '1234',
    userType: '2', // 2为pc用户，1为app用户
    confPsw: '70000025', // 会商密码
    path: '/tx',
    api: '/communication-service-ly'
  }}
  options={{
    confLeftBtn: (
      <Button
        type="primary"
        className="commonBtn ant-btn-primary2"
        onClick={() => {
          // EventReportStore.setVisible(true);
          console.log('生成事件...');
        }}>
        生成事件
      </Button>
    ),
    isTurnplateVisible: false // 是否显示转盘，默认为true，可以手动关闭
  }}>
  <div
    onClick={() => {
      Turnplate.dial();
    }}>
    <em className="ico ico-turnplate1"></em>
    拨号
  </div>
  <div
    onClick={() => {
      Turnplate.conf();
    }}>
    <em className="ico ico-turnplate2"></em>
    会商
  </div>
  <div onClick={() => {}}>
    <em className="ico ico-turnplate3"></em>
    知识库
  </div>
  <div
    onClick={() => {
      Turnplate.control();
    }}>
    <em className="ico ico-turnplate4"></em>
    调度人员
  </div>
  <div onClick={() => {}}>
    <em className="ico ico-turnplate5"></em>
    档案
  </div>
  <div
    onClick={() => {
      Turnplate.contacts();
    }}>
    <em className="ico ico-turnplate6"></em>
    通讯录
  </div>
</Turnplate>`;

export const UIHTML = `import { Turnplate } from 'yunli-rtc';

/*
  1、基于Antd的主题样式，适用于项目内或不同项目之间的样式复用
  2、支持主题切换，以及自定义主题
  3、样式可以脱离通话API和组件单独使用，Turnplate.set方法设置主题，或者body上加对应主题的样式，比如：class="commonThemCyan"
  4、一个页面内支持多个主题同时使用，在不同的页面块元素上设置不同的主题class即可
*/

Turnplate.set({
  theme: 'Cyan'
});

`;

export const CSSHTML = `:root {
  --ylr-color-default: #005684;
  --ylr-color-default-border: #00A6FF;
  --ylr-color-default-hover: #004b74;
  --ylr-color-primary: #0081FF;
  --ylr-color-primary-border: #00F2FF;
  --ylr-color-primary-hover: #019aec;
  --ylr-color-primary2: rgba(0,156,203,0.8);
  --ylr-color-primary2-border: #00BBF3;
  --ylr-color-primary2-hover: rgba(0, 138, 180, 0.8);
  --ylr-color-success: #00b578;
  --ylr-color-warning: #ff8f1f;
  --ylr-color-danger: rgba(255,64,0,0.8);
  --ylr-color-danger-border: #FF2800;
  --ylr-color-danger-hover: rgba(238, 61, 1, 0.8);
  --ylr-color-border: #2C6199;
  --ylr-color-link: #0081FF;
  --ylr-color-white: #fff;
  --ylr-color-text: #333;
  --ylr-color-text-secondary: #666;
  --ylr-color-weak: #999;
  --ylr-color-light: #ccc;
  --ylr-color-scroll: #396D9F;
  --ylr-color-bg-title: #1073B9;
  --ylr-color-bg-content: #03243F;
  --ylr-color-bg-footer: #133461;
  --ylr-color-bg-avatar-start: #0099FF;
  --ylr-color-bg-avatar-end: #006FFF;
}`;

export const ApiHTML = `import YunliRTC from 'yunli-rtc';

// 获取SDK类型
YunliRTC.getSDK();

// 设置SDK类型（ly-凌壹，hm-航美），默认为'ly'
YunliRTC.setSDK('hm');

// 注册SDK
const result = await YunliRTC.regist(options);
if (result === 1) {
  YunliRTC.onIncomingCall(({ type, status, direction, from, cause, originator }) => {
    // 来电回调
  });
}

// 拨打音视频
YunliRTC.call(id, {
  video: true, // 是否为视频通话，默认为false
  media: remoteAudio.current, // 接收媒体流的DOM
  callback: () => { // 回调
    
  }
});

// 接听音视频
YunliRTC.answer({
  video: true, // 是否为视频通话，默认为false
  media: remoteVideo.current // 接收媒体流的DOM
});

// 语音或视频会议
YunliRTC.createConf(
  ['70000032', '70001037'], // 设备号、手机号数组，可以为空
  {
    media: remoteAudio.current, // 接收媒体流的DOM
    video: false, // 是否为视频会议，默认为true，false为语音会议
    name: 'test', // 会议名称
    callback: (res) => { // 回调
      
    }
  }
);

// 开启接收实时消息
YunliRTC.getMessage({
  callback: (data) => {
    message.info(data.SENDERNAME + ':' + data.CONTENT);
  }
});

// 发送实时消息
YunliRTC.sendMessage('70001037', {
  content: '内容', // 消息内容
  callback: (res) => { // 回调
    if (res.isSuccess) {
      message.success('发送成功');
    }
  }
});
`;
