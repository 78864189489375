import React from 'react';
import { inject, observer } from 'mobx-react';
import Toast from './Toast';
import Modal from './Modal';
import Incoming from './Incoming';
import Call from './Call';

const Mobile = () => {
  return (
    <>
      <Incoming />
      <Call />
      <Toast />
      <Modal />
    </>
  );
};

export default inject('VideoCallStore')(observer(Mobile));
